import React, { useEffect } from 'react';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@material-ui/core';
import ReactPlayer from 'react-player'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './styles.css';



const theme = createMuiTheme();
const drawerWidth = 240;

theme.typography.body1 = {
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.2rem',
    fontFamily: 'Helvetica Now Text',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.2rem',
    fontFamily: 'Helvetica Now Text',
  },
};


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    marginTop: '4rem',
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    //marginTop: '100px',
    marginTop: '4rem',
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    marginTop: '4rem',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },



  playerwrapper: {
    position: 'relative',
  },
  reactplayer: {
    position: 'absolute',
  },
  light: {
    background: '#F2F2F1',
    color: '#212125'
  },
  dark: {
    background: '#212125',
    color: '#F2F2F1'
  },
  videoHeading: {
    color: '#DC2F02',
    fontFamily: 'Helvetica Now Text',
    paddingTop: '1%',
    paddingBottom: '1%',
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, ' +
      'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  spaced: {
    paddingBottom: '5%',
    paddingTop: '1%',
    paddingLeft: '5%',
    paddingRight: '5%',
  },
  beltContainer: {
    marginTop: '6rem',
    marginBottom: '6rem',
    flexWrap: 'wrap',
  },
  beltImage: {
    marginLeft: '4rem',
    marginRight: '4rem',
    maxHeight: '200px',
    maxWidth: '200px',
  },
  beltText: {
    alignText: 'center',
    fontFamily: 'Helvetica Now Text',
    fontWeight: 'normal',
  },
  smallScreen: {
    "@media only screen and (max-width : 900px)": {
      display: 'none'
    },
  },
  bigScreen: {
    display: 'none',
    "@media only screen and (max-width : 899px)": {
      display: 'initial'
    },
  },
  smallHeader: {
    textAlign: 'center',
    fontFamily: 'Helvetica Now Text',
    fontSize: '1.5rem',
    fontWeight: 'bold',
  }
}));




export default function BeltVideos({ belt, videos }) {
  const classes = useStyles();
  const categories = [...new Set(videos.map(item => item.category))];

  useEffect(() => {

  }, [])


  return (
    <Box>

      <ThemeProvider theme={theme}>
        <div>
          {videos
            ? categories.map((category, count) =>
              <div>
                  <div style={{ width: '80vw' }}>
                    {
                      //Accordion width is set hackily, needs to be reactive and not so long
                    }
                    <Accordion defaultExpanded="true" style={{ background: "white", marginBottom: '4rem' }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.videoHeading} variant="h4" component="h4" align="center">{category}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box display="flex" flexWrap="wrap" justifyContent="space-around" width="100%">
                          {videos.map((video) =>
                            (video.category === category)
                              ?
                              <GridListTile style={{  width: '75vw', maxWidth: '720px', listStyle: 'none', marginBottom: '2em', border: '1px solid #cecece' }}>
                                <ReactPlayer pip={true} light width='100%' controls={true} url={video.address} />

                                <GridListTileBar
                                  title={video.title}
                                  classes={{
                                    root: classes.titleBar,
                                    title: classes.title,
                                  }}
                                  titlePosition='top'
                                  style={{  width: '75vw', maxWidth: '720px' }}
                                />
                              </GridListTile>
                              : <div></div>
                          )}
                        </Box>

                      </AccordionDetails>
                    </Accordion>
                  </div>
              </div>
            )
            : []
          }

        </div>

      </ThemeProvider>

    </Box>
  );
}