import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import './styles.css';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({

    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    innerAccordion :{
        boxShadow: 'none', 
            "@media only screen and (max-width : 320px)": {
            maxWidth: '80vw', 

          },
      
          /* Extra Small Devices, Phones */
          "@media only screen and (min-width : 480px)": {
            maxWidth: '60vw', 

          },
      
          /* Small Devices, Tablets */
          "@media only screen and (min-width : 768px)": {
            maxWidth: '45vw', 

          },
      
          /* Medium Devices, Desktops */
          "@media only screen and (min-width : 992px)": {
            maxWidth: '35vw', 

          },
      
          /* Large Devices, Wide Screens */
          "@media only screen and (min-width : 1200px)": {
            maxWidth: '25vw', 

          },
    },
    outerAccordion :{
        "@media only screen and (max-width : 320px)": {
            maxWidth: '80vw', 

          },
      
          /* Extra Small Devices, Phones */
          "@media only screen and (min-width : 480px)": {
            maxWidth: '60vw', 

          },
      
          /* Small Devices, Tablets */
          "@media only screen and (min-width : 768px)": {
            maxWidth: '45vw', 

          },
      
          /* Medium Devices, Desktops */
          "@media only screen and (min-width : 992px)": {
            maxWidth: '35vw', 

          },
      
          /* Large Devices, Wide Screens */
          "@media only screen and (min-width : 1200px)": {
            maxWidth: '25vw', 

          },
        
    },
    summaryBorder: {
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
    },
    headerText: {
        fontWeight: 'bold',
        fontFamily: 'Helvetica Now Text',
        fontSize: '1.2rem',
    },
    bodyText: {
        fontFamily: 'Helvetica Now Text',
        fontSize: '400',
    },
    content: {
        height: '100%',
        /* Custom, iPhone Retina */
    "@media only screen and (max-width : 479px)": {
        marginLeft: '5vw', 
        maxWidth: '90vw',
      },
  
      /* Extra Small Devices, Phones */
      "@media only screen and (min-width : 480px)": {
        marginLeft: '10vw', 
        maxWidth: '80vw',
      },
  
      /* Small Devices, Tablets */
      "@media only screen and (min-width : 768px)": {
        marginLeft: '15vw', 
        maxWidth: '70vw',
      },
  
      /* Medium Devices, Desktops */
      "@media only screen and (min-width : 992px)": {
        marginLeft: '15vw', 
        maxWidth: '60vw',
      },
  
      /* Large Devices, Wide Screens */
      "@media only screen and (min-width : 1200px)": {
        marginLeft: '15vw', 
        maxWidth: '50vw',
      },
    }
}));

export default function Privacy() {
    const classes = useStyles();

    return (
        <div className={classes.content}>
            <Typography className={classes.headerText} style={{ marginTop: '2em', fontSize: '2rem' }}>
                Privacy policy<br /><br />
            </Typography>

            <Typography className={classes.bodyText}>
                Kiaido Ryu Martial Arts International considers the protection of privacy to be of utmost importance, this policy explains how we may collect information about you and use it in order to satisfy your particular requirements. It outlines some of the security measures that we take in order to protect your privacy and gives certain assurances on things that we will not do
        <br /><br />
            </Typography>
            <Accordion className={classes.outerAccordion}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.summaryBorder}
                >
                    <Typography className={classes.headerText}>
                        Collection of information
                </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        <Typography className={classes.bodyText}>
                            We collect personal information from you in several ways. This includes when you:
                    </Typography>
                        <ul >
                            <li ><Typography className={classes.bodyText}>Become a member of one of Kiaido Ryu Martial Arts affiliated schools. This may include:</Typography></li>
                            <ul>
                                <li><Typography className={classes.bodyText}>Your contact details</Typography></li>
                                <li><Typography className={classes.bodyText}>Date of birth</Typography></li>
                                <li><Typography className={classes.bodyText}>Possibly bank details</Typography></li>
                                <li><Typography className={classes.bodyText}>Personal medical information.</Typography> </li>
                            </ul>
                            <li><Typography className={classes.bodyText}>Contact us with an enquiry, or in response to a communication from us.</Typography> </li>
                            <li><Typography className={classes.bodyText}>Website information.</Typography> </li>
                            <li><Typography className={classes.bodyText}>Pictures may be used from Kiaido Ryu events</Typography> </li>
                        </ul>
                    </div>
                </AccordionDetails>
            </Accordion>

            <Accordion className={classes.outerAccordion}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    className={classes.summaryBorder}
                >
                    <Typography className={classes.headerText}>
                        Use of information
                </Typography>
                </AccordionSummary>
                <AccordionDetails>

                    <div>
                    <Accordion className={classes.innerAccordion}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.headerText}>
                                What we will do
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.bodyText}>
                            <div>
                                <Typography className={classes.bodyText}>
                                We may use personal information about you for a number of purposes, including:
                                </Typography>
                                <ul>
                                    <li><Typography className={classes.bodyText}>Responses to enquiries from you</Typography></li>
                                    <li><Typography className={classes.bodyText}>Administration of our student database </Typography></li>
                                    <li><Typography className={classes.bodyText}>Communicating with you by any means (including mail, email, telephone,
                                    text messages or online advertising) about other services or products, where
                                    we think you would appreciate receiving an offer from us
                                    </Typography></li>
                                    <li><Typography className={classes.bodyText}>Carrying out marketing analysis and profiling and creating statistical and
                                    testing information
                                    </Typography></li>
                                    <li><Typography className={classes.bodyText}>Make sure we are fully aware of any medical conditions that may impact the
                                    way in which you train
                                    </Typography></li>
                                    <li><Typography className={classes.bodyText}>To assess and reduce any potential risks based on your medical conditions
                                    </Typography></li>
                                </ul>
                                <Typography className={classes.bodyText}>
                                When you first provide personal information to us, we will normally give you the opportunity to indicate whether you would prefer us not to contact you in this way. However, you can send us an email at any time to change your preferences or use the unsubscribe links at the bottom of any of our direct marketing email communications.
                                </Typography>
                            </div>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion className={classes.innerAccordion}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.headerText}>
                                What we will not do
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                <Typography className={classes.bodyText}>
                                We will not:
                                </Typography>
                                <ul>
                                    <li><Typography className={classes.bodyText}>Send you communications that you do not expect to receive as part of your 
                                    relationship with us
                                    </Typography></li>
                                    <li><Typography className={classes.bodyText}>Disclose your personal information (unless authorised to do so) </Typography></li>
                                    <li><Typography className={classes.bodyText}>Sell/rent contact information lists to parties who are not part of our business
                                    </Typography></li>
                                </ul>
                            </div>
                        </AccordionDetails>
                    </Accordion>

                    </div>
                </AccordionDetails>
            </Accordion>

            <Accordion className={classes.outerAccordion}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    className={classes.summaryBorder}
                >
                    <Typography className={classes.headerText}>
                        Protection of information
                            </Typography>
                </AccordionSummary>
                <AccordionDetails>

                    <Typography className={classes.bodyText}>
                        We maintain strict security measures to protect your personal information.
                                </Typography>

                </AccordionDetails>
            </Accordion>

            <Accordion className={classes.outerAccordion} style={{marginBottom: '2em'}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    className={classes.summaryBorder}
                >
                    <Typography className={classes.headerText}>
                    Access to personal information
                            </Typography>
                </AccordionSummary>
                <AccordionDetails>

                    <Typography className={classes.bodyText}>
                    The Privacy Act 1993 gives you the right to access and correct personal information held by us. Your information is held securely and will not be disclosed to any other person or organisation unless authorised by you. To query or access any personal information we hold, contact us on info@martials.co.nz or 021 502 333 
                                </Typography>

                </AccordionDetails>
            </Accordion>
        </div>
    );

}