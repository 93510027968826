import React, { useEffect, useState } from 'react';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import './styles.css';
import { client } from "./Client";
import MailView from "./MailView";
import Moment from 'react-moment';
import { useSnackbar } from "notistack";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Box, Button, Checkbox, FormControlLabel, IconButton, TextField, Typography } from '@material-ui/core';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

const useStyles = makeStyles((theme) => ({
    button: {
        maxWidth: '50%',
        marginTop: '5em',
    },
    title: {
        fontWeight: 600,
        cursor: 'pointer',
        "&:hover": {
            color: 'grey',
        },
        fontSize: 'large'
    },
    circleIcon: {
        background: 'yellow',
        padding: '10px',
        borderRadius: '50%',
        width: '50px',
        height: '50px',
        marginRight: '12px',
        textAlign: 'center',
        lineHeight: '50px',
        verticalAlign: 'middle',
    }

}))

export default function Mail({ mailSend, user }) {
    const classes = useStyles();
    const [messages, setMessages] = useState(null);
    const [mail, setMail] = useState(null);
    const [selectMessages, setSelectMessages] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const options = [
        'Mark as Unread',
        'Mark as Read',
        'Delete',
    ];
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const open = Boolean(anchorEl);
    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    }; 

    const handleChangeSelectMessages = (index, checked) => {

        const itemRef = [...selectMessages];

        itemRef[index].selected = checked

        setSelectMessages([...itemRef]);
    }


    const postReadMail = async (message) => {
        const mailUser = { mailUser: { id: message.userMessage.id } }
        const response = await client.post(`userMessages/readMail`, mailUser).catch((err) =>
            console.error(err)
        );
        fetchMail(user);

    };

    const postUnreadMail = async (message) => {
        const mailUser = { mailUser: { id: message.userMessage.id } }
        const response = await client.post(`userMessages/unreadMail`, mailUser).catch((err) =>
            console.error(err)
        );
        fetchMail(user);

    };

    const postDeleteMail = async (message) => {
        const mailUser = { mailUser: { id: message.userMessage.id } }
        const response = await client.post(`userMessages/deleteMail`, mailUser).catch((err) =>
            console.error(err)
        );
        fetchMail(user);

    };


    const fetchMail = async (user) => {
        const response = await client.get(`userMessages/user/`+user.id).catch((err) =>
            console.error("TAG", err)
        );

        if (response) {
            setMessages(response);
            var tmp = []
            response.map((item) =>
                tmp.push({ data: item, selected: false })
            )
            setSelectMessages(tmp);
            return response;
        }
        else {


        }

    };

    useEffect(() => {
        fetchMail(user);
    }, []);


    const removeMail = () => {
        setMail(null);
    }

    const unreadMail = () => {
        selectMessages.map((select) => {
            if (select.selected)
                postUnreadMail(select.data);
        });
    }

    const readMail = () => {
        selectMessages.map((select) => {
            if (select.selected)
                postReadMail(select.data);
        });
    }

    const selectionAction = () => {
        switch(selectedIndex) {
            case 0 :
                return unreadMail();
            case 1 :
                return readMail();
            case 2 :
                return deleteMail();
            default :
                return unreadMail();
        }
    }

    const deleteMail = () => {
        selectMessages.map((select) => {
            if (select.selected)
                postDeleteMail(select.data);
        });
    }

    const openMail = (message) => {
        if (message.userMessage.read === false)
            postReadMail(message);
        setMail(message.message);
    }

    const GoButton = () => {
        if(selectMessages !== null){
            return (
                <Button color="primary" variant="contained" style={{ width: '60px', minWidth: "10%" }} onClick={() => selectionAction()}>Go</Button>
            )
        }
        return (
            <div></div>
        )
    }

    return (
        <Box display="flex" flexDirection="column" style={{ marginLeft: '24px' }}>
            {mail === null ?
                <Box display="flex" flexDirection="column">
                    {selectMessages !== null ? selectMessages.map((select, i) =>
                        <Box display="flex" alignItems="center" style={{ backgroundColor: i % 2 === 1 ? 'gainsboro' : 'lightgrey', paddingLeft: '24px' }}>
                            <PriorityHighIcon className={classes.circleIcon} style={{ background: select.data.userMessage.read === true ? 'grey' : 'yellow', marginBottom: '12px', marginTop: '12px' }} />
                            <Box display="flex" flexDirection="column" style={{ marginBottom: '12px', marginTop: '12px'}} >
                                <Typography className={classes.title} onClick={() => openMail(select.data)}>{select.data.message.title}</Typography>
                                <Typography style={{ fontSize: 'small' }}>from <b>{select.data.message.author}</b></Typography>
                                <Typography style={{ fontSize: 'x-small', color: '#383838' }}><Moment date={select.data.message.createdAt} format="MMM DD, YYYY, h:mm:ss A" /></Typography>
                            </Box>
                            <FormControlLabel style={{ marginLeft: '12px' }}
                                control={<Checkbox key={select.data.message.text} checked={select.selected} onChange={(_e, checked) => handleChangeSelectMessages(i, checked)} />}

                            />
                        </Box>
                    )
                        : []}
                    <Box display="flex" flexDirection="column">
                        <Box display="flex" flexDirection="row">
                            <div >
                                <List
                                    component="nav"
                                    aria-label="Device settings"
                                    sx={{ bgcolor: '#3f51b5', color: 'white' }}
                                >
                                    <ListItem
                                        button
                                        id="lock-button"
                                        aria-haspopup="listbox"
                                        aria-controls="lock-menu"
                                        aria-label="select options"
                                        aria-expanded={open ? 'true' : undefined}
                                        onClick={handleClickListItem}
                                    >
                                        <ListItemText
                                            primary={options[selectedIndex]}
                                        />
                                    </ListItem>
                                </List>
                                <Menu
                                    id="lock-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'lock-button',
                                        role: 'listbox',
                                    }}
                                >
                                    {options.map((option, index) => (
                                        <MenuItem
                                            key={option}
                                            selected={index === selectedIndex}
                                            onClick={(event) => handleMenuItemClick(event, index)}
                                        >
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </div>
                            {selectMessages !== null
                            ? <Button color="primary" variant="contained" style={{ width: '60px', minWidth: "10%" }} onClick={() => selectionAction()}>Go</Button>
                            : <div></div>
                            }
                        </Box>
                        <Button color="primary" variant="contained" style={{ width: '60px', minWidth: "10%", marginTop: '24px' }} onClick={() => mailSend()}>Send mail</Button>
                    </Box>
                </Box>
                : <MailView message={mail} removeMail={removeMail} />}

        </Box>
    )
}