import React, { Fragment } from 'react';
import { Typography, Grid, Paper, Card, CardMedia, Box } from '@material-ui/core/';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import clsx from 'clsx';
import './styles.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const theme = createMuiTheme();

theme.typography.body1 = {
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.2rem',
    fontFamily: 'Helvetica Now Text',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.2rem',
    fontFamily: 'Helvetica Now Text',
  },
};

const useStyles = makeStyles((theme) => ({
  section: {
    height: '100%',
    padding: 24,

  },
  background: {
    minHeight: '75vh',
  },
  primary: {
    color: '#ff3822',
  },
  light: {
    background: '#F2F2F1',
  },
  darkerLight: {
    background: '#dadad9',
    borderBottom: '2px solid #A9A9A9',
    boxShadow: 'inset 0px 4px 8px #A9A9A9',
    zIndex: 1,
  },
  dark: {
    background: '#212125',
    color: '#F2F2F1'
  },
  white: {
    background: 'white',
  },
  center: {
    marginLeft: '25%',
    marginRight: '25%',
  },
  spacedTop: {
    paddingTop: 48,
  },
  spacedBottom: {
    paddingBottom: 48,
  },
  spacedFattyBottom: {
    paddingBottom: 144,
  },
  spaced: {
    paddingBottom: 48,
    paddingTop: 48,
  },
  root: {
    fontSize: 30,
  },
  overlay: {
    position: 'relative',
    top: '60px',
    left: '20px',
    height: '128px',
    width: '128px',
    marginLeft: 'auto',
    //border: '2px solid #F2F2F1',
  },
  fullheight: {
    height: '100%'
  },
  columnGridOnSmall: {
    transition: "all ease 500ms",
    flexDirection: "row",
    /* Custom, iPhone Retina */
    "@media only screen and (max-width : 600px)": {
      margin: 0,
      padding: 0,
      flexDirection: "column",
    },
  },
  mediaTest: {
    transition: "all ease 500ms",
    /* Custom, iPhone Retina */
    "@media only screen and (max-width : 600px)": {
      margin: 0,
      width: '100%',
    },

    /* Extra Small Devices, Phones */
    "@media only screen and (min-width : 1000px)": {
      marginLeft: '5vw',
      width: '90vw',
    },

    /* Small Devices, Tablets */
    "@media only screen and (min-width : 1300px)": {
      marginLeft: '15vw',
      width: '70vw',
    },

    /* Medium Devices, Desktops */
    "@media only screen and (min-width : 1600px)": {
      marginLeft: '20vw',
      width: '60vw',
    },

    /* Large Devices, Wide Screens */
    "@media only screen and (min-width : 1900px)": {
      marginLeft: '25vw',
      width: '50vw',
    },
  },
  topCenter: {
    transition: "all ease 500ms",
    /* Custom, iPhone Retina */
    "@media only screen and (max-width : 700px)": {
      marginLeft: '5%',
      marginRight: '8%',
    },

    /* Extra Small Devices, Phones */
    "@media only screen and (min-width : 1000px)": {
      marginLeft: '10%',
      marginRight: '10%',
    },

    /* Small Devices, Tablets */
    "@media only screen and (min-width : 1300px)": {
      marginLeft: '15%',
      marginRight: '15%',
    },

    /* Medium Devices, Desktops */
    "@media only screen and (min-width : 1600px)": {
      marginLeft: '20%',
      marginRight: '20%',
    },

    /* Large Devices, Wide Screens */
    "@media only screen and (min-width : 1900px)": {
      marginLeft: '25%',
      marginRight: '25%',
    },
  },
}));



export default function Means() {
  const classes = useStyles();

  return (
    //SIZING ISSUE LIKELY OCCURRING IN ROUTES OR HIGHER CLASSES
    <div>
      <Grid
        container
        direction="column"
        
      >

        <div className={clsx(classes.light, classes.spacedTop, classes.spacedBottom)}>
          <div className={classes.topCenter}>
            <div item className={classes.spacedTop} >
              <Typography style={{ fontWeight: '900', fontFamily: 'Helvetica Now Text' }} className={classes.primary} variant="h4" >What does Kiaido Ryu mean?</Typography>
            </div>
            <Box display="flex" >
              <ThemeProvider theme={theme}>
                <Typography variant="body1">
                  <br />Through our unique approach to the martial arts, our internationally certified instructors are committed to building a strong foundation that enables all students to become their own masters in life. Our aim is to develop and enhance the positive qualities and tremendous potential hidden inside all students. To provide the keys and tools that eliminate fear and limited beliefs, and empower students to walk their own unique path in life, to lead a purposeful goal-oriented life.
              </Typography>
              </ThemeProvider>
            </Box>
          </div>
        </div>

        <div style={{ marginTop: '4em', marginBottom: '2em' }}>
          <span className={classes.divider}
            style={{ display: 'block', margin: '29px auto 26px', maxWidth: '50vw', borderBottom: '2px solid #cecece' }}></span>
        </div>

        <div className={clsx(classes.light, classes.spacedFattyBottom)} >
          <div className={classes.mediaTest}>
            <Grid
              container
              item
              justify="center"
              spacing={10}
              className={clsx(classes.section, classes.columnGridOnSmall)}
            >
              <Grid item xs={10} sm={6}>
                <div className={classes.fullheight} >

                  <Card className={clsx(classes.overlay, classes.dark)} elevation={16}>
                    <CardMedia
                      component="img"
                      image="./Image/kiNew.png"
                      title="Lance"
                    />
                  </Card>
                  <Paper className={clsx(classes.section, classes.light)} elevation={16}>

                    <Typography style={{ fontWeight: '900', fontFamily: 'Helvetica Now Text' }} variant="h4" className={classes.primary}>
                      <b>Ki</b>
                    </Typography>

                    <Typography variant="body1">
                      Means life force energy or universal energy. Through our martial arts training, we learn how to connect to this energy and tap into our own internal power. This develops our internal strength, removes limitations and barriers, and releases our potential.
                    </Typography>
                  </Paper>
                </div>

              </Grid>


              <Grid item xs={10} sm={6}>

                <div className={classes.fullheight}>

                  <Card className={clsx(classes.overlay, classes.dark)} elevation={16}>
                    <CardMedia
                      component="img"
                      image="./Image/aiNew.png"
                      title="Lance"
                    />
                  </Card>

                  <Paper className={`${classes.section} ${classes.light}`} elevation={16}>
                    <Typography style={{ fontWeight: '900', fontFamily: 'Helvetica Now Text' }} variant="h4" className={classes.primary}>
                      <b>Ai</b>
                    </Typography>
                    <Typography variant="body1">
                      Stands for the importance of having harmony and balance in our lives. Understanding the philosophy of Yin and Yang we develop physical, mental and spiritual balance in all aspects of our lives.
                  </Typography>
                  </Paper>
                </div>
              </Grid>
            </Grid>



            <Grid
              container
              item
              //justify="center"
              spacing={10}
              className={clsx(classes.section, classes.spacingTop, classes.columnGridOnSmall)}

            >
              <Grid item xs={10} sm={6}>
                <div className={classes.fullheight} >

                  <Card className={clsx(classes.overlay, classes.dark)} elevation={16}>
                    <CardMedia
                      component="img"
                      image="./Image/doNew.png"
                      title="Lance"
                    />
                  </Card>
                  <Paper className={`${classes.section} ${classes.light}`} elevation={16}>
                    <Typography style={{ fontWeight: '900', fontFamily: 'Helvetica Now Text' }} variant="h4" className={classes.primary}>
                      <b>Do</b>
                    </Typography>
                    <Typography variant="body1">
                      Is the Way, or Path to personal growth, fulfillment, and self mastery. Physical, mental, and spiritual growth, and raised consciousness are some of the ultimate goals and outcomes of following the martial Way.
                    </Typography>
                  </Paper>
                </div>
              </Grid>


              <Grid item xs={10} sm={6}>
                <div className={classes.fullheight} >
                  <Card className={clsx(classes.overlay, classes.dark)} elevation={16}>
                    <CardMedia
                      component="img"
                      image="./Image/ryuNew.png"
                      title="Lance"
                    />
                  </Card>
                  <Paper className={`${classes.section} ${classes.light}`} elevation={16}>
                    <Typography style={{ fontWeight: '900', fontFamily: 'Helvetica Now Text' }} variant="h4" className={classes.primary}>
                      <b>Ryu </b>
                    </Typography>

                    <Typography variant="body1">
                      Is our group or family of martial arts schools. Kiaido Ryu has a strong emphasis on providing a positive and supportive family environment. This provides all our students with a support structure of mutual respect that empowers them to become all that they are capable of becoming.
            </Typography>
                  </Paper>
                </div>
              </Grid>
            </Grid>



          </div>
        </div>

      </Grid>

    </div>
  );
}