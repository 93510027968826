import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CardContent, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import './styles.css';
import Button from '@material-ui/core/Button';
import EmailIcon from '@material-ui/icons/Email';
import Gallery from 'react-grid-gallery';


const useStyles = makeStyles((theme) => ({
  root: {
    background: '#F1FAEE',
  },
  textTitle: {
    fontSize: '2rem',
    fontFamily: 'Helvetica Now Text',
    fontWeight: '900',
    color: '#ff3822'
  },
  boxContainer: {
    paddingTop: '2rem',
    marginLeft: '15%',
    paddingBottom: '2rem',
    width: '50%',
    "@media only screen and (max-width : 999px)": {
      width: '85%',
    },
  },
  textBody: {
    fontFamily: 'Helvetica Now Text',
    fontWeight: 'normal',
    marginTop: '1rem',
    fontSize: '1rem',
  },
  text: {
    fontFamily: 'Helvetica Now Text',
    fontWeight: 'normal',
    "@media only screen and (max-width : 660px)": {
      clear: 'both'
    },
  },
  boxBorder: {
    border: "3px solid black",
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingBottom: '1.5rem',
    marginTop: '2rem',
  },
  verticalHeadingText: {
    writingMode: 'vertical-rl',
    textOrientation: 'upright',
    fontSize: '2rem',
    fontFamily: 'Helvetica Now Text',
    fontWeight: '900',
    marginLeft: '1rem',
    color: '#ff3822',
  },
  imageBox: {
    display: "block",
    minHeight: "1px",
    paddingTop: "2%",
    width: '400px',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: "2%",
    border: "1px solid #ddd",
    overflow: "auto",
    "@media only screen and (max-width : 999px)": {
      display: 'none',
    },
  },
}));

const IMAGES =
  [{
    src: './Image/Carousel/1.jpg',
    thumbnail: './Image/Carousel/1.jpg',
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "After Rain (Jeshu John - designerspics.com)"
  },
  {
    src: './Image/Carousel/2.jpg',
    thumbnail: './Image/Carousel/2.jpg',
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: "Boats (Jeshu John - designerspics.com)"
  },
  {
    src: './Image/Carousel/3.jpg',
    thumbnail: './Image/Carousel/3.jpg',
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: "Boats (Jeshu John - designerspics.com)"
  },
  {
    src: './Image/Carousel/4.jpg',
    thumbnail: './Image/Carousel/4.jpg',
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: "Boats (Jeshu John - designerspics.com)"
  },
  {
    src: './Image/Carousel/5.jpg',
    thumbnail: './Image/Carousel/5.jpg',
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: "Boats (Jeshu John - designerspics.com)"
  },

  {
    src: './Image/Carousel/6.jpg',
    thumbnail: './Image/Carousel/6.jpg',
    thumbnailWidth: 320,
    thumbnailHeight: 212
  }]

export default function AucklandRemueraDojo() {
  const classes = useStyles();
  const Mailto = ({ email, subject, body, children }) => {
    return (
      <Button startIcon={<EmailIcon />} color="primary" variant="contained" href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`}>Email Inquiry</Button>
    );
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.root}>
      <Box display="flex">
        <div className={classes.boxContainer}>
          <div style={{ width: '80%', marginBottom: '2rem' }}>
            <Typography className={classes.textTitle} style={{ paddingTop: '2rem' }}>Auckland Remuera Dojo Information</Typography>
            <Typography className={classes.textBody} component="p">
              At Remuera Dojo, we run mixed senior and junior student classes where our tight-knit martial arts family trains together in a fun, safe & positively encouraging atmosphere.  We are an inclusive club where people of all ages (from 5 years and above), races, orientations and physical fitness levels are welcome as every student has their own journey in the martial arts and we are here to help each student on their journey of self-discovery and developing usable self-defence skills along with self-discipline and self-confidence which will also help them in regular life.
            </Typography>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Card variant="outlined" style={{ marginTop: '2rem' }}>
                <CardContent>
                <Typography><b>Juniors and Seniors </b><br />
                Monday and Wednesday - 6.00pm - 7.30pm</Typography>
              <Typography>Contact: Sensei Jayshri Patel, 0211096286</Typography>
              <Typography><b>Location</b> <br />  St Aidans Church Hall, Cnr Ascot Ave and Remuera Road, Auckland   </Typography>
                  <div style={{display: 'flex', alignItems: 'center', justifyContent:'center', marginTop: '24px'}}>
                  <Mailto style={{marginLeft: 'auto'}} email="jaybirdihug@outlook.com" subject="Auckland Remuera Dojo Inquiry" body="Replace with your inquiry!"></Mailto>
                  </div>
                  </CardContent>
              </Card>
            </Box>
          </div>

          <div style={{maxWidth: '85%'}}>
            <Typography className={classes.textTitle}>Sensei Jayshri Patel</Typography>
            <Typography variant="body2" color="textSecondary" component="p" style={{ paddingBottom: '2rem' }}>
              3rd Degree Black Belt (Sandan) - Remuera Dojo Instructor
            </Typography>
            <img style={{ float: 'left', paddingRight: '25px', maxWidth: '240px' }} alt="Jayshri Patel" src="./Image/Remuera/Sensei_Jayshri.JPG"></img>
            <div style={{ display: "inline" }}>
              <Typography className={classes.text}>I had always wanted to train in a martial art since I was a young child, but it wasn't until I was an adult that I decided to take the plunge and start training. After much searching, I discovered Kaido Ryu Martial Arts which incorporated ALL the different martial arts disciplines that I was interested in learning about.


              <br/><br/>I have been training in the KRMA system for almost 20 years and am proud to have reached Sandan level (3rd-degree black belt). I started my martial arts journey at the Remuera Dojo and trained under exemplary Senior Instructors over the years until I was honoured to be offered the opportunity by Hanshi Lance and Kyoshi Ava to become the Senior Instructor myself for the Remuera Dojo.
              
              
              <br/><br/>I now enjoy passing on the knowledge I have gained to my students as well as continuing with my own martial arts journey. </Typography>
            </div>
          </div>
        </div>

        <Box className={classes.imageBox}>
          <Gallery backdropClosesModal={true} images={IMAGES} enableImageSelection={false} margin={12} />
        </Box>

      </Box>
    </div>
  );

}