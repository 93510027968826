import React, { useEffect, useState } from 'react';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Box, Collapse, Typography } from '@material-ui/core';
import { client } from "./Client";
import { useSnackbar } from "notistack";
import Mail from './Mail';
import SendMail from './SendMail';
import Seminar from './Seminar';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import './styles.css';
import BeltVideos from './BeltVideos';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import DescriptionIcon from '@material-ui/icons/Description';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import SportsKabaddiIcon from '@material-ui/icons/SportsKabaddi';
import HomeIcon from '@material-ui/icons/Home';
import MovieIcon from '@material-ui/icons/Movie';
import SummarySheets from './SummarySheets';
import UserPage from './UserPage';
import MailIcon from '@material-ui/icons/Mail';
import { useHistory } from 'react-router';


const theme = createMuiTheme({
    overrides: {
        MuiCollapse: {
          entered: {
            height: "auto",
            overflow: "visible"
          }
        }
      }
});
const drawerWidth = 240;

theme.typography.body1 = {
    fontSize: '1.2rem',
    '@media (min-width:600px)': {
        fontSize: '1.2rem',
        fontFamily: 'Helvetica Now Text',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '1.2rem',
        fontFamily: 'Helvetica Now Text',
    },
};

const useStyles = makeStyles((theme) => ({
    entered: {},
    collapseFix: {
        '&$entered': {
            height: 'auto',
            overflowY: 'scroll'
        },
    },
    root: {
        display: "flex",
    },
    drawer: {
        marginTop: '4rem',
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        //marginTop: '100px',
        marginTop: '4rem',
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        marginTop: '4rem',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },



    playerwrapper: {
        position: 'relative',
    },
    reactplayer: {
        position: 'absolute',
    },
    light: {
        background: '#F2F2F1',
        color: '#212125'
    },
    dark: {
        background: '#212125',
        color: '#F2F2F1'
    },
    videoHeading: {
        color: '#DC2F02',
        fontFamily: 'Helvetica Now Text',
        paddingTop: '1%',
        paddingBottom: '1%',
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, ' +
            'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
        width: '94%',
    },
    spaced: {
        paddingBottom: '5%',
        paddingTop: '1%',
        paddingLeft: '5%',
        paddingRight: '5%',
    },
    beltContainer: {
        marginTop: '6rem',
        marginBottom: '6rem',
        flexWrap: 'wrap',
    },
    beltImage: {
        marginLeft: '4rem',
        marginRight: '4rem',
        maxHeight: '200px',
        maxWidth: '200px',
    },
    beltText: {
        alignText: 'center',
        fontFamily: 'Helvetica Now Text',
        fontWeight: 'normal',
    },
}));


export default function Profile() {
    const classes = useStyles();
    const [user, setUser] = useState(null);
    const [belts, setBelts] = useState(null);
    const [beltId, setBeltId] = useState(0);
    const [currentVideos, setCurrentVideos] = useState(null);
    const [currentBelt, setCurrentBelt] = useState(null);
    const [homePageOpen, setHomePageOpen] = useState(true);
    const [myVideoOpen, setMyVideoOpen] = React.useState(false);
    const [videoObject, setVideoObject] = useState([]);
    const [currentPage, setCurrentPage] = useState('Members Home');
    const [summarySheetOpen, setSummarySheetOpen] = React.useState(false);
    const [mailPageOpen, setMailPageOpen] = useState(false);
    const [mailSendOpen, setMailSendOpen] = useState(false);
    const [seminarOpen, setSeminarOpen] = useState(false);
    const [hasSeminar, setHasSeminar] = useState(false);
    const history = useHistory();



    const [mySeminarOpen, setMySeminarOpen] = React.useState(false);
    const [drawer, setDrawer] = useState(
        <div>
            <div className={classes.toolbar} />
            <Divider />
            <List>
                {['Default'].map((text, index) => (
                    <ListItem button key={text}>
                        <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItem>
                ))}
            </List>
        </div>
    );
    var videoList;
    var beltList;

    const [videos, setVideos] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };


    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));

        if (user) {
            setUser(user.user);
            fetchVideos(user.user);
            fetchBelts(user.user);
            fetchSeminarUser(user.user);
            fetchBeltId(user.user.rank)
        }
        else{
            localStorage.setItem('loggedIn', false);
            history.push('/');
        }
        
    }, [])

    const fetchBeltId = async (rank) => {
        const response = await client.get(`belts/rank/${rank}`).catch((err) =>
            console.error("TAG", err)
        );
    
        if (response) {
            setBeltId(response.id)
            return response.id
        }
        else {
            // enqueueSnackbar("Belt id loading failed - retying...", {
            //     variant: "default",
            // });
            fetchBeltId(rank);
        }
    
    };

    const fetchSeminarUser = async (user) => {
        const response = await client.get(`seminars/id/${user.id}`).catch((err) =>
            console.error("TAG", err)
        );
    
        if (response) {
            if (response.user === user.id) {
                setHasSeminar(true);
            }
            else {
                setHasSeminar(false);
            }
        }
        else {
            setHasSeminar(false);
        }
    };


    const fetchVideos = async (user) => {
        const response = await client.get(`videos/rank/${user.rank}`).catch((err) =>
            console.error(err)
        );
        if (response) {
            const unique = [...new Set(response.map(item => item.category))];
            setVideos(response);
            videoList = response;
        }
        else {
            // enqueueSnackbar("Video loading failed, retrying...", {
            //     variant: "default",
            // });
            fetchVideos(user);
        }

    };

    const clickSummarySheet = () => {
        setSummarySheetOpen(true);
        setHomePageOpen(false);
        setCurrentPage('Summary Sheets');
        setCurrentBelt(null);
        setMailPageOpen(false);
        setSeminarOpen(false);
        setCurrentVideos(null);
    }

    const clickHomePage = () => {
        setSummarySheetOpen(false);
        setHomePageOpen(true);
        setCurrentPage('Members Home');
        setCurrentBelt(null);
        setMailPageOpen(false);
        setCurrentVideos(null);
        setSeminarOpen(false);

    }

    const clickMailSend = () => {
        setSummarySheetOpen(false);
        setHomePageOpen(false);
        setCurrentPage('Send Mail');
        setMailSendOpen(true);
        setMailPageOpen(false);
        setCurrentBelt(null);
        setCurrentVideos(null);
        setSeminarOpen(false);

    }

    const clickMail = () => {
        setSummarySheetOpen(false);
        setHomePageOpen(false);
        setMailPageOpen(true);
        setMailSendOpen(false);
        setCurrentPage('Mail');
        setCurrentBelt(null);
        setCurrentVideos(null);
        setSeminarOpen(false);

    }


    const clickSeminar = () => {
        setSummarySheetOpen(false);
        setHomePageOpen(false);
        setMailPageOpen(false);
        setMailSendOpen(false);
        setCurrentPage('Seminar');
        setCurrentBelt(null);
        setCurrentVideos(null);
        setSeminarOpen(true);
    }

    const clickBelt = (belt) => {
        setSummarySheetOpen(false);
        setHomePageOpen(false);
        setMailPageOpen(false);
        setMailSendOpen(false);
        setSeminarOpen(false);

        var selected;
        //use case instead
        if (!belt) {
            setCurrentBelt(null);
            setCurrentVideos(null);
            setCurrentPage('Members Home');
        }
        else if (belt === 'White') {
            selected = videoList.filter(item => item.minRank === 1);
            setCurrentBelt(beltList[0]);
            setCurrentPage('White Belt Videos')
            setCurrentVideos(selected);
            setVideoObject(<BeltVideos belt={beltList[0]} videos={selected}></BeltVideos>)
        }
        else if (belt === 'Yellow') {
            selected = videoList.filter(item => item.minRank === 2);
            setCurrentBelt(beltList[1]);
            setCurrentPage('Yellow Belt Videos')
            setCurrentVideos(selected);
            setVideoObject(<BeltVideos belt={beltList[1]} videos={selected}></BeltVideos>)
        }
        else if (belt === 'Orange') {
            selected = videoList.filter(item => item.minRank === 3);
            setCurrentBelt(beltList[2]);
            setCurrentPage('Orange Belt Videos')
            setCurrentVideos(selected);
            setVideoObject(<BeltVideos belt={beltList[2]} videos={selected}></BeltVideos>)
        }
        else if (belt === 'Blue') {
            selected = videoList.filter(item => item.minRank === 4);
            setCurrentBelt(beltList[3]);
            setCurrentPage('Blue Belt Videos')
            setCurrentVideos(selected);
            setVideoObject(<BeltVideos belt={beltList[3]} videos={selected}></BeltVideos>)
        }
        else if (belt === 'Purple') {
            selected = videoList.filter(item => item.minRank === 5);
            setCurrentBelt(beltList[4]);
            setCurrentPage('Purple Belt Videos')
            setCurrentVideos(selected);
            setVideoObject(<BeltVideos belt={beltList[4]} videos={selected}></BeltVideos>)
        }
        else if (belt === 'Green') {
            selected = videoList.filter(item => item.minRank === 6);
            setCurrentBelt(beltList[5]);
            setCurrentPage('Green Belt Videos')
            setCurrentVideos(selected);
            setVideoObject(<BeltVideos belt={beltList[5]} videos={selected}></BeltVideos>)
        }
        else if (belt === 'Brown') {
            selected = videoList.filter(item => item.minRank === 7);
            setCurrentBelt(beltList[6]);
            setCurrentPage('Brown Belt Videos')
            setCurrentVideos(selected);
            setVideoObject(<BeltVideos belt={beltList[6]} videos={selected}></BeltVideos>)
        }
        else if (belt === 'Shodan') {
            selected = videoList.filter(item => item.minRank === 8);
            setCurrentBelt(beltList[7]);
            setCurrentPage('Shodan Videos')
            setCurrentVideos(selected);
            setVideoObject(<BeltVideos belt={beltList[7]} videos={selected}></BeltVideos>)
        }
        else if (belt === 'Nidan') {
            selected = videoList.filter(item => item.minRank === 9);
            setCurrentBelt(beltList[8]);
            setCurrentPage('Sandan Videos')
            setCurrentVideos(selected);
            setVideoObject(<BeltVideos belt={beltList[8]} videos={selected}></BeltVideos>)
        }
        else if (belt === 'Sandan') {
            selected = videoList.filter(item => item.minRank === 10);
            setCurrentBelt(beltList[9]);
            setCurrentPage('Sandan Videos')
            setCurrentVideos(selected);
            setVideoObject(<BeltVideos belt={beltList[9]} videos={selected}></BeltVideos>)
        }
        else if (belt === 'Yondan') {
            selected = videoList.filter(item => item.minRank === 11);
            setCurrentBelt(beltList[10]);
            setCurrentPage('Yondan Videos')
            setCurrentVideos(selected);
            setVideoObject(<BeltVideos belt={beltList[10]} videos={selected}></BeltVideos>)
        }
        else if (belt === 'Godan') {
            selected = videoList.filter(item => item.minRank === 12);
            setCurrentBelt(beltList[11]);
            setCurrentPage('Godan Videos')
            setCurrentVideos(selected);
            setVideoObject(<BeltVideos belt={beltList[11]} videos={selected}></BeltVideos>)
        }
        else if (belt === 'Rokudan') {
            selected = videoList.filter(item => item.minRank === 13);
            setCurrentBelt(beltList[12]);
            setCurrentPage('Rokudan Videos')
            setCurrentVideos(selected);
            setVideoObject(<BeltVideos belt={beltList[12]} videos={selected}></BeltVideos>)
        }
        else if (belt === 'Sichidan') {
            selected = videoList.filter(item => item.minRank === 14);
            setCurrentBelt(beltList[13]);
            setCurrentPage('Sichidan Videos')
            setCurrentVideos(selected);
            setVideoObject(<BeltVideos belt={beltList[13]} videos={selected}></BeltVideos>)
        }
        else if (belt === 'Sichidan') {
            selected = videoList.filter(item => item.minRank === 15);
            setCurrentBelt(beltList[14]);
            setCurrentPage('Sichidan Videos')
            setCurrentVideos(selected);
            setVideoObject(<BeltVideos belt={beltList[14]} videos={selected}></BeltVideos>)
        }
        else if (belt === 'Hachidan') {
            selected = videoList.filter(item => item.minRank === 16);
            setCurrentBelt(beltList[15]);
            setCurrentPage('Hachidan Videos')
            setCurrentVideos(selected);
            setVideoObject(<BeltVideos belt={beltList[15]} videos={selected}></BeltVideos>)
        }
        else if (belt === 'Kudan') {
            selected = videoList.filter(item => item.minRank === 17);
            setCurrentBelt(beltList[16]);
            setCurrentPage('Kudan Videos')
            setCurrentVideos(selected);
            setVideoObject(<BeltVideos belt={beltList[16]} videos={selected}></BeltVideos>)
        }
        else if (belt === 'Judan') {
            selected = videoList.filter(item => item.minRank === 18);
            setCurrentBelt(beltList[17]);
            setCurrentPage('Judan Videos')
            setCurrentVideos(selected);
            setVideoObject(<BeltVideos belt={beltList[17]} videos={selected}></BeltVideos>)
        }
    }



    const fetchBelts = async (user) => {
        const response = await client.get(`belts/availableRanks/${user.rank}`).catch((err) =>
            console.error(err)
        );

        if (response) {
            setBelts(response);
            beltList = response;
            response.sort((a, b) => a.id - b.id);
            setDrawer(
                <div >

                    <List>
                        {response.map((belt, index) => (
                            <ListItem button key={belt.name} onClick={() => clickBelt(belt.name)}>
                                <ListItemIcon><MovieIcon/></ListItemIcon>
                                <ListItemText primary={belt.name} />
                            </ListItem>
                        ))}
                    </List>
                </div>
            );

        }
        else {
            // enqueueSnackbar("Belt loading failed, retrying...", {
            //     variant: "default",
            // });
            fetchBelts(user);

        }

    };

    const handleMyVideoClick = () => {
        setMyVideoOpen(!myVideoOpen);
    };

    const handleMySeminarClick = () => {
        setMySeminarOpen(!mySeminarOpen);
    };



    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar className={classes.appBar}>
                <Toolbar display="flex">
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography Typography variant="h6" noWrap>{currentPage}</Typography>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden mdUp implementation="css">
                    <Drawer
                        style={{ marginTop: '4rem', overflow: 'hidden'}}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        
                             <div className={classes.toolbar} >
                        
                         </div>
                         { user ? user.email === "KaneJScott@gmail.com" ||  user.email === "paul@martialarts.co.nz"?
                         <IconButton aria-label="mail" onClick={() => clickMail()}><MailIcon/></IconButton>
                         : []
                         : []
                        }
                        <Divider />
                        
                        <ListItem button key='home' onClick={() => clickHomePage()}>
                            <ListItemIcon> <MailIcon /></ListItemIcon>
                            <ListItemText primary='Home' />
                        </ListItem>
                        <ListItem button onClick={handleMyVideoClick}>
                            <ListItemIcon>
                                <VideoLibraryIcon />
                            </ListItemIcon>
                            <ListItemText primary="My Videos" />
                            {myVideoOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse classes={{container: classes.collapseFix , entered: classes.entered}} in={myVideoOpen} timeout="auto" unmountOnExit>
                            <List style={{ marginLeft: '1rem' }} component="div" disablePadding>
                                {drawer}
                            </List>
                        </Collapse>
                        <ListItem button onClick={() => clickSummarySheet()}>
                            <ListItemIcon> <DescriptionIcon /></ListItemIcon>
                            <ListItemText primary='Summary Sheets' />
                        </ListItem>
                        <ListItem button onClick={handleMySeminarClick}>
                            <ListItemIcon>
                                <VideoLibraryIcon />
                            </ListItemIcon>
                            <ListItemText primary="Seminars" />
                            {mySeminarOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={mySeminarOpen} timeout="auto" unmountOnExit>

                            <List style={{ marginLeft: '1rem' }} component="div" disablePadding>
                                {
                                    hasSeminar
                                        ? <ListItem button key={"seminar"} onClick={() => clickSeminar()}>
                                            <ListItemIcon><MovieIcon /></ListItemIcon>
                                            <ListItemText primary={"Black Belt Seminar"} />
                                        </ListItem>
                                        : <ListItem disabled button key='tournaments'>
                                        <ListItemIcon> <SportsKabaddiIcon /></ListItemIcon>
                                        <ListItemText primary='No seminars available' />
                                    </ListItem>
                                }

                            </List>
                        </Collapse>


                        <ListItem disabled button key='training'>
                            <ListItemIcon> <AssignmentIndIcon /> </ListItemIcon>
                            <ListItemText primary='Training Record - Coming Soon™' />
                        </ListItem>
                        <ListItem disabled button key='tournaments'>
                            <ListItemIcon> <SportsKabaddiIcon /></ListItemIcon>
                            <ListItemText primary='Tournaments - Coming Soon™' />
                        </ListItem>
                    </Drawer>
                </Hidden>
                <Hidden smDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        
                            <div className={classes.toolbar} >
                        
                         </div>
                         { user ? user.email === "KaneJScott@gmail.com" ||  user.email === "paul@martialarts.co.nz" ?
                         <IconButton aria-label="mail" onClick={() => clickMail()}><MailIcon/></IconButton>
                         : []
                         : []
                        }
                    
                        <Divider />
                        <ListItem button key='home' onClick={() => clickHomePage()}>
                            <ListItemIcon> <HomeIcon /></ListItemIcon>
                            <ListItemText primary='Home' />
                        </ListItem>
                        <ListItem button onClick={handleMyVideoClick}>
                            <ListItemIcon>
                                <VideoLibraryIcon />
                            </ListItemIcon>
                            <ListItemText primary="My Videos" />
                            {myVideoOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse classes={{container: classes.collapseFix , entered: classes.entered}} in={myVideoOpen} timeout="auto" unmountOnExit>
                            <List style={{ marginLeft: '1rem' }} component="div" disablePadding>
                                {drawer}
                            </List>
                        </Collapse>

                        <ListItem button onClick={() => clickSummarySheet()}>
                            <ListItemIcon> <DescriptionIcon /></ListItemIcon>
                            <ListItemText primary='Summary Sheets' />
                        </ListItem>
                        <ListItem button onClick={handleMySeminarClick}>
                            <ListItemIcon>
                                <VideoLibraryIcon />
                            </ListItemIcon>
                            <ListItemText primary="Seminars" />
                            {mySeminarOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={mySeminarOpen} timeout="auto" unmountOnExit>
                            <List style={{ marginLeft: '1rem' }} component="div" disablePadding>
                            {
                                hasSeminar
                                    ? <ListItem button key={"seminar"} onClick={() => clickSeminar()}>
                                        <ListItemIcon><MovieIcon /></ListItemIcon>
                                        <ListItemText primary={"Black Belt Seminar"} />
                                    </ListItem>
                                    : <ListItem disabled button key='tournaments'>
                                    <ListItemIcon> <SportsKabaddiIcon /></ListItemIcon>
                                    <ListItemText primary='No seminars available' />
                                </ListItem>
                            }
                            </List>
                        </Collapse>


                        <ListItem disabled button key='training'>
                            <ListItemIcon> <AssignmentIndIcon /> </ListItemIcon>
                            <ListItemText primary='Training Record - Coming Soon™' />
                        </ListItem>
                        <ListItem disabled button key='tournaments'>
                            <ListItemIcon> <SportsKabaddiIcon /></ListItemIcon>
                            <ListItemText primary='Tournaments - Coming Soon™' />
                        </ListItem>
                    </Drawer>
                </Hidden>
            </nav>

            <main className={classes.content}>
                <div className={classes.toolbar} />

                <ThemeProvider theme={theme}>

                    {!currentBelt && !currentVideos
                        ?
                        []
                        

                        // : <BeltVideos belt={currentBelt} videos={currentVideos}></BeltVideos>
                        : videoObject
                    }
                    {
                        homePageOpen && user && videos ? <UserPage user={user} videos={videos}></UserPage> : 
                        homePageOpen ? <Box container style={{display: 'flex', alignItems: 'center'}}>
                        <Typography >Attempting to load account...</Typography>
                        </Box>
                        : []
                    }
                    {
                        summarySheetOpen ? <SummarySheets belt={beltId}/> : null
                    }
                    {
                        mailPageOpen ? <Mail mailSend={clickMailSend} user={user}/> : null
                    }
                    {
                        seminarOpen ? <Seminar/> : null
                    }
                    {
                        mailSendOpen ? <SendMail mail={clickMail}/> : null
                    }
                    
                </ThemeProvider>

            </main>
        </div>
    );
}