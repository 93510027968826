import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useHistory, useLocation } from "react-router-dom";
import { client } from "./Client";
import { useSnackbar } from "notistack";




const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    redText: {
        color: 'red',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));



export default function SignIn(props) {
    const classes = useStyles();
    const { data } = useLocation();
    if (data) {
        var { checkLoginFunc } = data;
    }
    const history = useHistory();
    const [showFailed, setShowFailed] = useState(false);
    const [showInactive, setShowInactive] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [checkbox, setCheckbox] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const localCheckbox = localStorage.getItem('checkbox');
        const localEmail = localStorage.getItem('email');
        const localPass = localStorage.getItem('password');
        if (localCheckbox) {
            let parsedBox = JSON.parse(localCheckbox);
            setCheckbox(parsedBox);
            if (parsedBox) {
                if (localEmail)
                    setEmail(localEmail);
                if (localPass)
                    setPassword(localPass);
            }
        }

    }, [])

    const fetchUser = async (email, password) => {
        const user = {
            user: {
                email: `${email}`,
                password: `${password}`
            }
        };
        const response = await client.post(`users/login`, user).catch((err) =>
            console.error(err)
        );
        if (response) {
            setShowFailed(false);
            if (response.user.active === false) {
                setShowInactive(true);
                localStorage.setItem('loggedIn', false);
                enqueueSnackbar("User no longer active. Unable to access members area until active", {
                    variant: "default",
                });
            }
            else {
                setShowInactive(false);
                //TEMP FIX FOR ROLL CALL
                localStorage.setItem('loggedIn', true);
                localStorage.setItem('email', email);
                localStorage.setItem('user', JSON.stringify(response));
                if (checkLoginFunc) {
                    checkLoginFunc();
                }
                history.push('/profile');
            }
        }
        else {
            setShowFailed(true);
            if (localStorage.getItem('user') !== "") {
                enqueueSnackbar("Login session expired, please try again", {
                    variant: "default",
                });
                localStorage.setItem('user', "");
                localStorage.setItem('email', "");
                localStorage.setItem('password', "");
                setEmail("");
                setPassword("");
            } else {
                enqueueSnackbar("Login failed", {
                    variant: "default",
                });
            }
        }

    };

    const handleCheckbox = (evt) => {
        let checked = !checkbox;
        localStorage.setItem('checkbox', checked);
        if (checked) {
            localStorage.setItem('email', email);
            localStorage.setItem('password', password);

        }
        setCheckbox(checked);

    }

    const handleLostPassword = () => {
        if (checkLoginFunc) {
            let location = {
                pathname: '/recoverpassword',
                data: { checkLoginFunc: checkLoginFunc }
            }
            history.push(location)
        }
        else {
            history.push('/recoverpassword')
        }
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        fetchUser(email, password);
        if (checkbox) {
            localStorage.setItem('email', email);
            localStorage.setItem('password', password);
        }
    }

    return (
        <Container style={{ height: "82vh" }} component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <form className={classes.form} noValidate onSubmit={handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        value={email}
                        label="Email Address"
                        name="email"
                        onChange={e => setEmail(e.target.value)}
                        autoComplete="email"
                        autoFocus
                    />
                    {showFailed ? <Typography className={classes.redText} variant="subtitle2">
                        Incorrect login details
                    </Typography> : null}
                    {showInactive ? <Typography className={classes.redText} variant="subtitle2">
                        Account inactive
                    </Typography> : null}
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        autoComplete="current-password"
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" checked={checkbox} color="primary" />}
                        label="Remember me"
                        onClick={handleCheckbox}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Sign In
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link variant="body2" style={{ cursor: "pointer" }} onClick={() => handleLostPassword()} >
                                Forgot password?
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>

        </Container>
    );
}