import React, { Fragment } from 'react';
import { Typography, Grid } from '@material-ui/core/';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './styles.css';

const theme = createMuiTheme();

theme.typography.body1 = {
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.2rem',
    fontFamily: 'Helvetica Now Text',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.2rem',
    fontFamily: 'Helvetica Now Text',
  },
};

const useStyles = makeStyles((theme) => ({
  spacingTop: {
    marginTop: 40,
  },
  caro: {
    width: '100%',
    minWidth: '10%'
  },
  text: {
    fontSize: '1.2rem',
    fontFamily: 'Helvetica Now Text',
    fontWeight: 'normal',
  },
  textTitle: {
    fontSize: '2rem',
    fontFamily: 'Helvetica Now Text',
    fontWeight: '900',
    marginBottom: '1rem',
    color: '#ff3822'
  },
  textTitleAva: {
    fontSize: '2rem',
    fontFamily: 'Helvetica Now Text',
    fontWeight: '900',
    marginBottom: '1rem',
    marginLeft: '-4rem',
    color: '#ff3822',
    "@media only screen and (max-width : 600px)": {
      marginLeft: '0',
    },
  },
  boxContainer: {
    marginTop: '4em',

    transition: "all ease 500ms",
    /* Custom, iPhone Retina */
    "@media only screen and (max-width : 999px)": {
      marginLeft: '5vw',
      marginRight: '5vw',
    },

    /* Extra Small Devices, Phones */
    "@media only screen and (min-width : 1000px)": {
      marginLeft: '5vw',
      marginRight: '15vw',
    },

    /* Small Devices, Tablets */
    "@media only screen and (min-width : 1300px)": {
      marginLeft: '10vw',
      marginRight: '25vw',
    },

    /* Medium Devices, Desktops */
    "@media only screen and (min-width : 1600px)": {
      marginLeft: '10vw',
      marginRight: '30vw',
    },

    /* Large Devices, Wide Screens */
    "@media only screen and (min-width : 1900px)": {
      marginLeft: '20vw',
      marginRight: '30vw',
    },
  },
  boxContainerAva: {
    marginTop: '4em',

    transition: "all ease 500ms",
    /* Custom, iPhone Retina */
    "@media only screen and (max-width : 999px)": {
      marginLeft: '5vw',
      width: '90vw',
    },

    /* Extra Small Devices, Phones */
    "@media only screen and (min-width : 1000px)": {
      marginLeft: '5vw',
      width: '80vw',
    },

    /* Small Devices, Tablets */
    "@media only screen and (min-width : 1300px)": {
      marginLeft: '10vw',
      width: '65vw',
    },

    /* Medium Devices, Desktops */
    "@media only screen and (min-width : 1600px)": {
      marginLeft: '10vw',
      width: '60vw',
    },

    /* Large Devices, Wide Screens */
    "@media only screen and (min-width : 1900px)": {
      marginLeft: '20vw',
      width: '50vw',
    },
  },

  lanceImage: {
    float: 'left', 
    paddingRight: '25px',
    "@media only screen and (max-width : 600px)": {
      marginLeft: 'auto',
      marginRight: 'auto',
      float: 'none',
      display: `block`,
      marginBottom: '24px'

    },
    
  },

  textWrapper: {
    /* Custom, iPhone Retina */
    "@media only screen and (max-width : 600px)": {
      display: "inline-block",

    },
    /* Small Devices, Tablets */
    "@media only screen and (min-width : 1300px)": {
      display: "inline",
    },

  },

  avaImage: {
    float: 'right', 
    paddingRight: '25px', 
    maxWidth: '240px',
    "@media only screen and (max-width : 600px)": {
      marginLeft: 'auto',
      marginRight: 'auto',
      float: 'none',
      display: `block`,
      marginBottom: '24px'
    },
  },

  verticalHeadingText: {
    writingMode: 'vertical-rl',
    textOrientation: 'upright',
    fontSize: '2rem',
    fontFamily: 'Helvetica Now Text',
    fontWeight: '900',
    marginLeft: '1rem',
    color: '#ff3822',
    "@media only screen and (max-width : 600px)": {
      display: `none`,
    },
  },
  verticalHeadingTextAva: {
    writingMode: 'vertical-rl',
    textOrientation: 'upright',
    fontSize: '2rem',
    fontFamily: 'Helvetica Now Text',
    fontWeight: '900',
    marginRight: '4rem',
    color: '#ff3822',
    "@media only screen and (max-width : 600px)": {
      display: `none`,
    },
  },
  textSubtitleAva: {
    paddingBottom: '2rem',
     marginLeft: '-4rem',
     "@media only screen and (max-width : 600px)": {
      marginLeft: '0',
    },
  }

}));

export default function Founder() {
  const classes = useStyles();

  return (
    <div>
      <ThemeProvider theme={theme}>

        <Box display="flex" className={classes.boxContainer}>
          <div >
            <Typography className={classes.textTitle}>Founder of Kiaido Ryu Martial Arts (KRMA)</Typography>
            <Typography variant="body2" color="textSecondary" component="p" style={{ paddingBottom: '2rem' }}>
              Grandmaster Lance Strong MNZM, 9th Degree Black Belt (Hanshi)
            </Typography>
            <img className={classes.lanceImage} style={{ }} alt="lance" src="./Image/affiliation1.jpg"></img>
            <div className={classes.textWrapper}>
              <Typography className={classes.text}>KRMA was founded by Grandmaster, 9th Degree Black Belt, Lance Strong (MNZM).
                He’s been involved in martial arts for 60 years and an instructor for 38 years. </Typography>
              <Typography className={classes.text}><br />2018 - Lance was awarded 9th Degree Black Belt in KRMA and Wei Kuen Do by Grandmaster Leo Fong and the KRMA Masters Board</Typography>
              <Typography className={classes.text}><br />2019 - Lance received the Member New Zealand Order of Merit (MNZM) for service to Karate and the Community. (MNZM)
              </Typography>
              <Typography className={classes.text}><br />2021 - Lance was inducted into the New Zealand Martial Arts Hall of Fame (NZMAHOF)
              </Typography>
              <Typography className={classes.text}><br />"Lance is a visionary who sees martial arts as a continuous journey toward self-improvement. He’s a true teacher able to guide students to the door of knowledge and inspire them to enter and discover themselves." Grandmaster Leo Fong - Los Angeles USA
              </Typography>
              <Typography className={classes.text}><br />
                Lance has always had a passion for the development of human potential, helping people live their very best lives. He combines modern motivational training with the philosophy and teachings of the martial arts, to make this knowledge readily available through his "Warriors Wisdom" course; based on his book "Warriors Wisdom
              </Typography>
              <Typography className={classes.text}><br />Lance has done stunt work and weapons choreography for TV shows Hercules and Xena.
                He also has an extensive background in Education and Training, working as a Training Manager for Toyota New Zealand for many years. He also spent several years in the USA working as a Senior VP of Operations, while continuing his extensive martial arts studies.
              </Typography>
              <Typography className={classes.text}><br />KRMA is affiliated to Grandmaster Leo Fong's Wei Kuen Do in the USA.
              </Typography>
            </div>


          </div>
          <Box display="flex" style={{ alignItems: 'center' }}>
            <Typography className={classes.verticalHeadingText}>グランドマスターランスストロング</Typography>
          </Box>
        </Box>
        <div style={{ marginLeft: '-10vw', marginTop: '4em' }}>
          <span
            style={{ display: 'block', margin: '29px auto 26px', borderBottom: '1px solid #cecece', width: '35%' }}></span>
        </div>
        <Box display="flex" className={classes.boxContainerAva}>
          <Box display="flex" style={{ alignItems: 'center' }}>
            <Typography className={classes.verticalHeadingTextAva}>グランドマスターエイバストロング</Typography>
          </Box>
          <div >
            <Typography className={classes.textTitleAva}>Co-founder of Kiaido Ryu Martial Arts (KRMA)</Typography>
            <Typography variant="body2" color="textSecondary" component="p" className={classes.textSubtitleAva}>
              Grandmaster Ava Strong MNZM, 8th Degree Black Belt (Kyoshi)
            </Typography>
            <img className={classes.avaImage} style={{  }} alt="ava" src="./Image/Ava.jpg"></img>
            <div className={classes.textWrapper}>
              <Typography className={classes.text}>As one of the founding members of Kiaido Ryu Ava is the highest ranked woman in
                KRMA and has been studying the Martial Arts for over 38 years.
                In July 2019 Ava received her 8th Degree Black Belt sanctioned by Grandmaster
                Leo Fong 10th Degree Black Belt USA, and the KRMA Masters Board.<br /><br />
                Ava received one of the highest recognitions for her work in the 2019 NZ Honours by
                being awarded a Member of the New Zealand Order of Merit for her contribution to
                martial arts and the community.<br /><br />
                Ava has trained extensively in the USA and Canada in Karate, Kyusho Jitsu (6 th
                Degree), Wei Kuen Do (8 th Degree), Jiu Jitsu and Arnis under some of the world’s
                leading martial arts masters.<br /><br />
                “Some of the highlights of my journey were helping to empower women through
                Community Self Defence Courses, Wahine Toa Courses for women, and being a
                champion for the Kids Right2besafe program run through CAPS Hauraki”.<br /><br />
                Ava also worked for five years as a stunt person and stunt double on the television
                shows "Xena" and ""Hercules" and also worked on the movie "The Last Samurai"<br /><br />
                “Empowering woman in the Community through martial arts helps to take the victims
                out of society and Kiaido Ryu definitely makes a contribution to this effort.” </Typography>
            </div>
          </div>



        </Box>

        <Box display="flex" justifyContent="center" style={{ marginTop: '4em', marginBottom: '4em', maxWidth: "80%", marginLeft: "auto", marginRight: "auto"  }}>
          <Box display="flex" flexDirection='column' style={{ border: "3px solid #ff3822", paddingLeft: '25px', paddingRight: '25px', paddingBottom: '10px', maxWidth: "500px" }}>
            <Typography className={classes.text} style={{ fontWeight: 'bold', }}><br />All KRMA ranks are internationally sanctioned by a panel of Masters</Typography>
            <ul>
              <li><Typography className={classes.text}>Grandmaster Lance Strong 9th Degree Black Belt</Typography></li>
              <li><Typography className={classes.text}>Grandmaster Ava Strong 8th Degree Black Belt</Typography></li>
              <li><Typography className={classes.text}>Master Paul Hinton 6th Degree Black Belt</Typography></li>
              <li><Typography className={classes.text}>Master Phil Murray 5th Degree Black Belt</Typography></li>
            </ul>
          </Box>
        </Box>

      </ThemeProvider>
    </div>
  );
}