// A tiny wrapper around fetch(), borrowed from
// https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper

let token = null;

export { token };
export const setToken = (t) => {
  token = t;
};
export async function client(endpoint, { body, ...customConfig } = {}) {
  const url = 'https://www.martialarts.co.nz/api/';
  //const url = 'http://localhost:3000/';


  
  const headers = { "Content-Type": "application/json" };
  if ("user" in localStorage) {
    if (localStorage.getItem("user") !== "") {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user.user.token) headers.Authorization = "Bearer " + user.user.token;
    }
  }

  if (token) {
    headers.Authorization = "Bearer " + token;
  }
  const config = {
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  if (body) {
    config.body = JSON.stringify(body);
  }

  let data;
  try {
    const response = await window.fetch(`${url}${endpoint}`, config);
    data = await response.json();
    if (response.ok) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data);
  }
}

client.get = function (endpoint, customConfig = {}) {
  return client(endpoint, { ...customConfig, method: "GET" });
};

client.post = function (endpoint, body, customConfig = {}) {
  return client(endpoint, { ...customConfig, body, method: "POST" });
};

client.patch = function (endpoint, body, customConfig = {}) {
  return client(endpoint, { ...customConfig, body, method: "PATCH" });
};

client.delete = function (endpoint, customConfig = {}) {
  return client(endpoint, { ...customConfig, method: "DELETE" });
};