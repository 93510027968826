import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CardContent, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import './styles.css';
import Button from '@material-ui/core/Button';
import EmailIcon from '@material-ui/icons/Email';
import Gallery from 'react-grid-gallery';


const useStyles = makeStyles((theme) => ({
  root: {
    background: '#F1FAEE',
  },
  textTitle: {
    fontSize: '2rem',
    fontFamily: 'Helvetica Now Text',
    fontWeight: '900',
    color: '#ff3822'
  },
  boxContainer: {
    paddingTop: '2rem',
    marginLeft: '15%',
    paddingBottom: '2rem',
    width: '50%',
    "@media only screen and (max-width : 999px)": {
      width: '85%',
    },
  },
  textBody: {
    fontFamily: 'Helvetica Now Text',
    fontWeight: 'normal',
    marginTop: '1rem',
    fontSize: '1rem',
  },
  text: {
    fontFamily: 'Helvetica Now Text',
    fontWeight: 'normal',
  },
  boxBorder: {
    border: "3px solid black",
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingBottom: '1.5rem',
    marginTop: '2rem',
  },
  verticalHeadingText: {
    writingMode: 'vertical-rl',
    textOrientation: 'upright',
    fontSize: '2rem',
    fontFamily: 'Helvetica Now Text',
    fontWeight: '900',
    marginLeft: '1rem',
    color: '#ff3822',
  },
  imageBox: {
    display: "block",
    minHeight: "1px",
    paddingTop: "2%",
    width: '400px',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: "2%",
    border: "1px solid #ddd",
    overflow: "auto",
    "@media only screen and (max-width : 999px)": {
      display: 'none',
    },
  },
}));

const IMAGES =
  [
  {
    src: './Image/Taupo/1.jpg',
    thumbnail: './Image/Taupo/1.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180px',
  },
  {
    src: './Image/Taupo/2.jpg',
    thumbnail: './Image/Taupo/2.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180px',
  },
  {
    src: './Image/Taupo/3.jpg',
    thumbnail: './Image/Taupo/3.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180px',
  },
  {
    src: './Image/Taupo/4.jpg',
    thumbnail: './Image/Taupo/4.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180px',
  },
  {
    src: './Image/Thames/9.jpg',
    thumbnail: './Image/Thames/9.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180px',
  },
  
]

export default function TaupoDojo() {
  const classes = useStyles();
  const Mailto = ({ email, subject, body, children }) => {
    return (
      <Button startIcon={<EmailIcon />} color="primary" variant="contained" href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`}>Email Inquiry</Button>
    );
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.root}>
      <Box display="flex">
        <div className={classes.boxContainer}>
        <div style={{maxWidth: '85%'}}>
        <Typography className={classes.textTitle}>Taupo Dojo Information - Dai Sempai Julie Hulena</Typography>
        <Typography variant="body2" color="textSecondary" component="p" style={{ paddingBottom: '2rem' }}>
        2nd Degree Black Belt (Nidan) - Taupo Dojo Instructor
        </Typography>
        <img style={{ float: 'left', paddingRight: '25px', maxWidth: '320px' }} alt="julie" src="./Image/Taupo/taupoInstructor.jpg"></img>
        <div style={{ display: "inline" }}>
          <Typography className={classes.text}>My Martial Arts journey started on January 15th, 2009. I still vividly remember my first class with Hanshi Lance and Kyoshi Ava in the Thames Memorial Hall. Lots of butterflies - then I was hooked. 2 years later, my partner Logan and I moved to Taupo to improve our career prospects. After a few months of training with Sempai Dan, I took over the Taupo Dojo, and have been instructing here ever since.
          <br/><br/>Although I had only been training as a student for 2 years, my passion for Martial Arts and my 20+ years of teaching experience helped me grow the dojo into what it is today - a thriving, learner centred dojo that helps people from all walks of life to reach their true potential.
          <br/><br/>We train Fridays of every school term, and always look forward to meeting new and interested people. 
          </Typography>
          
        </div>
      </div>

          <div style={{ maxWidth: '85%', marginBottom: '2rem'  }}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Card variant="outlined" style={{ marginTop: '2rem' }}>
              <CardContent>
              <Typography>
              <b>Fridays Classes: </b><br/>Primary age starts at 4:30pm. Secondary and Adults start at 5:30pm<br/>
              </Typography>
              <Typography><b>Location</b> <br/>Hilltop School Hall, 88 Rokino Road, Hilltop, Taupo 3330</Typography>
              <Typography><b>Facebook</b><br/><a href='https://www.facebook.com/Kiaido-Ryu-Martial-ArtsTaupo-14760174960300741/'>https://www.facebook.com/Kiaido-Ryu-Martial-ArtsTaupo-14760174960300741/</a></Typography>

              <div style={{display: 'flex', alignItems: 'center', justifyContent:'center', marginTop: '24px'}}><Mailto email="krmataupo@gmail.com" subject="Taupo Dojo Inquiry" body="Replace with your inquiry!"></Mailto></div> 
              </CardContent>
              </Card>
            </Box>
          </div>

          
        </div>

        <Box className={classes.imageBox}>
          <Gallery backdropClosesModal={true} images={IMAGES} enableImageSelection={false} margin={12} />
        </Box>

      </Box>
    </div>
  );

}