import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CardContent, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import './styles.css';
import Button from '@material-ui/core/Button';
import EmailIcon from '@material-ui/icons/Email';
import Gallery from 'react-grid-gallery';


const useStyles = makeStyles((theme) => ({
  root: {
    background: '#F1FAEE',
  },
  textTitle: {
    fontSize: '2rem',
    fontFamily: 'Helvetica Now Text',
    fontWeight: '900',
    color: '#ff3822'
  },
  boxContainer: {
    paddingTop: '2rem',
    marginLeft: '15%',
    paddingBottom: '2rem',
    width: '50%',
    "@media only screen and (max-width : 999px)": {
      width: '85%',
    },
  },
  textBody: {
    fontFamily: 'Helvetica Now Text',
    fontWeight: 'normal',
    marginTop: '1rem',
    fontSize: '1rem',
  },
  text: {
    fontFamily: 'Helvetica Now Text',
    fontWeight: 'normal',
    "@media only screen and (max-width : 660px)": {
      clear: 'both'
    },
  },
  boxBorder: {
    border: "3px solid black",
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingBottom: '1.5rem',
    marginTop: '2rem',
  },
  verticalHeadingText: {
    writingMode: 'vertical-rl',
    textOrientation: 'upright',
    fontSize: '2rem',
    fontFamily: 'Helvetica Now Text',
    fontWeight: '900',
    marginLeft: '1rem',
    color: '#ff3822',
  },
  imageBox: {
    display: "block",
    minHeight: "1px",
    paddingTop: "2%",
    width: '400px',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: "2%",
    border: "1px solid #ddd",
    overflow: "auto",
    "@media only screen and (max-width : 999px)": {
      display: 'none',
    },
  },
}));

const IMAGES =
  [
  {
    src: './Image/Thames/4.jpg',
    thumbnail: './Image/Thames/4.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180px',
  },
  {
    src: './Image/Thames/5.jpg',
    thumbnail: './Image/Thames/5.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180px',
  },
  {
    src: './Image/Thames/7.jpg',
    thumbnail: './Image/Thames/7.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180px',
  },
  {
    src: './Image/Thames/8.jpg',
    thumbnail: './Image/Thames/8.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180px',
  },
  {
    src: './Image/Thames/9.jpg',
    thumbnail: './Image/Thames/9.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180px',
  },
  
]

export default function BayOfIslandsDojo() {
  const classes = useStyles();
  const Mailto = ({ email, subject, body, children }) => {
    return (
      <Button startIcon={<EmailIcon />} color="primary" variant="contained" href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`}>Email Inquiry</Button>
    );
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.root}>
      <Box display="flex">
        <div className={classes.boxContainer}>
          <div style={{ maxWidth: '85%' }}>
            <Typography className={classes.textTitle}>Bay of Islands Dojo Information - Sensei Warren Gabb</Typography>
            <Typography variant="body2" color="textSecondary" component="p" style={{ paddingBottom: '2rem' }}>
              3rd Degree Black Belt (Sandan) - Bay of Islands Dojo Instructor
            </Typography>
            <img style={{ float: 'left', paddingRight: '25px' }} alt="Warren" src="./Image/kawakawaInstructor.jpg"></img>
            <div style={{ display: "inline" }}>
              <Typography className={classes.text}>Warren operates the Bay of Islands Dojo, Northland and has been training in KRMA for 18 years. He graded to 3rd Degree Black Belt in December 1999.


                <br /><br />For the last decade Warren's been involved in Full Contact Karate Knockdown tournaments and has fought against some of the countries top fighters. Warren has been the NZ and Australian IKO1 Super Heavyweight Champion.


                <br /><br />In recent years he has competed in the USA, Japan, Russia and Australia. In his own words "This is a true test of technique, focus, power and spirit".


                <br /><br />Warren's martial arts and life philosophy is to always have an open mind and show humility.</Typography>
            </div>
          </div>

          <div style={{ maxWidth: '85%', marginBottom: '2rem' }}>

            <Box display="flex" flexDirection="column" alignItems="center">
              <Card variant="outlined" style={{ marginTop: '2rem' }}>
                <CardContent>
                  <Typography><b>Juniors and Seniors</b><br />
                    Tuesday & Thursday - 6.30pm - 8.00pm</Typography>
                  <Typography><b>Contact</b><br />  Warren Gabb, 0211831345</Typography>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '24px' }}>
                    <Mailto email="warren@martialarts.co.nz" subject="Bay of Islands Dojo Inquiry" body="Replace with your inquiry!">warren@martialarts.co.nz</Mailto>
                  </div>
                </CardContent>
              </Card>
            </Box>
          </div>


        </div>

        <Box className={classes.imageBox}>
          <Gallery backdropClosesModal={true} images={IMAGES} enableImageSelection={false} margin={12} />
        </Box>

      </Box>
    </div>
  );

}