import React, { useEffect, useState, useRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';


import './styles.css';
import { useLocation } from 'react-router';
import { Box, Grid } from '@material-ui/core';

const drawerWidth = 360;

const useStyles = makeStyles((theme) => ({

  drawer: {
    width: drawerWidth,
    flexShrink: 0, 

  },
  BackdropProps: {
    background: 'transparent'
  },
  drawerHeaderText: {
    fontFamily: 'Helvetica Now Text',
    fontWeight: '600',
    fontStyle: 'normal'
  },
  drawerBodyText: {
    fontFamily: 'Helvetica Now Text',
    fontWeight: '300',
    fontStyle: 'normal',
    fontSize: '0.8rem',
  },
  drawerPaper: {
    width: drawerWidth,
    overflow: 'hidden',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    // boxShadow: '0px 4px 9px -3px rgba(0,0,0,0.75)',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  hideOnSmall: {
    /* Custom, iPhone Retina */
    "@media only screen and (max-width : 700px)": {
      display: "none",
    },
  }
}));



export default function ScrollingNews({ open, setOpen }) {
  var newsTextArray = [
  <ListItem item>
    <Typography>
    <Typography style={{textAlign: 'center', fontSize: '24px'}}><b>2024</b></Typography><br/>
    <Typography style={{textAlign: 'center'}}><b>May Saturday 25th <br/>All ranks Syllabus Training Day</b></Typography><br/>
    <Box display="flex" justifyContent="center">
    <Typography style={{marginLeft: '3em'}}>
    <b>Time:</b> 10am - 2.30pm 
    <br/><b>Venue:</b> Jack Mclean Community Center, Rolleston Street, Thames<br/>
    <b>Fee:</b> $35, register with your Instructor<br/><br/>
    Bring your weapons, protective gear & water bottles.
    All students may attend.<br/>
      </Typography>
      </Box>
      <span style={{ display: 'block', margin: '29px auto 26px', borderBottom: '1px solid #cecece', width: '35%' }}/>
          </Typography>
  </ListItem>,
  <ListItem item>
    <Typography>
    <Typography style={{textAlign: 'center'}}><b>July Friday 5th<br/>Black Belt Grading</b></Typography><br/>
    <Box display="flex" justifyContent="center">
    <Typography style={{marginLeft: '3em'}}>
    <b>Time:</b> Hall opens 6pm. Grading starts 7pm <br/>
    <b>Venue:</b> Jack Mclean Community Center, Rolleston Street, Thames
      </Typography>
      </Box>
      <span style={{ display: 'block', margin: '29px auto 26px', borderBottom: '1px solid #cecece', width: '35%' }}/>
          </Typography>
  </ListItem>,
  <ListItem item>
    <Typography>
    <Typography style={{textAlign: 'center'}}><b>Saturday 6th July <br/>All Ranks Grading</b></Typography><br/>
    <Box display="flex" justifyContent="center">
    <Typography style={{marginLeft: '3em'}}>
    <b>Time:</b> Hall opens 9am. Grading starts 10am <br/>
    <b>Venue:</b> Jack Mclean Community Center, Rolleston Street, Thames
      </Typography>
      </Box>
      <span style={{ display: 'block', margin: '29px auto 26px', borderBottom: '1px solid #cecece', width: '35%' }}/>
    </Typography>
  </ListItem>,
  <ListItem item>
    <Typography>
    <Typography style={{textAlign: 'center'}}><b>Saturday 6th July <br/>Kiaido Ryu Ball & Dinner</b></Typography><br/>
    <Box display="flex" justifyContent="center">
    <Typography style={{marginLeft: '3em'}}>
    <b>Venue:</b> Thames<br/>
      <b>Time:</b> <b>-  Information to come -</b><br/>
      <b>Fee:</b> Check with your Instructor closer to the date
    </Typography>
      </Box>
      <span style={{ display: 'block', margin: '29px auto 26px', borderBottom: '1px solid #cecece', width: '35%' }}/>
    </Typography>
  </ListItem>,
  <ListItem item>
    <Typography>
      <Typography style={{textAlign: 'center'}}><b>August Saturday 24th<br/>Seminar Training Day <br/>(still to be set) - Check with your Instructor</b></Typography><br/>
      <Box display="flex" justifyContent="center">
      <Typography style={{marginLeft: '3em'}}>
        <b>Time:</b> 10am - 2.30pm<br/>
        <b>Venue:</b> Jack Mclean Community Center, Rolleston Street, Thames<br/>
        <b>Fee:</b> $35 register with your Instructor
      </Typography>
        </Box>
        <span style={{ display: 'block', margin: '29px auto 26px', borderBottom: '1px solid #cecece', width: '35%' }}/>
    </Typography>
  </ListItem>,
  <ListItem item>
    <Typography>
      <Typography style={{textAlign: 'center'}}><b>Nov Saturday 9th<br/>All ranks Syllabus Training Day<br/></b></Typography><br/>
      <Box display="flex" justifyContent="center">
      <Typography style={{marginLeft: '3em'}}>
        <b>Time:</b> 10am - 2.30pm<br/>
        <b>Venue:</b> Jack Mclean Community Center, Rolleston Street, Thames<br/>
        <b>Fee:</b> $35 register with your Instructor<br/><br/>
        Bring your weapons, protective gear & water bottles.
        All students may attend.<br/>
      </Typography>
        </Box>
        <span style={{ display: 'block', margin: '29px auto 26px', borderBottom: '1px solid #cecece', width: '35%' }}/>
    </Typography>
  </ListItem>,
  <ListItem item>
    <Typography>
      <Typography style={{textAlign: 'center'}}><b>Dec Friday 6th<br/>Black Belt Grading<br/></b></Typography><br/>
      <Box display="flex" justifyContent="center">
      <Typography style={{marginLeft: '3em'}}>
        <b>Time:</b> Hall opens 6pm. Grading starts 7pm<br/>
        <b>Venue:</b> Jack Mclean Community Center, Rolleston Street, Thames<br/>
      </Typography>
        </Box>
        <span style={{ display: 'block', margin: '29px auto 26px', borderBottom: '1px solid #cecece', width: '35%' }}/>
    </Typography>
  </ListItem>,
  <ListItem item>
    <Typography>
      <Typography style={{textAlign: 'center'}}><b>Saturday 7th July<br/>All Ranks Grading<br/></b></Typography><br/>
      <Box display="flex" justifyContent="center">
      <Typography style={{marginLeft: '3em'}}>
        <b>Time:</b> Hall opens 9am. Grading starts 10am<br/>
        <b>Venue:</b> Jack Mclean Community Center, Rolleston Street, Thames<br/>
      </Typography>
        </Box>
        <span style={{ display: 'block', margin: '29px auto 26px', borderBottom: '1px solid #cecece', width: '35%' }}/>
    </Typography>
  </ListItem>,
  ]


  const itemsRef = useRef([]);

  const theme = useTheme();
  const classes = useStyles();
  const location = useLocation();
  const [lastScrollAmount, setLastScrollAmount] = useState(0);
  const [scrollCount, setScrollCount] = useState(0);
  const [listDisplay, setListDisplay] = useState(newsTextArray);
  const [pauseScroll, setPauseScroll] = useState(false);
  const [hardPauseScroll, setHardPauseScroll] = useState(false);

  const [scrollOffset, setScrollOffset] = useState(0);
  var scrollObject = document.getElementById("scroll");

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!hardPauseScroll) {
      open ? setPauseScroll(false) : setPauseScroll(true)
    }
  }, [open, hardPauseScroll])

  function setOpenTimer(interval){
    setOpen(true);
    clearInterval(interval);
  }

  useEffect(() => {
    if (location.pathname === '/') {
      //setOpen(true);
      // const interval = setInterval(() => {
      //   setOpenTimer(interval);
      // }, 2500);
      // return () => clearInterval(interval);
     }
  }, [])


  document.onmousedown = function(e){
    if(e.target.id === 'scroll'){
      setPauseScroll(true);
    }
  }

 

  document.onmouseup = function(e){
    if(e.target.id === 'scroll' && !hardPauseScroll){
      const newOffset = scrollObject.scrollTop;
      setScrollOffset(newOffset);
      setPauseScroll(false);
    }
  }

  function useInterval(callback, delay) {
    const savedCallback = useRef();
  
    // Remember the latest function.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  const pauseScrollClick = () => {
    setPauseScroll(!hardPauseScroll);
    setHardPauseScroll(!hardPauseScroll);
  }
  
  //TODO: Think of something to do when manually scrolled to bottom, currently stops the auto scroll. Which may be alright
  const scrollEvent = (e) => {
    if (!pauseScroll)
      if (scrollObject.scrollTop > (itemsRef.current[0].clientHeight)+scrollOffset) {
        setLastScrollAmount(scrollObject.scrollTop);
        let topElement = listDisplay[0];
        let tmpArr = [...listDisplay];
        scrollObject.scrollTop -= itemsRef.current[0].clientHeight
        tmpArr.push(topElement);
        tmpArr.shift();
        setListDisplay(tmpArr)

        setScrollCount(scrollCount + 1);
      }


  }


  useInterval(() => {
    const newsSelect = document.getElementById("scroll");
    if(!pauseScroll){
      newsSelect.scrollTop += 3
    }
  }, 100);

  useEffect(() => {
    if(!scrollObject){
      scrollObject = document.getElementById("scroll");
    }
  }, []);

  useEffect(() => {
    if(scrollObject != null)
    scrollObject.scrollTop = 0;
  }, [scrollObject]);

  return (
    <div id="news">
      <Drawer
        className={classes.drawer}
        variant="temporary"
        anchor="right"
        open={open}
        //hideBackdrop
        onClose={handleDrawerClose}

        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
          //Below makes backdrops transparent but is weird when clicking to close
          BackdropProps:{
            classes:{
              root: classes.BackdropProps
            }
          },
        }}
        styles={{BackdropProps: {
          background: 'transparent'
        }}}
        onScroll={scrollEvent}
        classes={{
          paper: classes.drawerPaper,
          
        }}
      >
        <Box boxShadow={4} display="flex" className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            <Typography className={classes.drawerHeaderText} style={{ fontSize: '1.5rem' }}>UPCOMING EVENTS</Typography>
          </IconButton>
          <IconButton onClick={pauseScrollClick}>
          {hardPauseScroll ? <PlayArrowIcon/>  : <PauseIcon/> }
          </IconButton>
          

        </Box>
        <List id="scroll" className={classes.accent} style={{overflow: 'scroll'}}>
        {listDisplay.map((item, i) =>
        <div key={i} ref={el => itemsRef.current[i] = el}>{item}</div>

        )}
        </List>
      </Drawer>

    </div>
  );
}
