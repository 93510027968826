import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import './styles.css';
import { client } from "./Client";
import { useSnackbar } from "notistack";
import moment from 'moment';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '50%',
    marginTop: '5em',
  }

}))



export default function SendMail({ mail }) {
  const classes = useStyles();
  const [selectRanks, setSelectRanks] = useState(false)
  const [selectDojos, setSelectDojos] = useState(false)
  const [ranks, setRanks] = useState(null);
  const [dojos, setDojos] = useState(null);
  const [text, setText] = useState("");
  const [title, setTitle] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  var currentDate = moment(new Date());
  const user = JSON.parse(localStorage.getItem("user"));

  const submit = () => {
    postMessage(ranks, dojos);
  }


  const handleChangeRanks = (index, checked) => {

    if (selectRanks && !checked) setSelectRanks(false)

    const itemRef = [...ranks];

    itemRef[index].selected = checked

    setRanks([...itemRef]);
  }

  const handleChangeDojos = (index, checked) => {

    if (selectDojos && !checked) setSelectDojos(false)

    const itemRef = [...dojos];

    itemRef[index].selected = checked

    setDojos([...itemRef]);
  }

  const handleChangeAllRanks = (checked) => {
    const itemRef = [...ranks];

    itemRef.forEach((_item, i) => {
      itemRef[i].selected = checked

    })

    setSelectRanks(checked)
    

    setRanks([...itemRef]);
  }

  const handleChangeAllDojos = (checked) => {
    const itemRef = [...dojos];

    itemRef.forEach((_item, i) => {
      itemRef[i].selected = checked

    })

    setSelectDojos(checked)

    setDojos([...itemRef]);
  }

  const fetchBelts = async (belt) => {
    const response = await client.get(`belts/`).catch((err) =>
      console.error("TAG", err)
    );

    if (response) {
      const sorted = response.sort((a, b) => (a.id < b.id) ? -1 : 1)
      var tmp = []
      sorted.map((item) =>
        tmp.push({ data: item, selected: false })
      )
      setRanks(tmp)
      return response;
    }
    else {


    }

  };


  const postMessage = async (ranks, dojos) => {
    const selectedRanksOld = ranks.filter(obj => {
      return obj.selected === true;
    })
    const selectedRanks = selectedRanksOld.map((obj) => obj.data.name)
    const selectedDojos = dojos.filter(obj => {
      return obj.selected === true;
    }).map((obj) => obj.data)
    const message = {message: {text: text, title: title, createdAt: currentDate.format(), author: user.user.firstname+" "+user.user.lastname, rank: selectedRanks, dojo: selectedDojos }}
    
    const response = await client.post(`messages/create`, message).catch((err) =>
        console.error(err)
    );
    if (response) {
      enqueueSnackbar("Message sent", {
        variant: "default",
    });
    setText("");
    setTitle("");
    handleChangeAllRanks(false);
    handleChangeAllDojos(false);
    mail();
    }
    else {
        enqueueSnackbar("Login failed", {
            variant: "default",
        });
    }

};

  useEffect(() => {
    fetchDojos();
    fetchBelts();
    
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const fetchDojos = async () => {
    const response = await client.get(`users/dojos`).catch((err) =>
      console.error(err)
    );
    if (response) {
      var tmp = []
      response.map((item) =>
        tmp.push({ data: item.school, selected: false })
      )
      setDojos(tmp);
    }
    else {
      enqueueSnackbar("Dojo retrieval failed", {
        variant: "default",
      });
    }

  };




  return (
    <Box display="flex" flexDirection='column'>
      <Box display="flex" flexDirection='row'>
        <FormControl component="fieldset" className={classes.formControl} style={{marginRight: '2em', marginBottom: '4em'}}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <FormLabel component="legend">Select Ranks</FormLabel>
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={selectRanks} onChange={(_e, checked) => handleChangeAllRanks(checked)} name="All" />}
                  label="All"
                />
                {

                  ranks !== null ? ranks.map((item, i) =>
                    [
                      <FormControlLabel
                        control={<Checkbox key={item.data.id} checked={item.selected} onChange={(_e, checked) => handleChangeRanks(i, checked)} />}
                        label={item.data.name}
                      />
                    ]
                  ) : []
                }

              </FormGroup>
            </AccordionDetails>
          </Accordion>
        </FormControl>

        <FormControl component="fieldset" className={classes.formControl}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <FormLabel component="legend">Select Dojos</FormLabel>
            </AccordionSummary>
            <AccordionDetails>
            <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={selectDojos} onChange={(_e, checked) => handleChangeAllDojos(checked)} name="All" />}
              label="All"
            />
            {

              dojos !== null ? dojos.map((item, i) =>
                [
                  <FormControlLabel
                    control={<Checkbox key={item.data} checked={item.selected} onChange={(_e, checked) => handleChangeDojos(i, checked)} />}
                    label={item.data}
                  />
                ]
              ) : []
            }

          </FormGroup>
            </AccordionDetails>
          </Accordion>
        </FormControl>



      </Box>
      <TextField onChange={e => setTitle(e.target.value)} multiline label="Enter title here..." variant="outlined" style={{marginBottom: '48px', backgroundColor: 'white' }}
        inputProps={{
          style: {
            minHeight: '24px',
            margin: '0px 0px',
          },
        }}></TextField>
      <TextField onChange={e => setText(e.target.value)}  multiline label="Enter message here..." variant="outlined" style={{minHeight: '420px', backgroundColor: 'white'}}
        inputProps={{
          style: {
            minHeight: '420px',
            padding: '0 14px',
          },
        }}></TextField>
      <Box display="flex" alignItems="center" flexDirection='column'>
        <Button className={classes.button} color="primary" variant="contained" onClick={() => submit()}>Submit</Button>
        <Button className={classes.button} color="primary" variant="contained" onClick={() => mail()}>Return to Inbox</Button>
      </Box>
    </Box>
  )
}