import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Box, Paper, Typography } from '@material-ui/core';
import './styles.css';
import { useHistory } from 'react-router';
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const useStyles = makeStyles((theme) => ({
    root: {
        background: '#F1FAEE',
        minWidth: '100%'
    },
    headingText: {
        fontFamily: 'Helvetica Now Text',
        fontWeight: '900',
        fontSize: '2rem',
        textAlign: 'center',
        paddingTop: '2rem',
        paddingBottom: '2rem',
        backgroundImage: `url(/Image/headrepeat.jpg)`,
        borderBottom: '2px solid #ddd',
        backgroundRepeat: 'repeat-x',

    },
    textBox: {
        fontFamily: 'Helvetica Now Text',
        marginTop: '16em',
        left: '4rem',
        boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.75)',
        padding: '1rem',
        border: '2px solid #ddd',
        background: 'white',
        maxWidth: '25%',
    "@media only screen and (max-height : 480px)": {
        maxWidth: '40%',
        marginTop: '6em',

      },
  
      /* Extra Small Devices, Phones */
      "@media only screen and (min-height : 480px)": {
        maxWidth: '25%',
        marginTop: '8em',

      },
  
      /* Small Devices, Tablets */
      "@media only screen and (min-height : 768px)": {
        maxWidth: '25%',
        marginTop: '10em',

      },
  
      /* Medium Devices, Desktops */
      "@media only screen and (min-height : 992px)": {
        maxWidth: '25%',
        marginTop: '12em',

      },
  
      /* Large Devices, Wide Screens */
      "@media only screen and (min-height : 1200px)": {
        maxWidth: '25%',
        marginTop: '16em',

      },

    },
    textBoxSmall: {
        marginTop: '2rem',
        marginBottom: '2rem',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '1rem',
        background: 'white',
        maxWidth: '85vw',
        border: '2px solid #ddd',
    },
    text: {
        fontFamily: 'Helvetica Now Text',
    }, 
    link: {
        color: 'black',
        borderBottom: '4px solid black',
        paddingBottom: '0px',
        "&:hover": {
            color: 'green',
            borderBottom: '4px solid green',
            cursor: 'pointer',
        },
    },
    hoverGreen: {
        "&:hover": {
            color: 'green',
            cursor: 'pointer',
        },
    },
    bigDisplay: {
        "@media only screen and (max-width : 999px)": {
            display: 'none',
        },
    },
    smallDisplay: {
        background: '#F1FAEE',
        display: 'none',
        "@media only screen and (max-width : 999px)": {
            display: 'block',
        },

    },
    smallButton: {
        //margin: "0 auto 2rem auto", 
        marginBottom: "2em",
        width: "40vw",

    }
}));


export default function ContactNew() {
    //IMAGE DIMENSONS  1928 x 2432
    //Line 1493,795
    const classes = useStyles();
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorE2, setAnchorE2] = React.useState(null);


    const locationOnClick = (location) => {
        switch (location) {
            case 'Bethlehem':
                setAnchorE2(null);
                window.scrollTo(0, 0);
                history.push('/bethlehem');
                break;
            case 'Katikati':
                setAnchorE2(null);
                history.push('/katikati');
                break;
            case 'Thames':
                history.push('/thames');
                break;
            case 'Taupo':
                history.push('/taupo');
                break;
            case 'Putaruru':
                history.push('/putaruru');
                break;
            case 'Bay of Islands':
                history.push('/bayofislands');
                break;
            case 'Papamoa':
                setAnchorEl(null);
                history.push('/papamoa');
                break;
            case 'Bombay':
                setAnchorEl(null);
                history.push('/auckland-bombay');
                break;
            case 'Remuera':
                setAnchorEl(null);
                history.push('/auckland-remuera');
                break;
            case 'Hamilton':
                setAnchorEl(null);
                history.push('/hamilton');
                break;
            case 'Mount':
                setAnchorE2(null);
                history.push('/mount');
                break;
            default:
                return;
        }

    }

    const handleClose = () => {
        setAnchorEl(null);
        setAnchorE2(null);
    };

    const handleClickE1 = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickE2 = (event) => {
        setAnchorE2(event.currentTarget);
    };

    const locations = [
        {
          name: "Bethlehem",
          location: { 
            lat: -37.69404198844647,
            lng: 176.10828710187783
          },
        },
        {
            name: "Katikati",
            location: {
                lat: -37.54987153735846,
                lng: 175.92156267242873,
            },
        },
        {
            name: "Thames",
            location: {
                lat: -37.12762998336725, 
                lng: 175.5585825670075,
            },
        },
        {
            name: "Taupo",
            location: {
                lat:-38.67662908469345, 
                lng: 176.12076996813187,
            },
        },
        {
            name: "Putaruru",
            location: {
                lat: -38.04454410129535, 
                lng: 175.7511487545209,
            },
        },
        {
            name: "Bay of Islands",
            location: {
                lat: -35.371394604507785, 
                lng: 174.07844104514788,
            },
        },
        {
            name: "Papamoa",
            location: {
                lat: -37.73923615975216, 
                lng: 176.33331445513127,
            },
        },
        {
            name: "Bombay",
            location: {
                lat: -37.18272301512606, 
                lng: 175.00168130166196,
            },
        },
        {
            name: "Remuera",
            location: {
                lat: -36.87794872756614, 
                lng: 174.80228331520308,
            },
        },
        {
            name: "Hamilton",
            location: {
                lat: -37.770201810022165, 
                lng: 175.26486769984538,
            },
        },
        {
            name: "Mount",
            location: {
                lat: -37.674669485246625, 
                lng: 176.23190878902116,
            },
        },
      ];

    const mapStyles = {
        height: "100%",
        width: "100%"
    };

    const defaultCenter = {
        lat: 40.9006, lng: 174.8860
    }
    
    const defaultProps = {
        center: {
            lat: -37.80021399663946,
            lng: 175.75918800134602
        },
         
        zoom: 9
    };
    return (
        //CONSIDER USING WHITE TEXT WITH BLACK OUTLINE FOR TITLE ON RED 
        <div className={classes.root} style={{minWidth: "100%"}}>
                <div className={classes.bigDisplay} position="absolute" style={{ height: '90vh', width: '100%', left: '0', top: '0', }}>
                    <LoadScript googleMapsApiKey="AIzaSyADCWs1Hq8ZikgaB-PNKzNOPsdqch9ew2U">
                        <GoogleMap
                            mapContainerStyle={mapStyles}
                            center={defaultProps.center}
                            zoom={defaultProps.zoom} 
                        >
                            {/* Child components, such as markers, info windows, etc. */}

                            {locations.map((location, count) =>
                                <Marker position={location.location} label={location.name} onClick={() => locationOnClick(location.name)}></Marker>
                            )}
                        <Box className={classes.textBox} position="relative" alignItems="baseline">
                            <Typography className={classes.text}>Kiaido Ryu is a national martial arts institution with dojos located all throughout New Zealand. Please feel free to select a dojo near you for some detailed information about our instructors and their dojos. You are welcome to come along to a class for no cost, come see if Kiaido Ryu is a good fit for you!  </Typography>
                        </Box>
                        </GoogleMap>
                    </LoadScript>
                </div>


            <Box className={classes.smallDisplay}  display="flex">
            <Paper className={classes.textBoxSmall} >
                        <Typography >Kiaido Ryu is a national martial arts institution with dojos located all throughout New Zealand. Please feel free to select a dojo near you for some detailed information about our instructors and their dojos. You are welcome to come along to a class for no cost, come see if Kiaido Ryu is a good fit for you!  </Typography>
                    </Paper>
                <Box display="flex" flexDirection="column" elevation={9} alignItems="stretch" flex={1}>
                    
                    <Box display="flex" flexDirection="row" justifyContent="center" width="100%" style={{marginLeft: '1em'}}>
                        <Box display="flex" flexDirection="column" style={{ marginRight: "2em" }}>
                            <Button className={classes.smallButton} display="flex" variant="contained" color="primary" onClick={() => locationOnClick('Mount')}>Mount Maunganui</Button>
                            <Button className={classes.smallButton} display="flex" variant="contained" color="primary" onClick={() => locationOnClick('Bethlehem')}>Bethlehem</Button>
                            <Button className={classes.smallButton} variant="contained" color="primary" onClick={() => locationOnClick('Remuera')}>Remuera</Button>
                            <Button className={classes.smallButton} variant="contained" color="primary" onClick={() => locationOnClick('Bombay')}>Bombay</Button>
                            <Button className={classes.smallButton} variant="contained" color="primary" onClick={() => locationOnClick('Katikati')}>Katikati</Button>
                        </Box>
                        <Box display="flex" flexDirection="column">
                            <Button className={classes.smallButton} variant="contained" color="primary" onClick={() => locationOnClick('Hamilton')}>Hamilton</Button>
                            <Button className={classes.smallButton} variant="contained" color="primary" onClick={() => locationOnClick('Taupo')}>Taupo</Button>
                            <Button className={classes.smallButton} variant="contained" color="primary" onClick={() => locationOnClick('Putaruru')}>Putaruru</Button>
                            <Button className={classes.smallButton} variant="contained" color="primary" onClick={() => locationOnClick('Bay of Islands')}>Bay of Islands</Button>
                            <Button className={classes.smallButton} variant="contained" color="primary" onClick={() => locationOnClick('Thames')}>Thames</Button>
                            <Button className={classes.smallButton} variant="contained" color="primary" onClick={() => locationOnClick('Papamoa')}>Papamoa</Button>

                        </Box>
                    </Box>





                </Box>
            </Box>
        </div>
    );

}
