import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CardContent, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import './styles.css';
import Button from '@material-ui/core/Button';
import EmailIcon from '@material-ui/icons/Email';
import Gallery from 'react-grid-gallery';


const useStyles = makeStyles((theme) => ({
  root: {
    background: '#F1FAEE',
    height: '100%',
  },
  textTitle: {
    fontSize: '2rem',
    fontFamily: 'Helvetica Now Text',
    fontWeight: '900',
    color: '#ff3822'
  },
  boxContainer: {
    paddingTop: '2rem',
    marginLeft: '15%',
    paddingBottom: '2rem',
    width: '50%',
    "@media only screen and (max-width : 999px)": {
      width: '85%',
    },
  },
  textBody: {
    fontFamily: 'Helvetica Now Text',
    fontWeight: 'normal',
    marginTop: '1rem',
    fontSize: '1rem',
  },
  text: {
    fontFamily: 'Helvetica Now Text',
    fontWeight: 'normal',
    "@media only screen and (max-width : 660px)": {
      clear: 'both'
    },
  },
  boxBorder: {
    border: "3px solid black",
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingBottom: '1.5rem',
    marginTop: '2rem',
  },
  verticalHeadingText: {
    writingMode: 'vertical-rl',
    textOrientation: 'upright',
    fontSize: '2rem',
    fontFamily: 'Helvetica Now Text',
    fontWeight: '900',
    marginLeft: '1rem',
    color: '#ff3822',
  },
  imageBox: {
    display: "block",
    minHeight: "1px",
    paddingTop: "2%",
    width: '360px',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: "2%",
    border: "1px solid #ddd",
    overflow: "auto",
    "@media only screen and (max-width : 999px)": {
      display: 'none',
    },
  },
}));

const IMAGES =
  [{
    src: './Image/Katikati/1.jpg',
    thumbnail: './Image/Katikati/1.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180',
  },
  {
    src: './Image/Katikati/2.JPG',
    thumbnail: './Image/Katikati/2.JPG',
    thumbnailWidth: '320',
    thumbnailHeight: '180',
  },
  {
    src: './Image/Katikati/3.jpg',
    thumbnail: './Image/Katikati/3.jpg',
    thumbnailWidth: '320',
    thumbnailHeight: '180',
  },
  {
    src: './Image/Katikati/4.JPG',
    thumbnail: './Image/Katikati/4.JPG',
    thumbnailWidth: '320',
    thumbnailHeight: '180',
  },
  {
    src: './Image/Katikati/5.JPG',
    thumbnail: './Image/Katikati/5.JPG',
    thumbnailWidth: '320',
    thumbnailHeight: '180',
  },
  {
    src: './Image/Katikati/6.jpg',
    thumbnail: './Image/Katikati/6.jpg',
    thumbnailWidth: '320',
    thumbnailHeight: '180',
  },
  {
    src: './Image/Katikati/7.JPG',
    thumbnail: './Image/Katikati/7.JPG',
    thumbnailWidth: '320',
    thumbnailHeight: '180',
  },
  {
    src: './Image/Katikati/8.JPG',
    thumbnail: './Image/Katikati/8.JPG',
    thumbnailWidth: '320px',
    thumbnailHeight: '180',
  },
  {
    src: './Image/Katikati/9.JPG',
    thumbnail: './Image/Katikati/9.JPG',
    thumbnailWidth: '320px',
    thumbnailHeight: '180',
  },
  {
    src: './Image/Katikati/10.jpg',
    thumbnail: './Image/Katikati/10.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180',
  },
  {
    src: './Image/Katikati/11.jpg',
    thumbnail: './Image/Katikati/11.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180',
  },
  {
    src: './Image/Katikati/12.jpg',
    thumbnail: './Image/Katikati/12.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180',
  },
  ]


export default function KatikatiDojo() {
  const classes = useStyles();
  const Mailto = ({ email, subject, body, children }) => {
    return (
      <Button startIcon={<EmailIcon />} color="primary" variant="contained" href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`}>Email Inquiry</Button>
    );
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.root}>
      <Box display="flex">
        <div className={classes.boxContainer}>

        <div style={{ maxWidth: '85%' }}>
        <Typography className={classes.textTitle}>Katikati Dojo Information - Sensei Christine Townsend
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p" style={{ paddingBottom: '2rem' }}>
          3rd Degree Black Belt (Sandan) - Katikati Dojo Instructor
        </Typography>
        <img style={{ float: 'left', paddingRight: '25px', maxWidth: '320px' }} alt="Warren" src="./Image/Katikati/katikatiInstructor.JPG"></img>
        <div style={{ display: "inline" }}>
          <Typography className={classes.text}>September 2006 I moved to Katikati with my husband and 3 boys.  I signed my eldest son Ben (age 7 at the time) up to the juniors class and within a month I was on the floor training alongside him.  This is where my martial arts journey began and I have never looked back.  It gave us the opportunity to meet people, get fit and to learn valuable life skills in a friendly, safe, family oriented environment.  My two other sons Mitchell and Luke were very eager to join also so when they reached the age of 7 they were allowed onto the dojo floor.
          <br/><br/>I achieved my 1st Degree Black Belt in 2010, my 2nd Degree Black Belt in 2012 and my 3rd Degree Black Belt in 2017 - the same year I took over the Katikati Dojo as head instructor.
          <br/><br/>Ben and Mitchell achieved their Black Belt together in 2015.  A few times along the way they needed a little help and encouragement to never give up which makes me even more proud as a parent of their dedication and perseverance to meet their goal of black belt.  My youngest son Luke achieved his black belt in 2019.
          <br/><br/>The name Kiaido Ryu says it all Ki (energy), ai (balance and harmony), do (the path/the way) and Ryu (family).  Through Kiaido Ryu Martial Arts I have learnt the true value and importance of what it stands for and our mission statement “making a positive difference in people’s lives” is something I strive to achieve every day.
          </Typography>
        </div>
      </div>

          <div style={{ width: '80%', marginBottom: '2rem' }}>
            <Typography className={classes.textTitle} style={{ paddingTop: '2rem', textAlign: 'center' }}>Katikati Dojo Information</Typography>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Card variant="outlined" style={{ maxWidth: '85%', marginTop: '2rem', }}>
                <CardContent>
                  <Typography><b>Juniors</b><br />
                    Monday and Wednesday: 5.30pm - 6.30pm</Typography>
                  <Typography><b>Seniors</b><br />
                    Monday and Wednesday: 6.30pm - 8.00pm</Typography>
                  <Typography><b>Contact</b><br /> Christine Townsend,  021 1408225</Typography>
                  <Typography><b>Location</b> <br />   33 Beach Road, Katikati – Katikati College Meads Gym
                  </Typography>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '24px' }}>
                    <Mailto style={{ marginLeft: 'auto' }} email="krmakatikati@xtra.co.nz" subject="Katikati  Dojo Inquiry" body="Replace with your inquiry!">paul@martialarts.co.nz</Mailto>
                  </div>
                </CardContent>
              </Card>
            </Box>
          </div>

        </div>

        <Box className={classes.imageBox} style={{ maxHeight: '100vh' }}>
          <div style={{ width: '320px', margin: '0 auto' }}>
            <Gallery backdropClosesModal={true} images={IMAGES} enableImageSelection={false} margin={12} />
          </div>
        </Box>

      </Box>
    </div>
  );

}