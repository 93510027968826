import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useHistory, useParams, useLocation } from "react-router-dom";
import { client } from "./Client";
import { useSnackbar } from "notistack";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                Your Website
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    redText: {
        color: 'red',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    emailSentText: {
        boxShadow: "0 0 3px red",
        padding: '4px',
        textAlign: 'center',
        marginTop: '1em',
        border: 'solid 1px red',
        borderRadius: '4px'
    }
}));


export default function PasswordRecover(props) {
    const classes = useStyles();
    const history = useHistory();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailSent, setEmailSent] = useState(false);
    const [passwordSecond, setPasswordSecond] = useState("");
    const { enqueueSnackbar } = useSnackbar();
    let { hash } = useParams();
    const [updatePasswordFormat, setUpdatePasswordFormat] = useState(hash ? true : false);
    const { data } = useLocation();
    if(data){
        var { checkLoginFunc } = data;
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        postPasswordRecovery(email);
        setEmailSent(true);
    }

    const handleUpdateSubmit = (evt) => {
        evt.preventDefault();
        if(password === passwordSecond){
            postUpdatePassword(hash, password);
        }
        else{
            enqueueSnackbar("Password does not match. Please ensure both password fields are the same.", {
                variant: "default",
            });
        }

    }

    const postUpdatePassword = async (hash, password) => {
        const response = await client.post(`users/updatePassword`, { hash: `${hash}`, password: `${password}` }).catch((err) =>
            console.error(err)
        );
        if (response.error) {
            hash = null;
            setUpdatePasswordFormat(false);
            enqueueSnackbar(response.error, {
                variant: "default",
            });
            return;
        }
        else {
            enqueueSnackbar("Password succesfully updated", {
                variant: "default",
            });
            if (checkLoginFunc) {
                let location = {
                    pathname: '/signin',
                    data: { checkLoginFunc: checkLoginFunc }
                }
                history.push(location)
            }
            else {
                history.push('/signin')
            }
        }


    };

    const postPasswordRecovery = async (email) => {
        localStorage.setItem('user', "");
        localStorage.setItem('email', "");
        localStorage.setItem('password', "");
        const response = await client.post(`users/recoverPassword`, {email: `${email}`}).catch((err) =>
            console.error(err)
        );
        if (response) {
            enqueueSnackbar("Password recovery email sent", {
                variant: "default",
            });
        }
        else {
            enqueueSnackbar("Password recovery failed: "+response, {
                variant: "default",
            });
        }

    };

    return (
        <Container style={{ height: "82vh" }} component="main" maxWidth="xs">
            <CssBaseline />
            { (!updatePasswordFormat)
                ? <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Lost Password Tool
                </Typography>
                { !emailSent
                    ? <form className={classes.form} noValidate onSubmit={handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        value={email}
                        label="Email Address"
                        name="email"
                        onChange={e => setEmail(e.target.value)}
                        autoComplete="email"
                        autoFocus
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Recover Password
                    </Button>
                </form>
                : <div>
                <Typography className={classes.emailSentText}>Recovery email has been sent. Please check your email inbox and follow the link provided.</Typography>
                </div>
            }
            </div>
        : <div className={classes.paper}>
        <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
            Update Password
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleUpdateSubmit}>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="newpassword"
                value={password}
                label="Enter new password"
                name="newpassword"
                type="password"
                onChange={e => setPassword(e.target.value)}
                autoFocus
            />
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="newpasswordverify"
                label="Verify new password"
                type="password"
                id="newpasswordverify"
                value={passwordSecond}
                onChange={e => setPasswordSecond(e.target.value)}
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
            >
                Update Password
            </Button>
        </form>
    </div>
    }

        </Container>
    );
}