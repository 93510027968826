import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ExploreIcon from '@material-ui/icons/Explore';
import DomainIcon from '@material-ui/icons/Domain';
import HelpIcon from '@material-ui/icons/Help';
import HomeIcon from '@material-ui/icons/Home';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ScrollingNews from './ScrollingNews';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import './styles.css';
import { useHistory } from 'react-router';
import { Button } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';


const drawerWidth = 360;
const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  selected: {},
  appBar: {
    //background: "#ff3822",
    background: "#1D3557",
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  helveticaText: {
    fontFamily: 'Helvetica Now Text'
  },
  divider: {
    backgroundColor: '#B4BCB8',
  },
  spaced: {
    marginTop: 25,
    marginBottom: 25,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerHeaderText: {
    fontFamily: 'Helvetica Now Text',
    fontWeight: '600',
    fontStyle: 'normal'
  },
  drawerBodyText: {
    fontFamily: 'Helvetica Now Text',
    fontWeight: '300',
    fontStyle: 'normal',
    fontSize: '0.8rem',
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  userIcon: {
    marginRight: '20px',
    color: 'white',
  },
  iconBtn: {
    marginLeft: "auto",
  },
  memberBtnOff: {
    background: '#A37315',
    color: 'black',
    '&:hover': {
      background: '#F0AA1F',
    },
    '&$selected': {
      background: '#F0AA1F',
      color: 'black',
      '&:hover': {
        background: '#F0AA1F',
      },
    }
  },
}));

export default function ToolbarDrawer({ content }) {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const email = localStorage.getItem('email');

  const [active, setActive] = useState('home');
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    checkLoggedIn();

  }, [])




  const checkLoggedIn = () => {
    const localLog = localStorage.getItem('loggedIn');
    if (localLog)
      setLoggedIn(JSON.parse(localLog));
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleClick = (target) => {
    history.push(target);
  };

  const handleActive = (event, newActive) => {
    setActive(newActive);
  };

  const handleLogin = () => {
    setAnchorEl(null);
    let location = {
      pathname: '/signin',
      data: { checkLoginFunc: checkLoggedIn }
    }
    history.push(location)
  };

  const handleLogout = () => {
    setAnchorEl(null);
    localStorage.setItem('loggedIn', false);
    setLoggedIn(false);
    history.push('/');

  };

  const handleMember = () => {
    setAnchorEl(null);

    loggedIn
      ? history.push('/profile')
      : handleLogin()
  };

  const handleRollcall = () => {
    setAnchorEl(null);

    loggedIn
      ? history.push('/rollcall')
      : handleLogin()
  };

  const handleAttendance = () => {
    setAnchorEl(null);

    loggedIn
      ? history.push('/attendance')
      : handleLogin()
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClickMobile = (target) => {
    setMobileOpen(false);
    history.push(target);
  };

  const handleMemberMobile = () => {
    setMobileOpen(false);

    loggedIn
      ? history.push('/profile')
      : handleLogin()
  };

  const handleRollcallMobile = () => {
    setMobileOpen(false);

    loggedIn
      ? history.push('/rollcall')
      : handleLogin()
  };

  const handleAttendanceMobile = () => {
    setMobileOpen(false);

    loggedIn
      ? history.push('/attendance')
      : handleLogin()
  };


  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        <ListItem button key='Home' onClick={() => handleClickMobile('/')}>
          <ListItemIcon><HomeIcon /></ListItemIcon>
          <ListItemText className={classes.helveticaText} primary='Home' />
        </ListItem>
        <ListItem button key='Founder' onClick={() => handleClickMobile('/founder')}>
          <ListItemIcon><DomainIcon /></ListItemIcon>
          <ListItemText className={classes.helveticaText} primary='Founder' />
        </ListItem>
        <ListItem button key='What does Kiaido Ryu mean?' onClick={() => handleClickMobile('/means')}>
          <ListItemIcon><HelpIcon /></ListItemIcon>
          <ListItemText className={classes.helveticaText} primary='What does Kiaido Ryu mean?' />
        </ListItem>
        <ListItem button key='Affiliation' onClick={() => handleClickMobile('/affiliation')}>
          <ListItemIcon><AccessibilityNewIcon /></ListItemIcon>
          <ListItemText className={classes.helveticaText} primary='Affiliation' />
        </ListItem>
        <ListItem button key='Locations' onClick={() => handleClickMobile('/contact')}>
          <ListItemIcon><ExploreIcon /></ListItemIcon>
          <ListItemText className={classes.helveticaText} primary='Locations' />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button key='Member' onClick={() => handleMemberMobile()}>
          <ListItemIcon><AccountCircle /></ListItemIcon>
          <ListItemText primary='Member' />
        </ListItem>
        {
          email != null ? (email.toLowerCase() === 'kanejscott@gmail.com' || 'paul@martialarts.co.nz' || 'sharvornm@icloud.com' || 'cssmart@hotmail.co.uk') && loggedIn ?
          <ListItem button key='Rollcall' onClick={() => handleRollcallMobile()}>
            <ListItemIcon><AccountCircle /></ListItemIcon>
            <ListItemText primary='Rollcall' />
          </ListItem> : [] : []
        }
        {
          email != null ? (email.toLowerCase() === 'kanejscott@gmail.com' || 'paul@martialarts.co.nz') && loggedIn ?
          <ListItem button key='Attendance' onClick={() => handleAttendanceMobile()}>
            <ListItemIcon><AccountCircle /></ListItemIcon>
            <ListItemText primary='Attendance' />
          </ListItem> : [] : []
        }

      </List>
    </div>
  );



  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        maxHeight="40px"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <Hidden mdUp implementation="css">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              variant="temporary"
              anchor="left"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden smDown>
            <img src="/Image/ki_trans_white.png" height="48" width="48" alt="Ki Symbol"></img>
            <StyledToggleButtonGroup
              size="small"
              value={active}
              exclusive

              onChange={handleActive}
              aria-label="navigation"
            >


              <ToggleButton className={classes.helveticaText} value="home" aria-controls="simple-menu" aria-haspopup="true" onClick={() => handleClick('/')} style={{ color: "white" }}>
                Home
              </ToggleButton>
              <ToggleButton className={classes.helveticaText} value="founder" aria-controls="simple-menu" aria-haspopup="true" onClick={() => handleClick('/founder')} style={{ color: "white" }}>
                Founder
              </ToggleButton>
              <ToggleButton className={classes.helveticaText} value="means" aria-controls="simple-menu" aria-haspopup="true" onClick={() => handleClick('/means')} style={{ color: "white" }}>
                What does Kiaido Ryu mean?
              </ToggleButton>
              <ToggleButton className={classes.helveticaText} value="affiliation" aria-controls="simple-menu" aria-haspopup="true" onClick={() => handleClick('/affiliation')} style={{ color: "white" }}>
                Affiliation
              </ToggleButton>
              <ToggleButton className={classes.helveticaText} value="contact" aria-controls="simple-menu" aria-haspopup="true" onClick={() => handleClick('/contact')} style={{ color: "white" }}>
                Locations
              </ToggleButton>
              <ToggleButton className={clsx(classes.memberBtnOff, classes.helveticaText)} value="member" classes={{ content: classes.memberBtnOff, selected: classes.selected }} size="small" aria-controls="simple-menu" onClick={handleMember} aria-haspopup="true">
                Member
              </ToggleButton>
              {
                email != null ? (email.toLowerCase() === 'kanejscott@gmail.com' || 'paul@martialarts.co.nz' || 'cssmart@hotmail.co.uk' || 'sharvornm@icloud.com') && loggedIn ? 
                <ToggleButton className={clsx(classes.memberBtnOff, classes.helveticaText)} value="rollcall" 
                classes={{ content: classes.memberBtnOff, selected: classes.selected }} size="small" aria-controls="simple-menu" onClick={handleRollcall} aria-haspopup="true">
                Rollcall
              </ToggleButton> : [] : []
              }
              {
                email != null ? (email.toLowerCase() === 'kanejscott@gmail.com' || 'paul@martialarts.co.nz') && loggedIn ? 
                <ToggleButton className={clsx(classes.memberBtnOff, classes.helveticaText)} value="attendance" 
                classes={{ content: classes.memberBtnOff, selected: classes.selected }} size="small" aria-controls="simple-menu" onClick={handleAttendance} aria-haspopup="true">
                Attendance
              </ToggleButton> : [] : []
              }



            </StyledToggleButtonGroup>
          </Hidden>


          <div className={classes.iconBtn}>
            <Button className={classes.helveticaText} onClick={loggedIn ? handleLogout : handleLogin} style={{ background: "white", color: "black" }}>
              {loggedIn ? 'Logout' : 'Login'}
            </Button>

            <IconButton
              color="inherit"
              aria-label="open drawer"

              onClick={handleDrawerOpen}
              className={clsx(open && classes.hide)}
            >
              <ChevronLeftIcon />
              <Typography className={classes.helveticaText} style={{ display: open ? "none" : "initial" }}>Open Events</Typography>

            </IconButton>

          </div>

        </Toolbar>
      </AppBar>
      <main className={clsx(classes.content, {
        // [classes.contentShift]: open,
        //That will add shift on drawer open
      })}>
        <div className={classes.drawerHeader} />
        {content}

      </main>
      <ScrollingNews open={open} setOpen={setOpen}></ScrollingNews>
    </div>
  );
}