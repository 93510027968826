import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import './styles.css';
import { client } from "./Client";



const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        flexWrap: 'wrap',
        flexDirection: 'column',
        marginLeft: '4vw',
    },
    link: {
        margin: '0.2em',
        fontFamily: 'Helvetica Now Text',
        fontSize: '400',
        marginBottom: '40px',
    },
    headerText: {
        fontFamily: 'Helvetica Now Text',
        fontWeight: 'bold',
    },
    listElement: {
        marginBottom: '8px',
    }

}));

const fetchBelts = async (belt) => {
    const response = await client.get(`belts/rank/${belt}`).catch((err) =>
        console.error("TAG", err)
    );

    if (response) {
                
        return response.id
    }
    else {


    }

};

export default function SummarySheets({belt}) {
    const classes = useStyles();

    return(
        <Box container className={classes.root}>
            <Typography className={classes.headerText}>Select sheet to download:</Typography>
            <ul>
                {belt >= 1 ? <li className={classes.listElement}><a className={classes.link} href="/Technique_Summary/White.pdf" download>White Belt Summary Sheet</a></li> : []} 
                {belt >= 2 ? <li className={classes.listElement}><a className={classes.link} href="/Technique_Summary/Yellow.pdf" download>Yellow Belt Summary Sheet</a></li> : []}
                {belt >= 3 ? <li className={classes.listElement}><a className={classes.link} href="/Technique_Summary/Orange.pdf" download>Orange Belt Summary Sheet</a></li> : []}
                {belt >= 4 ? <li className={classes.listElement}><a className={classes.link} href="/Technique_Summary/Blue.pdf" download>Blue Belt Summary Sheet</a></li> : []}
                {belt >= 5 ? <li className={classes.listElement}><a className={classes.link} href="/Technique_Summary/Purple.pdf" download>Purple Belt Summary Sheet</a></li> : []}
                {belt >= 6 ? <li className={classes.listElement}><a className={classes.link} href="/Technique_Summary/Green.pdf" download>Green Belt Summary Sheet</a></li> : []}
                {belt >= 7 ? <li className={classes.listElement}><a className={classes.link} href="/Technique_Summary/Brown.pdf" download>Brown Belt Summary Sheet</a></li> : []}
                {belt >= 8 ? <li className={classes.listElement}><a className={classes.link} href="/Technique_Summary/BlackTip.pdf" download>Black Tip Summary Sheet</a></li> : []}
                {belt >= 9 ? <li className={classes.listElement}><a className={classes.link} href="/Technique_Summary/ShodanUp.pdf" download>Summary Sheets for Shodan and above</a></li> : []}
            </ul>
        </Box>
    )
}