import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CardContent, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import './styles.css';
import Button from '@material-ui/core/Button';
import EmailIcon from '@material-ui/icons/Email';
import Gallery from 'react-grid-gallery';
import FacebookIcon from '@material-ui/icons/Facebook';
import IconButton from '@material-ui/core/IconButton';


const useStyles = makeStyles((theme) => ({
  root: {
    background: '#F1FAEE',
  },
  textTitle: {
    fontSize: '2rem',
    fontFamily: 'Helvetica Now Text',
    fontWeight: '900',
    color: '#ff3822'
  },
  boxContainer: {
    paddingTop: '2rem',
    marginLeft: '15%',
    paddingBottom: '2rem',
    width: '50%',
    "@media only screen and (max-width : 999px)": {
      width: '85%',
    },
  },
  textBody: {
    fontFamily: 'Helvetica Now Text',
    fontWeight: 'normal',
    marginTop: '1rem',
    fontSize: '1rem',
  },
  text: {
    fontFamily: 'Helvetica Now Text',
    fontWeight: 'normal',
  },
  boxBorder: {
    border: "3px solid black",
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingBottom: '1.5rem',
    marginTop: '2rem',
  },
  verticalHeadingText: {
    writingMode: 'vertical-rl',
    textOrientation: 'upright',
    fontSize: '2rem',
    fontFamily: 'Helvetica Now Text',
    fontWeight: '900',
    marginLeft: '1rem',
    color: '#ff3822',
  },
  imageBox: {
    display: "block",
    minHeight: "1px",
    paddingTop: "2%",
    width: '400px',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: "2%",
    border: "1px solid #ddd",
    overflow: "auto",
    "@media only screen and (max-width : 999px)": {
      display: 'none',
    },
  },
}));

const IMAGES =
  [{
    src: './Image/Carousel/1.jpg',
    thumbnail: './Image/Carousel/1.jpg',
    thumbnailWidth: 320,
    thumbnailHeight: 174,
  },
  {
    src: './Image/Carousel/2.jpg',
    thumbnail: './Image/Carousel/2.jpg',
    thumbnailWidth: 320,
    thumbnailHeight: 212,
  },
  {
    src: './Image/Carousel/3.jpg',
    thumbnail: './Image/Carousel/3.jpg',
    thumbnailWidth: 320,
    thumbnailHeight: 212,
  },
  {
    src: './Image/Carousel/4.jpg',
    thumbnail: './Image/Carousel/4.jpg',
    thumbnailWidth: 320,
    thumbnailHeight: 212,
  },
  {
    src: './Image/Carousel/5.jpg',
    thumbnail: './Image/Carousel/5.jpg',
    thumbnailWidth: 320,
    thumbnailHeight: 212,
  },
  {
    src: './Image/Carousel/6.jpg',
    thumbnail: './Image/Carousel/6.jpg',
    thumbnailWidth: 320,
    thumbnailHeight: 212
  }]

export default function MountDojo() {
  const classes = useStyles();
  const Mailto = ({ email, subject, body, children }) => {
    return (
      <Button startIcon={<EmailIcon />} color="primary" variant="contained" href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`}>Email Inquiry</Button>
    );
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleFacebook = () => {
    const facebookUrl = "https://www.facebook.com/KiaidoRyuTheMount"; // URL of the Facebook page you want to open
    window.open(facebookUrl, '_blank'); // Opens the URL in a new tab
  }

  return (
    <div className={classes.root}>
      <Box display="flex">
        <div className={classes.boxContainer}>
          <div style={{ maxWidth: '80%', marginBottom: '2rem' }}>
            <Typography className={classes.textTitle} style={{ paddingTop: '2rem' }}>The Mount Dojo Information</Typography>
            <Typography className={classes.textBody} component="p">Kiaido Ryu Martial Arts has a long-reaching history within the Mount Maunganui community. Located in the Bayfair area, your instructors, Dan and Wendy, are excited to welcome you into their dojo. From practical, real life self defence, to the basics of ground fighting. Learning and understanding traditional kata, and using traditional weapons. KRMA is a martial art which is sure to have something for everybody. And at The Mount, you will learn and progress in a fun, safe, and open environment for all.</Typography>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Card variant="outlined" style={{ marginTop: '2rem' }}>
                <CardContent>
                  <Typography><b>Juniors (8-12 yrs)</b><br />Tuesday & Thursday - 6.00pm - 7.00pm</Typography>
                  <Typography><b>Seniors (13 yrs +)</b><br />Tuesday & Thursday - 6.00pm - 7.30pm</Typography>
                  <Typography><b>Location:</b><br />Arataki Primary School Hall, 4 Kaimanawa Street, Mt Maunganui, Tauranga.</Typography>
                  <Typography><b>Contact:</b><br />Dan - 027 843 3216</Typography>
                  <div style={{display: 'flex', alignItems: 'center', justifyContent:'center', marginTop: '24px'}}>
                    <Mailto email="mountmartialarts@gmail.com" subject="Mount Maunganui Dojo Inquiry" body="Replace with your inquiry!">mountmartialarts@gmail.com</Mailto>
                  </div>
                  <div style={{display: 'flex', alignItems: 'center', justifyContent:'center', marginTop: '24px'}}>
                    <IconButton size="large" onClick={handleFacebook}>
                      <FacebookIcon />
                    </IconButton>
                  </div>
                </CardContent>
              </Card>
            </Box>
          </div>

          <div style={{maxWidth: '85%'}}>
            <Typography className={classes.textTitle}>Dai Sempai Dan & Sensei Wendy</Typography>
            <Typography variant="body2" color="textSecondary" component="p" style={{ paddingBottom: '2rem' }}>
              2nd Degree Black Belt (Nidan) & 3rd Degree Black Belt (Sandan)
            </Typography>
            <img style={{ float: 'left', paddingRight: '25px', maxWidth: '360px' }} alt="Dan and Wendy" src="./Image/Mount/mountInstructors.jpg"></img>
            <div style={{ display: "inline" }}>
              <Typography className={classes.text}>Both Dan and Wendy have trained across multiple dojos within Kiaido Ryu Martial Arts. With over 50 years experience between them and learning from some of the greatest martial arts instructors in the country, they have gathered a wealth of knowledge. They are passionate about sharing that knowledge with their students. 
              <br/>
              <br/><i>"We believe in creating an atmosphere within the dojo where our students - both kids and adults alike, can grow and thrive".</i> - Dai Sempai Dan and Sensei Wendy
              </Typography>
            </div>
          </div>
        </div>

        <Box className={classes.imageBox}>
          <Gallery images={IMAGES} enableImageSelection={false} margin={12} />
        </Box>

      </Box>
    </div>
  );

}