import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import './styles.css';
import ReactPlayer from 'react-player';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';

const videoAddress = "https://s3.ap-southeast-2.amazonaws.com/karate.static/video/Seminars/BlackBeltSeminar01.mp4";
const useStyles = makeStyles((theme) => ({
    button: {
      width: '50%',
      marginTop: '5em',
    },
    titleBar: {
        background:
          'linear-gradient(to top, rgba(0,0,0,0.7) 0%, ' +
          'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
      },
  
  }))

  export default function Seminar() {
    const classes = useStyles();
    return (
        <div style={{paddingLeft: '7vw'}}>
        <GridListTile style={{  width: '75vw', listStyle: 'none', marginBottom: '2em', border: '1px solid #cecece' }}>
        
        <ReactPlayer pip={true} light width='100%' controls={true} url={videoAddress} />
        <GridListTileBar
                                  title="Black Belt Seminar"
                                  classes={{
                                    root: classes.titleBar,
                                    title: classes.title,
                                  }}
                                  titlePosition='top'
                                  style={{  width: '75vw'}}
                                />
        </GridListTile>
        </div>
    );

  }
