import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CardContent, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import './styles.css';
import Button from '@material-ui/core/Button';
import EmailIcon from '@material-ui/icons/Email';
import Gallery from 'react-grid-gallery';


const useStyles = makeStyles((theme) => ({
  root: {
    background: '#F1FAEE',
    height: '100%',
  },
  textTitle: {
    fontSize: '2rem',
    fontFamily: 'Helvetica Now Text',
    fontWeight: '900',
    color: '#ff3822'
  },
  boxContainer: {
    paddingTop: '2rem',
    marginLeft: '15%',
    paddingBottom: '2rem',
    width: '50%',
    "@media only screen and (max-width : 999px)": {
      width: '85%',
    },
  },
  textBody: {
    fontFamily: 'Helvetica Now Text',
    fontWeight: 'normal',
    marginTop: '1rem',
    fontSize: '1rem',
  },
  text: {
    fontFamily: 'Helvetica Now Text',
    fontWeight: 'normal',
    "@media only screen and (max-width : 660px)": {
      clear: 'both'
    },
  },
  boxBorder: {
    border: "3px solid black",
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingBottom: '1.5rem',
    marginTop: '2rem',
  },
  verticalHeadingText: {
    writingMode: 'vertical-rl',
    textOrientation: 'upright',
    fontSize: '2rem',
    fontFamily: 'Helvetica Now Text',
    fontWeight: '900',
    marginLeft: '1rem',
    color: '#ff3822',
  },
  imageBox: {
    display: "block",
    minHeight: "1px",
    paddingTop: "2%",
    width: '360px',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: "2%",
    border: "1px solid #ddd",
    overflow: "auto",
    "@media only screen and (max-width : 999px)": {
      display: 'none',
    },
  },
}));

const IMAGES =
  [{
    src: './Image/Putaruru/ChritineTownsend.jpg',
    thumbnail: './Image/Putaruru/ChritineTownsend.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180',
  },
  {
    src: './Image/Putaruru/PaulMcEwan.JPG',
    thumbnail: './Image/Putaruru/PaulMcEwan.JPG',
    thumbnailWidth: '320px',
    thumbnailHeight: '180',
  },
  {
    src: './Image/Putaruru/1.jpg',
    thumbnail: './Image/Putaruru/1.jpg',
    thumbnailWidth: '320',
    thumbnailHeight: '180',
  },
  {
    src: './Image/Decent/20.jpg',
    thumbnail: './Image/Decent/20.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180',
  },
  {
    src: './Image/Decent/19.jpg',
    thumbnail: './Image/Decent/19.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180',
  },
  {
    src: './Image/Decent/18.jpg',
    thumbnail: './Image/Decent/18.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180',
  },
  {
    src: './Image/Decent/17.jpg',
    thumbnail: './Image/Decent/17.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180',
  },
  {
    src: './Image/Decent/16.jpg',
    thumbnail: './Image/Decent/16.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180',
  },
  {
    src: './Image/Decent/15.jpg',
    thumbnail: './Image/Decent/15.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180',
  },
  {
    src: './Image/Decent/11.jpg',
    thumbnail: './Image/Decent/11.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180',
  },]

export default function PutaruruDojo() {
  const classes = useStyles();
  const Mailto = ({ email, subject, body, children }) => {
    return (
      <Button startIcon={<EmailIcon />} color="primary" variant="contained" href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`}>Email Inquiry</Button>
    );
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.root}>
      <Box display="flex">
        <div className={classes.boxContainer}>
          <div style={{ maxWidth: '85%', marginBottom: '4em' }}>
            <Typography className={classes.textTitle}>Putaruru Dojo Information - Dai Sempai Paul McEwan
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p" style={{ paddingBottom: '2rem' }}>
              2nd Degree Black Belt (Nidan) - Putaruru Dojo Instructor
            </Typography>
            <img style={{ float: 'left', paddingRight: '25px', maxWidth: '280px' }} alt="PaulMcEwan" src='./Image/Putaruru/PaulMcEwan.JPG'></img>
            <div style={{ display: "inline" }}>
              <Typography className={classes.text}>The origins of the Putaruru Dojo were established in 2010 by Dai Sempai D’Arcy & Sempai Michelle Matthews.
              <br/><br/>The Dojo was passed onto Dai Sempai Paul McEwan who achieved his 2nd Degree Black Belt in Dec 2017 
              
              <br/><br/>Our Putaruru Kiaido Ryu Martial Arts school offers a supportive, positive, family friendly environment. It is for people of all ages to help improve their physical & mental wellbeing and to learn how to achieve their goals through Martial Arts.
              
              <br/><br/>We offer a complete Martial Arts System
              
              </Typography>
            </div>
          </div>
          <div style={{ maxWidth: '85%', marginBottom: '2rem' }}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Card variant="outlined" style={{ marginTop: '2rem' }}>
                <CardContent>
                  <Typography><b>Juniors and Seniors</b><br />
                    Monday & Wednesday: 6.30pm - 8.00pm</Typography>
                  <Typography><b>Contact</b> <br /> Paul McEwan, 027 2832777</Typography>
                  <Typography><b>Location</b> <br />    Junction Street, Putaruru 3411. Putaruru College Gym    </Typography>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '24px' }}>
                    <Mailto style={{ marginLeft: 'auto' }} email="paulmcewan968@gmail.com" subject="Putaruru  Dojo Inquiry" body="Replace with your inquiry!">paul@martialarts.co.nz</Mailto>
                  </div>
                </CardContent>
              </Card>
            </Box>
          </div>

        </div>

        <Box className={classes.imageBox} style={{ maxHeight: '100vh' }}>
          <div style={{ width: '320px', margin: '0 auto' }}>
            <Gallery backdropClosesModal={true} images={IMAGES} enableImageSelection={false} margin={12} />
          </div>
        </Box>

      </Box>
    </div>
  );

}