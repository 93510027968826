import React, { useState, useEffect } from 'react';
import { Typography, Grid, Box, Paper } from '@material-ui/core/';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import FormGroup from '@mui/material/FormGroup';
import { client } from "./Client";
import { useSnackbar, SnackbarProvider } from "notistack";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import './styles.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import clsx from 'clsx'
import moment from 'moment';
import { useSwipeable } from "react-swipeable";
import CancelIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import Fade from '@mui/material/Fade';

const theme = createMuiTheme();

theme.typography.body1 = {
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.2rem',
    fontFamily: 'Helvetica Now Text',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.2rem',
    fontFamily: 'Helvetica Now Text',
  },
};

const useStyles = makeStyles((theme) => ({
  topText: {
    display: "flex",
    flexWrap: 'wrap',
    width: '100%',
    paddingTop: '2em',
    paddingBottom: '2em',
    boxShadow: '0px 5px 5px 0px rgba(0,0,0,0.75)',
    background: '#dadad9',
    color: 'red',
    zIndex: "3",
  },
  darkerLight: {
    background: '#dadad9',
    borderBottom: '2px solid #A9A9A9',
    boxShadow: 'inset 0px 4px 8px #A9A9A9',
    zIndex: 1,
    width: '100%',
    paddingTop: '2rem',
    paddingBottom: '2rem',


  },
  root: {
    display: "flex",
    flexWrap: 'wrap',
    //boxShadow: 'inset 15px 0px 15px -10px rgba(0,0,0,0.75), inset -15px 0px 15px -10px rgba(0,0,0,0.75)', 
    //boxShadow: ' -5px 0px 10px 0px rgba(0,0,0,0.75), 5px 0px 10px 0px rgba(0,0,0,0.75)',
    paddingLeft: '5vw',
    paddingRight: '5vw',
    zIndex: "-1",
    "@media only screen and (max-width : 700px)": {
      marginLeft: '5vw',
      maxWidth: '90vw',
    },

    /* Extra Small Devices, Phones */
    "@media only screen and (min-width : 1000px)": {
      marginLeft: '5vw',
      maxWidth: '90vw',
    },

    /* Small Devices, Tablets */
    "@media only screen and (min-width : 1300px)": {
      marginLeft: '10vw',
      maxWidth: '80vw',
    },

    /* Medium Devices, Desktops */
    "@media only screen and (min-width : 1600px)": {
      marginLeft: '15vw',
      maxWidth: '70vw',
    },

    /* Large Devices, Wide Screens */
    "@media only screen and (min-width : 1900px)": {
      marginLeft: '20vw',
      maxWidth: '60vw',
    },
  },
  headerText: {
    fontWeight: 'bold',
    fontFamily: 'Helvetica Now Text',
    fontSize: '2rem',
  },
  bodyText: {
    fontFamily: 'Helvetica Now Text',
    fontSize: '400',
  },
  lightBackground: {
    background: '#F1FAEE',
  },
  darkBackground: {
    background: '#dadad9',
  },
  paperList: {
    maxWidth: '45%',
    paddingRight: '40px',
    paddingTop: '16px',
    paddingBottom: '16px',
    marginBottom: '2rem',
    border: 'solid 1px lightgrey',
    color: 'black',
    "@media only screen and (max-width : 1000px)": {
      width: '100%',
      maxWidth: '100%'
    },
  },
  reactiveFlex: {
    flexDirection: 'row',
    "@media only screen and (max-width : 1000px)": {
      flexDirection: 'column',
    },
  },
  topCenter: {
    transition: "all ease 500ms",
    /* Custom, iPhone Retina */
    "@media only screen and (max-width : 700px)": {
      marginLeft: '5%',
      marginRight: '8%',
    },

    /* Extra Small Devices, Phones */
    "@media only screen and (min-width : 1000px)": {
      marginLeft: '10%',
      marginRight: '10%',
    },

    /* Small Devices, Tablets */
    "@media only screen and (min-width : 1300px)": {
      marginLeft: '15%',
      marginRight: '15%',
    },

    /* Medium Devices, Desktops */
    "@media only screen and (min-width : 1600px)": {
      marginLeft: '20%',
      marginRight: '20%',
    },

    /* Large Devices, Wide Screens */
    "@media only screen and (min-width : 1900px)": {
      marginLeft: '25%',
      marginRight: '25%',
    },
  },

  divider: {
    /* Custom, iPhone Retina */
    "@media only screen and (max-width : 700px)": {
      width: '75%'
    },

    /* Extra Small Devices, Phones */
    "@media only screen and (min-width : 1000px)": {
      width: '65%'
    },

    /* Small Devices, Tablets */
    "@media only screen and (min-width : 1300px)": {
      width: '55%'
    },

    /* Medium Devices, Desktops */
    "@media only screen and (min-width : 1600px)": {
      width: '45%'
    },

    /* Large Devices, Wide Screens */
    "@media only screen and (min-width : 1900px)": {
      width: '35%'
    },
  },
}));



export default function Rollcall() {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [users, setUsers] = useState(null);
  const [currentUser, setCurrentUser] = useState(0);
  const [lastSnackbar, setLastSnackBar] = useState(null);
  const [listDisplay, setListDisplay] = useState(false);
  const [finished, setFinished] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleChangeUser = (index, checked) => {
    const itemRef = [...users];

    itemRef[index].attended = checked
    setUsers([...itemRef]);
    console.log('TAG USERS: ', users);

  }

  const switchInstructor = (instructor) => {
    switch (instructor.toLowerCase()) {
      case 'kanejscott@gmail.com':
        return 'Otumoetai'
      case 'paul@martialarts.co.nz':
        return 'Otumoetai'
      case 'sharvornm@icloud.com':
        return 'Otuemoetai'
      case 'cssmart@hotmail.co.uk':
        return 'Otuemoetai'
      default:
        return 'null'
    }
  }

  const fetchUsers = async () => {
    const email = localStorage.getItem('email');
    if (email != null) {
      console.log("TAG current email", email)
      const response = await client.get(`users/dojo/` + switchInstructor(email)).catch((err) =>
        console.error(err)
      );
      if (response) {
        var swipe = []
        response.map((item) =>
          item.active ?
          swipe.push({ firstName: item.firstname, lastName: item.lastname, id: item.id, active: item.active, rank: item.rank, attended: false }) : []
        )
        const swipeSorted = [].concat(swipe).sort((a, b) => valueByRank(a.rank) < valueByRank(b.rank) ? 1 : -1);
        setUsers(swipeSorted);
        console.log('TAG USERS: ', swipeSorted);
        console.log('TAG user amount', swipeSorted.length);
      }
      else {
        enqueueSnackbar("User retrieval failed", {
          variant: "default",
        });
      }
    }
  };

  const valueByRank = (value) => {
    if (value.toLowerCase() === 'white') {
      return 0;
    }
    else if (value.toLowerCase() === 'yellow') {
      return 1;
    }
    else if (value.toLowerCase() === 'orange') {
      return 2;
    }
    else if (value.toLowerCase() === 'blue') {
      return 3;
    }
    else if (value.toLowerCase() === 'purple') {
      return 4;
    }
    else if (value.toLowerCase() === 'green') {
      return 5;
    }
    else if (value.toLowerCase() === 'brown') {
      return 6;
    }
    else if (value.toLowerCase() === 'shodan') {
      return 7;
    }
    else if (value.toLowerCase() === 'nidan') {
      return 8;
    }
    else {
      return 9;
    }
  }


  const postSession = async () => {
    console.log(users);
    const response = users.map((item) => {
      const message = { user: item.id, date: moment(new Date()).format("YYYY-MM-DD"), attended: item.attended }
      client.post(`userSessions/create`, message).catch((err) => {
        console.error(err)
        enqueueSnackbar("error updating user: " + item.firstName + " " + item.lastName, {
          variant: "error",
        });
      }

      );
    });
    setSubmitted(true);
    enqueueSnackbar("Session logged", {
      variant: "default",
    });

  };

  useEffect(() => {
    fetchUsers();


  }, []);

  const snackky = snackbarId => (
    <Button style={{ background: 'white', width: '50px', height: '50px' }} onClick={() => { alert(`I belong to snackbar with id ${snackbarId}`); }}>
      Undo
    </Button>

  );

  const test =
    <Button>
      BLAAAAAAAAA
    </Button>

  const action = snackbarId => (

    <Button onClick={handleUndo}>
      Undo
    </Button>

  );

  const handleListDisplay = () => {
    console.log("set list: ", !listDisplay);
    setListDisplay(!listDisplay);
  }

  const handleUndo = () => {
    var tempCurrent = currentUser;
    setCurrentUser(tempCurrent);
  }

  const markAbsent = () => {
    const itemRef = [...users];
    itemRef[currentUser].attended = false;


    console.log("TAG checked user", currentUser);
    if (currentUser < (users.length - 1)) {

      setUsers([...itemRef]);
      setCurrentUser(currentUser + 1);
      if (lastSnackbar != null) {
        closeSnackbar(lastSnackbar)
      }
      const id = enqueueSnackbar(itemRef[currentUser].firstName + " " + itemRef[currentUser].lastName + " marked absent", {
        action,
      });
      setLastSnackBar(id);
    }
    else {
      setFinished(true);
    }
  }

  const markPresent = () => {
    const itemRef = [...users];
    itemRef[currentUser].attended = true;

    if (currentUser < (users.length - 1)) {
      setUsers([...itemRef]);
      setCurrentUser(currentUser + 1);
      if (lastSnackbar != null) {
        closeSnackbar(lastSnackbar)
      }
      const id = enqueueSnackbar(itemRef[currentUser].firstName + " " + itemRef[currentUser].lastName + " marked present", {
        action,
      });
      setLastSnackBar(id);
    }
    else {
      setFinished(true);
    }
  }

  const handlersBox = useSwipeable({
    onSwipedLeft: () => {
      console.log("swiped left")

      markAbsent();

    },
    onSwipedRight: () => {
      console.log("swiped right")

      markPresent();
    },
    // NOTE: another approach via onSwiping
    onSwiping: ({ event }) => event.stopPropagation(),
    preventDefaultTouchmoveEvent: true,
    preventScrollOnSwipe: true,
  });

  return (

    <div style={{ background: 'white' }}>
      <ThemeProvider theme={theme}>
        <Box position='relative' container>
          <Box container style={{ paddingTop: '48px' }}>
            <div style={{ display: 'flex' }}>
              <Typography className={classes.headerText} style={{ fontSize: '6vh', color: '#1D3557', flex: '1', textAlign: 'center', paddingLeft: '8vh' }} variant="h4">Rollcall</Typography>
              <Button variant="contained" style={{ position: 'relative', marginRight: '4px', height: '8vh' }} onClick={handleListDisplay}>{listDisplay === false ? <FormatListBulletedIcon /> : <TouchAppIcon />}</Button>
            </div>




            {listDisplay === false ?
              finished === false ?
                <Paper elevation={10} style={{ display: 'flex', justifyContent: 'center', alignSelf: 'center' }}>
                  <Box sx={{ display: 'flex', background: 'white', width: '100vw', height: '74vh', }} {...handlersBox}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', flexGrow: '1' }}>
                      <Box style={{ flexGrow: '1', background: 'none', paddingBottom: "8px" }}>
                        <Typography style={{ textAlign: 'center', fontSize: '5vw' }}>{users != null ? users[currentUser].rank + " Belt" : ''}</Typography>
                      </Box>
                      <Box style={{ flexGrow: '1', background: 'none' }}>
                        <Typography style={{ textAlign: 'center', fontSize: '10vw' }}><b>{users != null ? users[currentUser].firstName + " " + users[currentUser].lastName : ''}</b></Typography>
                      </Box>
                      <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '50px' }}>
                        <Box style={{ flexGrow: '1', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                          <CancelIcon style={{ color: 'red', width: '15vw', height: '15vw' }} onClick={() => markAbsent()}></CancelIcon>
                        </Box>
                        <Box style={{ flexGrow: '1', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                          <CheckCircleOutlineIcon style={{ color: 'green', height: '15vw', width: '15vw' }} onClick={() => markPresent()}></CheckCircleOutlineIcon>
                        </Box>
                      </Box>
                    </Box>

                  </Box>
                </Paper>
                :
                <Paper elevation={10} style={{ display: 'flex' }}>
                  <Box sx={{ display: 'flex', background: 'white', width: '100vw', height: '74vh', flexDirection: 'column', }} >
                    <Fade in={!submitted}><Typography className={classes.headerText} style={{ fontSize: '6vh', color: '#1D3557', textAlign: 'center' }} variant="h4">Finished marking. Please submit or edit in list mode</Typography></Fade>
                    <Fade in={submitted}><Typography className={classes.headerText} style={{ fontSize: '6vh', color: '#1D3557', textAlign: 'center' }} variant="h4">Marking submitted</Typography></Fade>
                    <Button variant="contained" style={{ margin: 'auto', width: '30vw', height: '8vh' }} disabled={submitted} onClick={() => postSession()}>Submit</Button>
                  </Box>
                </Paper>

              : <Box sx={{ flexDirection: 'column' }}>
                {
                  users !== null ? users.map((item, i) =>
                    <FormGroup style={{ paddingTop: '48px' }}>
                      <FormControlLabel
                        control={<Checkbox key={item.id} sx={{ '& .MuiSvgIcon-root': { fontSize: '8vw' } }} checked={item.attended} onChange={(_e, checked) => handleChangeUser(i, checked)} />}
                        label={<Typography style={{ fontSize: '4vh', color: item.firstName === users[currentUser].firstName ? 'yellow' : 'black' }}>{item.firstName} {item.lastName}</Typography>}
                        style={{ fontSize: '8vh' }}
                      />
                    </FormGroup>
                  ) : []
                }
                <Box sx={{ display: 'flex', justifyContent: 'center', paddingBottom: '24px' }}>
                  <Button variant="contained" onClick={() => postSession()}>Submit</Button>
                </Box>
              </Box>
            }

          </Box>
        </Box>


      </ThemeProvider>
    </div>
  );
}