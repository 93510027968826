import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CardContent, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import './styles.css';
import Button from '@material-ui/core/Button';
import EmailIcon from '@material-ui/icons/Email';
import Gallery from 'react-grid-gallery';


const useStyles = makeStyles((theme) => ({
  root: {
    background: '#F1FAEE',
  },
  textTitle: {
    fontSize: '2rem',
    fontFamily: 'Helvetica Now Text',
    fontWeight: '900',
    color: '#ff3822'
  },
  boxContainer: {
    paddingTop: '2rem',
    marginLeft: '15%',
    paddingBottom: '2rem',
    width: '50%',
    "@media only screen and (max-width : 999px)": {
      width: '85%',
    },
  },
  textBody: {
    fontFamily: 'Helvetica Now Text',
    fontWeight: 'normal',
    marginTop: '1rem',
    fontSize: '1rem',
  },
  text: {
    fontFamily: 'Helvetica Now Text',
    fontWeight: 'normal',
  },
  boxBorder: {
    border: "3px solid black",
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingBottom: '1.5rem',
    marginTop: '2rem',
  },
  verticalHeadingText: {
    writingMode: 'vertical-rl',
    textOrientation: 'upright',
    fontSize: '2rem',
    fontFamily: 'Helvetica Now Text',
    fontWeight: '900',
    marginLeft: '1rem',
    color: '#ff3822',
  },
  imageBox: {
    display: "block",
    minHeight: "1px",
    paddingTop: "2%",
    width: '400px',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: "2%",
    border: "1px solid #ddd",
    overflow: "auto",
    "@media only screen and (max-width : 999px)": {
      display: 'none',
    },
  },
}));

const IMAGES =
  [
  {
    src: './Image/Thames/4.jpg',
    thumbnail: './Image/Thames/4.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180px',
  },
  {
    src: './Image/Thames/5.jpg',
    thumbnail: './Image/Thames/5.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180px',
  },
  {
    src: './Image/Thames/7.jpg',
    thumbnail: './Image/Thames/7.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180px',
  },
  {
    src: './Image/Thames/8.jpg',
    thumbnail: './Image/Thames/8.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180px',
  },
  {
    src: './Image/Thames/9.jpg',
    thumbnail: './Image/Thames/9.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180px',
  },
  
]

export default function TaupoDojo() {
  const classes = useStyles();
  const Mailto = ({ email, subject, body, children }) => {
    return (
      <Button startIcon={<EmailIcon />} color="primary" variant="contained" href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`}>Email Inquiry</Button>
    );
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.root}>
      <Box display="flex">
        <div className={classes.boxContainer}>
        <div style={{maxWidth: '85%'}}>
        <Typography className={classes.textTitle}>Rukahia Dojo Information - Shihan Phil Murray</Typography>
        <Typography variant="body2" color="textSecondary" component="p" style={{ paddingBottom: '2rem' }}>
        5th Degree Black Belt (Rokudan) KRMA Master Board - Rukahia Dojo Instructor
        </Typography>
        <img style={{ float: 'left', paddingRight: '25px', maxWidth: '180px' }} alt="paul" src="./Image/Hamilton/hamiltonInstructor.jpg"></img>
        <div style={{ display: "inline" }}>
        <Typography className={classes.text}>Phil started training with Kiaido Ryu in 1999 in Cambridge Dojo. In 2005 he took over the Instructors role at the school. In 2008 Phil attained his 3rd Degree Black Belt and Sensei ranking. He's currently head instructor for Hamilton and the Waikato Region.</Typography>
        <Typography className={classes.text}><br />“I've always wanted to do martial arts ever since I was a small child.” “It always seemed like a great thing to get involved in; to be able to defend yourself and others.” “It wasn't until I got started that I realized it was so much more than that.” “It's all about family and responsibility and more than just punching and kicking; it teaches self control and is a great builder of self esteem.” “The students are my biggest motivation; watching them grow and develop, especially the juniors, is a very rewarding aspect of teaching.”
        </Typography>
        <Typography className={classes.text}><br />“One of the biggest goals I have has to do with developing my students as martial artists.” “There's no higher compliment than being able to send someone to a grading and watch them do really well, and to see the smile hidden under all the sweat when they walk off the floor with their new belt; it 's tremendously rewarding.”</Typography>
        <Typography className={classes.text}><br />“So my goal is to make sure that they smile at the end of the grading and be proud of themselves.” “My second and personal goal is to carry on through the ranks and become the best martial artist that I can be.” “There's always something new to learn no matter what rank you are.” “It's important to me to take every opportunity for growth and learning that is thrown my way.”</Typography>

          
        </div>
      </div>

          <div style={{ maxWidth: '85%', marginBottom: '2rem', }}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Card variant="outlined" style={{ marginTop: '2rem' }}>
                <CardContent>
                  <Typography><b>Juniors</b><br />
                    Monday and Wednesday - 6pm to 7pm</Typography>
                  <Typography><b>Seniors</b><br />
                    Monday and Wednesday - 7pm to 8.30pm</Typography>
                  <Typography><b>Contact</b><br /> Phil Murray, 021 183 9025</Typography>
                  <Typography><b>Location</b> <br />  Rukuhia Primary School, Hamilton  </Typography>
                  <div style={{display: 'flex', alignItems: 'center', justifyContent:'center', marginTop: '24px'}}>
                  <Mailto email="phil@martialarts.co.nz" subject="Hamilton Dojo Inquiry" body="Replace with your inquiry!">phil@martialarts.co.nz</Mailto></div>
                </CardContent>
              </Card>
            </Box>
          </div>


        </div>

        <Box className={classes.imageBox}>
          <Gallery backdropClosesModal={true} images={IMAGES} enableImageSelection={false} margin={12} />
        </Box>

      </Box>
    </div>
  );

}