import React, { useState, useEffect } from 'react';
import { Typography, Box } from '@material-ui/core/';
import { makeStyles, createMuiTheme, } from '@material-ui/core/styles';
import { client } from "./Client";
import { useSnackbar } from "notistack";
import Button from '@mui/material/Button';
import './styles.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const theme = createMuiTheme();

theme.typography.body1 = {
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.2rem',
    fontFamily: 'Helvetica Now Text',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.2rem',
    fontFamily: 'Helvetica Now Text',
  },
};

const useStyles = makeStyles((theme) => ({

}));



export default function Attendance() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [users, setUsers] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [attendance, setAttendance] = useState(null)
  const [monthTotal, setMonthTotal] = useState(null)
  const [monthAttended, setMonthAttended] = useState(null)

  useEffect(() => {
    fetchUsers();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const switchInstructor = (instructor) => {
    switch (instructor.toLowerCase()) {
      case 'kanejscott@gmail.com':
        return 'Otumoetai'
      case 'paul@martialarts.co.nz':
        return 'Otumoetai'
      default:
        return 'null'
    }
  }

  const fetchUsers = async () => {
    const email = localStorage.getItem('email');
    if (email != null) {
      const response = await client.get(`users/dojo/` + switchInstructor(email)).catch((err) =>
        console.error(err)
      );
      if (response) {
        var swipe = []
        response.map((item) =>
          swipe.push({ firstName: item.firstname, lastName: item.lastname, id: item.id, rank: item.rank, attended: false })
        )
        const swipeSorted = [].concat(swipe).sort((a, b) => valueByRank(a.rank) < valueByRank(b.rank) ? 1 : -1);
        setUsers(swipeSorted);
      }
      else {
        enqueueSnackbar("User retrieval failed", {
          variant: "default",
        });
      }
    }
  };

  const setMonths = (array) => {
    var monthMap = [];
    array.map((session, i) => {
      var date = new Date(session.date)
      monthMap = [...monthMap, { 'date': date, 'attended': session.attended }]
    }
    )
    setAttendance(monthMap)
    var monthTotal = { 'January': 0, 'February': 0, 'March': 0, 'April': 0, 'May': 0, 'June': 0, 'July': 0, 'August': 0, 'September': 0, 'October': 0, 'November': 0, 'December': 0 }
    monthMap.map((a) => {
      a.date.getMonth() === 0 ? monthTotal = { ...monthTotal, 'January': monthTotal.January + 1 } :
        a.date.getMonth() === 1 ? monthTotal = { ...monthTotal, 'February': monthTotal.February + 1 } :
          a.date.getMonth() === 2 ? monthTotal = { ...monthTotal, 'March': monthTotal.March + 1 } :
            a.date.getMonth() === 3 ? monthTotal = { ...monthTotal, 'April': monthTotal.April + 1 } :
              a.date.getMonth() === 4 ? monthTotal = { ...monthTotal, 'May': monthTotal.May + 1 } :
                a.date.getMonth() === 5 ? monthTotal = { ...monthTotal, 'June': monthTotal.June + 1 } :
                  a.date.getMonth() === 6 ? monthTotal = { ...monthTotal, 'July': monthTotal.July + 1 } :
                    a.date.getMonth() === 7 ? monthTotal = { ...monthTotal, 'August': monthTotal.August + 1 } :
                      a.date.getMonth() === 8 ? monthTotal = { ...monthTotal, 'September': monthTotal.September + 1 } :
                        a.date.getMonth() === 9 ? monthTotal = { ...monthTotal, 'October': monthTotal.October + 1 } :
                          a.date.getMonth() === 10 ? monthTotal = { ...monthTotal, 'November': monthTotal.November + 1 } :
                            a.date.getMonth() === 11 ? monthTotal = { ...monthTotal, 'December': monthTotal.December + 1 } : console.log()
    })
    var monthAttended = { 'January': 0, 'February': 0, 'March': 0, 'April': 0, 'May': 0, 'June': 0, 'July': 0, 'August': 0, 'September': 0, 'October': 0, 'November': 0, 'December': 0 }
    monthMap.map((a) => {
      a.attended === true ?
        a.date.getMonth() === 0 ? monthAttended = { ...monthAttended, 'January': monthAttended.January + 1 } :
          a.date.getMonth() === 1 ? monthAttended = { ...monthAttended, 'February': monthAttended.February + 1 } :
            a.date.getMonth() === 2 ? monthAttended = { ...monthAttended, 'March': monthAttended.March + 1 } :
              a.date.getMonth() === 3 ? monthAttended = { ...monthAttended, 'April': monthAttended.April + 1 } :
                a.date.getMonth() === 4 ? monthAttended = { ...monthAttended, 'May': monthAttended.May + 1 } :
                  a.date.getMonth() === 5 ? monthAttended = { ...monthAttended, 'June': monthAttended.June + 1 } :
                    a.date.getMonth() === 6 ? monthAttended = { ...monthAttended, 'July': monthAttended.July + 1 } :
                      a.date.getMonth() === 7 ? monthAttended = { ...monthAttended, 'August': monthAttended.August + 1 } :
                        a.date.getMonth() === 8 ? monthAttended = { ...monthAttended, 'September': monthAttended.September + 1 } :
                          a.date.getMonth() === 9 ? monthAttended = { ...monthAttended, 'October': monthAttended.October + 1 } :
                            a.date.getMonth() === 10 ? monthAttended = { ...monthAttended, 'November': monthAttended.November + 1 } :
                              a.date.getMonth() === 11 ? monthAttended = { ...monthAttended, 'December': monthAttended.December + 1 } : console.log() : console.log()
    })
    setMonthTotal(monthTotal)
    setMonthAttended(monthAttended)
  }

  const fetchAttendance = async (user) => {

    const response = await client.get(`userSessions/` + user.id).catch((err) =>
      console.error(err)
    );
    if (response) {
      setMonths(response)
    }
    else {
      enqueueSnackbar("User retrieval failed", {
        variant: "default",
      });
    }

  };

  const valueByRank = (value) => {
    const lowerValue = value.toLowerCase();
    if (lowerValue === 'white') {
      return 0;
    }
    else if (lowerValue === 'yellow') {
      return 1;
    }
    else if (lowerValue === 'orange') {
      return 2;
    }
    else if (lowerValue === 'blue') {
      return 3;
    }
    else if (lowerValue === 'purple') {
      return 4;
    }
    else if (lowerValue === 'green') {
      return 5;
    }
    else if (lowerValue === 'brown') {
      return 6;
    }
    else if (lowerValue === 'shodan') {
      return 7;
    }
    else if (lowerValue === 'nidan') {
      return 8;
    }
    else {
      return 9;
    }
  }





  return (

    <div>
      {users != null && currentUser === null ?
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {users.map((item, i) =>
            <Button
              style={{ display: 'flex' }}
              onClick={() => {
                setCurrentUser(item)
                fetchAttendance(item)
              }}
            >{item.firstName} {item.lastName}</Button>
          )}
        </Box>
        :
        currentUser != null ?
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '90vh' }}>
            <Typography>{currentUser.firstName} {currentUser.lastName}</Typography>
            {//<Typography>Attendance: {attendance != null ? attendance[0] +'/'+ attendance[1] : 'attendance loading...'}</Typography>
            }
            {
              monthTotal !== null && monthAttended !== null ?
                <div>
                  {Object.keys(monthTotal).map((key, index) =>
                    <Typography>{monthTotal[key] === 0 ? key + ': No trainings' :  key + ': ' + ((monthAttended[key] / monthTotal[key]) * 100) + '%'}</Typography>
                  )}
                </div>
                : <div>
                  
                </div>
            }
            <Button variant="outlined" style={{ marginTop: '12px' }} onClick={() => {
              setCurrentUser(null)
              setAttendance(null)
            }}>Back</Button>
          </Box>
          :
          []
      }
    </div>
  );
}