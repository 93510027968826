import React from 'react';
import { Typography, Grid, Box } from '@material-ui/core/';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Gallery from 'react-grid-gallery';
import './styles.css';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  helvetica: {
    fontFamily: 'Helvetica Now Text',
  },
  card: {
    flex: '1',
    padding: '2rem',
    marginTop: '2rem',
    marginBottom: '2rem',
    marginLeft: '10rem',
    marginRight: '10rem'
  },
  cardDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardUnderlineHeader: {
    fontFamily: 'Helvetica Now Text',
    fontWeight: '600',
    paddingBottom: '24px',
    fontSize: '1.6rem',
    textDecoration: 'underline',
    color: '#DC2F02',
  },
  cardContent: {
    display: 'flex',
    alignContent: "flex-end",
    height: '100%',
    width: '100%',
  },
  cardInnerSpaced: {
    paddingBottom: '24px',
    fontSize: '1rem',
    fontWeight: '400',
    fontFamily: 'Helvetica Now Text',

  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  caro: {
    width: '100%',
    minWidth: '10%',
    minHeight: '10%',
  },
  light: {
    // background: '#F2F2F1',
    background: '#F1FAEE',
    color: '#212125',
    fontFamily: 'Helvetica Now Text',
  },
  listSpacing: {
    marginBottom: '1.2rem',
  },
  darkbluebkg: {
    background: '#1D3557',
  },
  darkbluetxt: {
    color: '#1D3557',
  },
  white: {
    background: "white",
    border: "2px solid #ddd",
    minHeight: "1px",
    width: "100%",
    fontFamily: 'Helvetica Now Text',
    fontWeight: 'normal',
  },
  dark: {
    background: '#212125',
    color: '#F2F2F1'
  },
  center: {
    marginLeft: '25%',
    marginRight: '25%',
  },
  nocenter: {
    marginLeft: '0%',
    marginRight: '0%',
  },
  spacedTop: {
    paddingTop: 48,
  },
  spacedBottom: {
    paddingBottom: 48,
  },
  spaced: {
    paddingBottom: 48,
    paddingTop: 48,

  },
  sideImages: {
    paddingLeft: '2rem',
    paddingRight: '2rem',
    background: '#F1FAEE',
  },
  primary: {
    // color: '#ff3822',
    color: '#DC2F02',
    fontFamily: 'Helvetica Now Text',
    fontWeight: '600',
    fontSize: '1.6rem',
  },
  flexBoxImageSubText: {
    lineHeight: '1.5', letterSpacing: '0.1em', fontSize: '1rem', fontWeight: '400', fontFamily: 'Helvetica Now Text', marginTop: '0.7em'
  },
  flexBoxImageTitle: {
    lineHeight: '1.33', fontSize: '1.5rem', fontWeight: '500', fontFamily: 'Helvetica Now Text',
  },
  flexBoxImageSubTitle: {
    fontFamily: 'Helvetica Now Text', fontWeight: '400', fontSize: '.8125rem', lineHeight: '1', letterSpacing: '.24em', marginBottom: '10px', textTransform: 'uppercase',
  },
  flexSyllabusItemBox: {
    display: "flex",
    flexBasis: "300px",
    flexDirection: "column",
    boxSizing: 'border-box',
  },
  testDivider: {
    background: '-moz-linear-gradient(left,  #F1FAEE 0%, #2989d8 25%, #000000 75%, #F1FAEE 100%)',
    background: '-webkit-gradient(linear, left top, right top, color-stop(0%,#F1FAEE), color-stop(25%,#2989d8), color-stop(75%,#000000), color-stop(100%,#F1FAEE))', /* Chrome,Safari4+ */
    background: '-webkit-linear-gradient(left,  #F1FAEE 0%,#2989d8 25%,#000000 75%,#F1FAEE 100%)', /* Chrome10+,Safari5.1+ */
    background: '-o-linear-gradient(left,  #F1FAEE 0%,#2989d8 25%,#000000 75%,#F1FAEE 100%)', /* Opera 11.10+ */
    background: '-ms-linear-gradient(left,  #F1FAEE 0%,#2989d8 25%,#000000 75%,#F1FAEE 100%)', /* IE10+ */
    background: 'linear-gradient(left,  #F1FAEE 5%,#000000 25%, #000000 50%, #666666 75%,#F1FAEE 95%)',
  },
  mediaTest: {
    transition: "all ease 500ms",
    /* Custom, iPhone Retina */
    "@media only screen and (max-width : 479px)": {
      marginLeft: '5vw',
      width: '90vw',
    },

    /* Extra Small Devices, Phones */
    "@media only screen and (min-width : 480px)": {
      marginLeft: '5vw',
      width: '90vw',
    },

    /* Small Devices, Tablets */
    "@media only screen and (min-width : 768px)": {
      marginLeft: '15vw',
      width: '70vw',
    },

    /* Medium Devices, Desktops */
    "@media only screen and (min-width : 992px)": {
      marginLeft: '20vw',
      width: '60vw',
    },

    /* Large Devices, Wide Screens */
    "@media only screen and (min-width : 1200px)": {
      marginLeft: '25vw',
      width: '50vw',
    },
  },

  imageGallery: {
    transition: "all ease 500ms",
    maxHeight: '400px',

    /* Custom, iPhone Retina */
    "@media only screen and (max-width : 479px)": {
      marginLeft: '0',
      maxWidth: '100vw',
    },

    /* Extra Small Devices, Phones */
    "@media only screen and (min-width : 480px)": {
      marginLeft: '0',
      maxWidth: '100vw',
    },

    /* Small Devices, Tablets */
    "@media only screen and (min-width : 768px)": {
      marginLeft: '5vw',
      marginRight: '5vw',
      maxWidth: '90vw',
    },

    /* Medium Devices, Desktops */
    "@media only screen and (min-width : 992px)": {
      marginLeft: '10vw',
      marginRight: '10vw',
      maxWidth: '80vw',
    },

    /* Large Devices, Wide Screens */
    "@media only screen and (min-width : 1200px)": {
      marginLeft: '15vw',
      marginRight: '15vw',
      width: '100%',
      maxWidth: '70vw',
    },
  },
  kanji: {
   
    marginBottom: '-10rem',
    transition: "all ease 500ms",
    /* Custom, iPhone Retina */
    "@media only screen and (max-width : 479px)": {
      display: 'none',

    },

    /* Extra Small Devices, Phones */
    "@media only screen and (min-width : 480px)": {
      display: 'none',

    },

    /* Small Devices, Tablets */
    "@media only screen and (min-width : 768px)": {
      display: 'none',

    },

    /* Medium Devices, Desktops */
    "@media only screen and (min-width : 992px)": {
      display: 'inline',

      marginTop: '10rem',
      width: "180px",
      maxWidth: '25vw',

    },

    /* Large Devices, Wide Screens */
    "@media only screen and (min-width : 1200px)": {
      display: 'inline',
      marginTop: '5rem',
      width: "200px",
      maxWidth: '15vw',
    },
  }
}));



const IMAGES =
  [{
    src: './Image/HighRanks/standing.jpg',
    thumbnail: './Image/HighRanks/standing.jpg',
    thumbnailWidth: '320',
    thumbnailHeight: '180',
  },

  {
    src: './Image/HighRanks/blackbelt_bo.JPG',
    thumbnail: './Image/HighRanks/blackbelt_bo.JPG',
    thumbnailWidth: '320',
    thumbnailHeight: '180',
  },

  {
    src: './Image/Decent/5.jpg',
    thumbnail: './Image/Decent/5.jpg',
    thumbnailWidth: '320',
    thumbnailHeight: '180',
  },
  {
    src: './Image/Decent/6.jpg',
    thumbnail: './Image/Decent/6.jpg',
    thumbnailWidth: '320',
    thumbnailHeight: '180',
  },
  {
    src: '../Image/HighRanks/casual.jpg',
    thumbnail: './Image/HighRanks/casual.jpg',
    thumbnailWidth: '320',
    thumbnailHeight: '180',
  },
  {
    src: './Image/Decent/7.jpg',
    thumbnail: './Image/Decent/7.jpg',
    thumbnailWidth: '320',
    thumbnailHeight: '180',
  },
  {
    src: './Image/Decent/8.jpg',
    thumbnail: './Image/Decent/8.jpg',
    thumbnailWidth: '320',
    thumbnailHeight: '180',
  },
  {
    src: './Image/HighRanks/tonfa.jpg',
    thumbnail: './Image/HighRanks/tonfa.jpg',
    thumbnailWidth: '320',
    thumbnailHeight: '180',
  },
  {
    src: './Image/Decent/9.jpg',
    thumbnail: './Image/Decent/9.jpg',
    thumbnailWidth: '320',
    thumbnailHeight: '180',
  },
  {
    src: './Image/Decent/10.jpg',
    thumbnail: './Image/Decent/10.jpg',
    thumbnailWidth: '320',
    thumbnailHeight: '180',
  },]

export default function MainPage() {

  const classes = useStyles();

  return (
    <Box className={classes.root} style={{ fontFamily: 'Helvetica Now Text' }}>

      <Grid
        container
        direction="column"
      >



        <div className={classes.light}>


          <div style={{ backgroundImage: `url(/Image/headrepeat.jpg)`, backgroundRepeat: 'repeat-x', boxSizing: "border-box", borderBottom: '2px solid #ddd', maxWidth: '100vw', overflow: 'hidden' }}>
            <div style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              maxWidth: '1170px',
              marginBottom: '-5px',
            }}>
              <img style={{ marginLeft: '5vw' }} src="/Image/mainBanner.jpg" alt="/Image/mainBanner.jpg"></img>

              <div class="sitelogo" style={{ position: 'absolute', top: '84px', left: '6vw', lineHeight: 1.428571429, fontSize: '16px' }}>
                <Box display="flex">
                  <Box display="flex">
                    <img src="/Image/KRMA_NZ_WhiteNew.png" alt="Ki = mind/body/spirit" title="Ki = mind/body/spirit" style={{
                      float: 'left',
                      border: 0,
                      width: '420px'
                    }} />
                  </Box>

                </Box>
              </div>
            </div>
          </div>


          <div>


            <img className={classes.kanji} style={{ float: 'left', marginLeft: '2vw' }} src="/Image/KanjiTrans.png" position="relative" alt="Ki = mind/body/spirit" title="Ki = mind/body/spirit" />
            <img className={classes.kanji} style={{ float: 'right', marginRight: '2vw' }} src="/Image/KanjiTrans.png" position="relative" alt="Ki = mind/body/spirit" title="Ki = mind/body/spirit" />


            <div className={clsx(classes.mediaTest, classes.spaced)}>
              <Grid item>
                <Typography style={{ fontFamily: 'Helvetica Now Text', fontWeight: '900', lineHeight: '1.5', textAlign: 'center', fontSize: '1.5rem', fontStyle: 'normal' }}>
                  Kiaido Ryu Martial Arts focuses on teaching highly effective self defence for all ages and levels of physical ability.

                </Typography>
                <Typography style={{ fontFamily: 'Helvetica Now Text', fontWeight: '400', lineHeight: '1.5', fontSize: '1.2rem', fontStyle: 'normal' }}>
                  <br />Kiaido Ryu Martial Arts is an evolutionary martial art holistically encompassing elements of traditional Okinawan karate, kata and self-defence - (bunkai) stand up sparring - (K1 style), throwing, ground fighting, traditional weaponry - (kobudo) and internal arts - (Qi Gong) in a comprehensive syllabus.
                  <br /><br />Our art is taught in an environment of mutual respect and cooperation that builds confidence and self-esteem. We emphasize a supportive, positive, family atmosphere and combine self defence skills with the internal aspects of the arts to optimize your health and energy.
                </Typography>
              </Grid>

            </div>
          </div>



          <Box display="flex" justifyContent="space-around" className={classes.mediaTest} style={{ fontFamily: 'Helvetica Now Text', fontWeight: '400', fontSize: '1.2em', marginTop: '1.5rem', fontStyle: 'normal' }}>
            <Box display="flex" >
              <ul>
                <li className={classes.listSpacing}>
                  <Typography variant="h12" style={{ fontStyle: "italic" }}>
                    Self-defence, health, and fitness for the whole family.
                  </Typography>
                </li>
                <li className={classes.listSpacing}>
                  <Typography variant="h12" style={{ fontStyle: "italic" }}>
                    Train in a fun, safe, supportive environment.
                  </Typography>
                </li>
                <li className={classes.listSpacing}>
                  <Typography variant="h12" style={{ fontStyle: "italic" }}>
                    Professional, qualified instructors.
                  </Typography>
                </li>
                <li className={classes.listSpacing}>
                  <Typography variant="h12" style={{ fontStyle: "italic" }}>
                    Self-mastery, confidence, and self-discipline.
                  </Typography>
                </li>

              </ul>
            </Box>

            <Box display="flex">
              <ul>
                <li className={classes.listSpacing}>
                  <Typography variant="h12" style={{ fontStyle: "italic" }}>
                    Strip away old limitations, fears, and habits.
                  </Typography>
                </li>
                <li className={classes.listSpacing}>
                  <Typography variant="h12" style={{ fontStyle: "italic" }}>
                    Unlock your true potential.
                  </Typography>
                </li>
                <li className={classes.listSpacing}>
                  <Typography variant="h12" style={{ fontStyle: "italic" }}>
                    Multisport performance enhancement.
                  </Typography>
                </li>
                <li>
                  <Typography variant="h12" style={{ fontStyle: "italic" }}>
                    Inclusive, non-discriminant syllabus - anyone can train!
                  </Typography>
                </li>
              </ul>
            </Box>

          </Box>
        </div>

        <div style={{
          border: "1px solid #ddd",
        }}>
          <div className={classes.imageGallery} style={{}}>
            <Gallery images={IMAGES} direction={'row'} maxRows={2} backdropClosesModal={true} enableImageSelection={false} margin={12} style={{ display: 'inline' }} />
          </div>
        </div>


        <div className={classes.light} style={{paddingTop: '3rem'}}>
          <Box className={classes.mediaTest} style={{ paddingBottom: '2em', paddingTop: '2em' }}>
            <Typography align="center" style={{ fontFamily: 'Helvetica Now Text', fontSize: '2rem', fontWeight: '600', paddingBottom: '0.3em' }}> What is our Martial Arts Syllabus?</Typography>
            <Hidden xsDown>

              <div style={{ width: '500px', height: '4px', marginLeft: 'auto', marginRight: 'auto', marginBottom: '2rem', }} className={classes.testDivider} />
            </Hidden>
          </Box>

          <Box display="flex" flexWrap="wrap" style={{ lineHeight: '1.5', maxWidth: '100vw', padding: '0 2%', paddingBottom: '2rem', marginBottom: '2rem' }} justifyContent="space-evenly">

            <Box class={classes.flexSyllabusItemBox}>
              <Typography className={classes.flexBoxImageSubTitle}>Meditation</Typography>
              <img src="/Image/internalArts.jpg" alt="stuff" height="200px" ></img>
              <Box display="flex" flexDirection="column" justifyContent="center" boxSizing="border-box" paddingLeft="0.5rem" paddingRight="0.5rem">
                <Typography className={classes.flexBoxImageTitle}>Internal Arts</Typography>
                <Typography className={classes.flexBoxImageSubText}>The internal aspect of Kiaido Ryu Martial Arts teaches time-honored Pressure Points and Ki Energy techniques for health and well-being. This includes breathing, Centering, progressive relaxation, Meditation, and Qigong (energy boosting exercises). </Typography>
              </Box>
            </Box>

            <Hidden smUp>
              <div style={{ width: '100vw', height: '4px', marginLeft: 'auto', marginRight: 'auto', marginTop: '2rem', marginBottom: '2rem' }} className={classes.testDivider} />
            </Hidden>



            <Box class={classes.flexSyllabusItemBox}>
              <Typography className={classes.flexBoxImageSubTitle}>Kata and Bunkai</Typography>
              <img src="/Image/karate1.jpg" alt="stuff" height="200px"></img>
              <Box display="flex" flexDirection="column" justifyContent="center" boxSizing="border-box" paddingLeft="0.5rem" paddingRight="0.5rem">
                <Typography className={classes.flexBoxImageTitle}>Self Defence</Typography>
                <Typography className={classes.flexBoxImageSubText}>Our comprehensive syllabus gives you real skills and strategies that work regardless of your age or physical ability. The curriculum incorporates, striking, kicking, grappling, joint-locking, throws and takedowns, and groundfighting.</Typography>
              </Box>
            </Box>

            <Hidden smUp>
              <div style={{ width: '100vw', height: '4px', marginLeft: 'auto', marginRight: 'auto', marginTop: '2rem', marginBottom: '2rem' }} className={classes.testDivider} />
            </Hidden>

            <Box class={classes.flexSyllabusItemBox}>
              <Typography className={classes.flexBoxImageSubTitle}>Mind/Body/Spirit</Typography>
              <img src="/Image/selfMastery.jpg" alt="stuff" height="200px"></img>
              <Box display="flex" flexDirection="column" justifyContent="center" boxSizing="border-box" paddingLeft="0.5rem" paddingRight="0.5rem">
                <Typography className={classes.flexBoxImageTitle}>Warriors Wisdom</Typography>
                <Typography className={classes.flexBoxImageSubText}>The Kiaido Ryu Martial Arts Warriors Wisdom element of our art teaches Mind/Body/Spirit, Self Mastery principles for optimizing your personal growth and development. </Typography>
              </Box>
            </Box>
          </Box>
        </div>

      </Grid>


    </Box>
  );
}