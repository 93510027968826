import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import './styles.css';
import { client } from "./Client";
import BeltVideos from "./BeltVideos"
import AccountCircleIcon from '@material-ui/icons/AccountCircle';



const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        flexWrap: 'wrap',
        flexDirection: 'column',
        alignItems: 'center',
    },
    link: {
        margin: '0.2em',
        fontFamily: 'Helvetica Now Text',
        fontSize: '400',
        marginBottom: '40px',
    },
    headerText: {
        fontFamily: 'Helvetica Now Text',
        fontWeight: 'bold',
    },
    bodyText: {
        fontFamily: 'Helvetica Now Text',
        fontWeight: 'normal',
    },
    listElement: {
        marginBottom: '8px',
    },
    familyText: {
        maxWidth: '50vw',
    }

}));

const fetchBelts = async (belt) => {
    const response = await client.get(`belts/rank/${belt}`).catch((err) =>
        console.error("TAG", err)
    );

    if (response) {

        return response.id
    }
    else {


    }

};

const Mailto = ({ email, subject, body, children }) => {
    return (
        <a href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`}>{email}</a>
    );
};

export default function SummarySheets({ user, videos, stuff }) {
    const classes = useStyles();
    const [rankVideos, setRankVideos] = useState();
    useEffect(() => {
        if (user && videos) {
            if (user.rank.toLowerCase() === 'white') {
                setRankVideos(videos.filter(item => item.minRank === 1));
            }
            else if (user.rank.toLowerCase() === 'yellow') {
                setRankVideos(videos.filter(item => item.minRank === 2));
            }
            else if (user.rank.toLowerCase() === 'orange') {
                setRankVideos(videos.filter(item => item.minRank === 3));
            }
            else if (user.rank.toLowerCase() === 'blue') {
                setRankVideos(videos.filter(item => item.minRank === 4));
            }
            else if (user.rank.toLowerCase() === 'purple') {
                setRankVideos(videos.filter(item => item.minRank === 5));
            }
            else if (user.rank.toLowerCase() === 'green') {
                setRankVideos(videos.filter(item => item.minRank === 6));
            }
            else if (user.rank.toLowerCase() === 'brown') {
                setRankVideos(videos.filter(item => item.minRank === 7));
            }
            else if (user.rank.toLowerCase() === 'shodan') {
                setRankVideos(videos.filter(item => item.minRank === 8));
            }
            else if (user.rank.toLowerCase() === 'nidan') {
                setRankVideos(videos.filter(item => item.minRank === 9));
            }
            else if (user.rank.toLowerCase() === 'sandan') {
                setRankVideos(videos.filter(item => item.minRank === 10));
            }
            else if (user.rank.toLowerCase() === 'yondan') {
                setRankVideos(videos.filter(item => item.minRank === 11));
            }
            else if (user.rank.toLowerCase() === 'godan') {
                setRankVideos(videos.filter(item => item.minRank === 11));
            }
            else if (user.rank.toLowerCase() === 'rokudan') {
                setRankVideos(videos.filter(item => item.minRank === 11));
            }
            else if (user.rank.toLowerCase() === 'sichidan') {
                setRankVideos(videos.filter(item => item.minRank === 11));
            }
            else if (user.rank.toLowerCase() === 'hachidan') {
                setRankVideos(videos.filter(item => item.minRank === 11));
            }
            else if (user.rank.toLowerCase() === 'kudan') {
                setRankVideos(videos.filter(item => item.minRank === 11));
            }
            else if (user.ran.toLowerCase() === 'judan') {
                setRankVideos(videos.filter(item => item.minRank === 11));
            }
            else {
                setRankVideos(videos.filter(item => item.minRank === 1));
            }
        }
    }, [user, videos])


    return (
        <Box container className={classes.root}>
            <Typography className={classes.headerText}>{user ? user.multipleUser ? user.lastname + ' Family Account' : user.firstname + ' ' + user.lastname + ' (' + user.rank + ')' : ''} - {user ? user.school : 'No school found'} Dojo</Typography>
            <AccountCircleIcon style={{ fontSize: "240px" }}></AccountCircleIcon>
            <a href='Handbook.pdf' target='_blank' rel='noopener noreferrer' style={{ marginBottom: '2em' }}>
                <Typography className={classes.headerText}>Student Handbook Download</Typography></a>
            {user ? user.multipleUser ?
                <Typography className={classes.familyText} style={{ marginBottom: '2em' }}>
                    This account currently has multiple people associated with it, and will include videos up to the highest rank of your family account. If you would prefer for each person to have their own account, please feel free to request this at
                    <Mailto style={{ marginLeft: 'auto' }} email="martialartssup@gmail.com" subject="Family account inquiry"
                        body='Please let us know which family member you would like to have a seperate account for, and include an email address to be used with their account. If you have any questions about this, please feel free to let us know.' />
                    .</Typography> : [] : []}
            <Typography style={{ marginBottom: '4em', fontSize: '2rem' }} className={classes.headerText} >{user ? user.rank : 'No rank found'} Videos quick access</Typography>
            {user && rankVideos ? <BeltVideos belt={user.rank} videos={rankVideos} /> : []}

        </Box>
    )
}