import React, { useEffect, useRef  } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CardContent, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import './styles.css';
import Button from '@material-ui/core/Button';
import EmailIcon from '@material-ui/icons/Email';
import Gallery from 'react-grid-gallery';


const useStyles = makeStyles((theme) => ({
  root: {
    background: '#F1FAEE',
  },
  textTitle: {
    fontSize: '2rem',
    fontFamily: 'Helvetica Now Text',
    fontWeight: '900',
    color: '#ff3822'
  },
  boxContainer: {
    paddingTop: '2rem',
    marginLeft: '15%',
    paddingBottom: '2rem',
    width: '50%',
    "@media only screen and (max-width : 999px)": {
      width: '85%',
    },
  },
  textBody: {
    fontFamily: 'Helvetica Now Text',
    fontWeight: 'normal',
    marginTop: '1rem',
    fontSize: '1rem',
  },
  text: {
    fontFamily: 'Helvetica Now Text',
    fontWeight: 'normal',
    "@media only screen and (max-width : 660px)": {
      clear: 'both'
    },
  },
  boxBorder: {
    border: "3px solid black",
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingBottom: '1.5rem',
    marginTop: '2rem',
  },
  verticalHeadingText: {
    writingMode: 'vertical-rl',
    textOrientation: 'upright',
    fontSize: '2rem',
    fontFamily: 'Helvetica Now Text',
    fontWeight: '900',
    marginLeft: '1rem',
    color: '#ff3822',
  },
  imageBox: {
    display: "block",
    minHeight: "1px",
    paddingTop: "2%",
    width: '360px',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: "2%",
    border: "1px solid #ddd",
    overflow: "auto",
    "@media only screen and (max-width : 999px)": {
      display: 'none',
    },
  },
  centerText: {
    textAlign: "center"
  }
}));



const IMAGES =
  [{
    src: './Image/frontpage/1.jpg',
    thumbnail: './Image/frontpage/1.jpg',
    thumbnailWidth: 320,
    thumbnailHeight: 180,
    caption: ""
  },
  {
    src: './Image/frontpage/2.jpg',
    thumbnail: './Image/frontpage/2.jpg',
    thumbnailWidth: 320,
    thumbnailHeight: 180,
    caption: ""
  },
  {
    src: './Image/frontpage/3.jpg',
    thumbnail: './Image/frontpage/3.jpg',
    thumbnailWidth: 320,
    thumbnailHeight: 180,
    caption: ""
  },
  {
    src: './Image/frontpage/4.jpg',
    thumbnail: './Image/frontpage/4.jpg',
    thumbnailWidth: 320,
    thumbnailHeight: 180,
    caption: ""
  },
  {
    src: './Image/frontpage/5.jpg',
    thumbnail: './Image/frontpage/5.jpg',
    thumbnailWidth: 320,
    thumbnailHeight: 180,
    caption: ""
  },
  {
    src: './Image/frontpage/6.jpg',
    thumbnail: './Image/frontpage/6.jpg',
    thumbnailWidth: 320,
    thumbnailHeight: 180,
    caption: ""
  },
  {
    src: './Image/frontpage/7.jpg',
    thumbnail: './Image/frontpage/7.jpg',
    thumbnailWidth: 320,
    thumbnailHeight: 180,
    caption: ""
  },
  {
    src: './Image/frontpage/8.jpg',
    thumbnail: './Image/frontpage/8.jpg',
    thumbnailWidth: 320,
    thumbnailHeight: 180,
    caption: ""
  },
  {
    src: './Image/frontpage/9.jpg',
    thumbnail: './Image/frontpage/9.jpg',
    thumbnailWidth: 320,
    thumbnailHeight: 180,
    caption: ""
  },
  {
    src: './Image/frontpage/10.jpg',
    thumbnail: './Image/frontpage/10.jpg',
    thumbnailWidth: 320,
    thumbnailHeight: 180,
    caption: ""
  },
  {
    src: './Image/frontpage/11.jpg',
    thumbnail: './Image/frontpage/11.jpg',
    thumbnailWidth: 320,
    thumbnailHeight: 180,
    caption: ""
  },
]

export default function TaurangaDojo() {
  const classes = useStyles();
  const myDiv = useRef(null);
  const Mailto = ({ email, subject, body, children }) => {
    return (
      <Button startIcon={<EmailIcon />} color="primary" variant="contained" href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`}>Email Inquiry</Button>
    );
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // React.findDOMNode(myDiv)

  return (
    <div className={classes.root}>
      <Box display="flex" >
        <div className={classes.boxContainer} id="parent" ref={myDiv}>
          <div style={{ width: '80%', marginBottom: '2rem' }}>
            <Typography className={classes.textTitle} style={{ paddingTop: '2rem' }}>Bethlehem Martial Arts Dojo</Typography>
            <Typography className={classes.textBody} component="p">Founded circa 1986, Bethlehem Dojo has a rich history in the local community. Since its conception under Kyoshi Steve Bird, a high value relationship with the extended Tauranga community has allowed us to facilitate our programmes right in the heart of the our community, and thousands of locals have benefited from the KRMA training programmes taught here.</Typography>
            <Typography className={classes.textBody} component="p">Delivered in a supportive, positive, family friendly environment, Warriors Wisdom, Ki Gong and Mindfulness programmes provide harmonic balance to the physical and mental discipline of Karate Do, and the kick boxing, ground fighting, throwing, and kobudo (traditional weaponry) elements we teach.</Typography>
            <Typography className={classes.textBody} component="p">We encourage everyone to strive to ‘be the best they can be’ in all aspects of training, nurturing students along the Budo path to reaching their greatest human potential and higher life fulfilment.</Typography>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Card variant="outlined" style={{ marginTop: '2rem' }}>
                <CardContent>
                  <Typography>
                  <div style={{textAlign: 'center'}}>
                    <b >Juniors & Seniors Class</b>
                  </div>
                    <br/><b>Wednesday:</b> 6.30pm - 8.00pm
                    <br/>Bethlehem Hall, 239A State Highway 2, Bethlehem<br/>
                    <br/><b>Thursday:</b> 6.30pm - 8.00pm
                    <br/>Bethlehem Hall, 239A State Highway 2, Bethlehem
                  </Typography>
                  <Typography><br/><b>Contact:</b><br /> Paul Scott, 021 368 519</Typography>
                  <div style={{display: 'flex', alignItems: 'center', justifyContent:'center', marginTop: '24px'}}>
                  <Mailto style={{marginLeft: 'auto'}} email="paul@martialarts.co.nz" subject="Bethlehem Dojo Inquiry" body="Replace with your inquiry!">paul@martialarts.co.nz</Mailto>
                  </div>
                  </CardContent>
              </Card>
            </Box>
          </div>

          <div style={{maxWidth: '85%'}}>
            <Typography className={classes.textTitle}>Renshi Paul Scott</Typography>
            <Typography variant="body2" color="textSecondary" component="p" style={{ paddingBottom: '2rem' }}>
              4th Degree Black Belt (Yondan) KRMA Masters Board - Tauranga Dojo Instructor
            </Typography>
            <img style={{ float: 'left', paddingRight: '25px' }} alt="paul" src="./Image/paul.jpg"></img>
            <div style={{ display: "inline" }}>
              <Typography className={classes.text}>Having joined the Kiaido Ryu Martial Arts family in January 2001, Renshi Paul heads the Bethlehem Dojo instructor team and has been instrumental in the development of several schools in the Bay of Plenty region. He has a true passion for the development of human potential through the martial arts delivery system and having attained his fourth degree in 2017 Paul has an accomplished resume as a martial artist.</Typography>
              <Typography className={classes.text}><br />Endeavouring to continue his own personal growth through research and attending seminars and training camps, he has also been extensively involved in the fitness industry, is a Netfit qualified fitness instructor, KRMA qualified programme instructor and has held international certifications as a Les Mills group fitness instructor.</Typography>
            </div>
          </div>
        </div>

        <Box className={classes.imageBox} >
         <div style={{width: '320px', margin: '0 auto'}}>
          <Gallery backdropClosesModal={true} images={IMAGES} enableImageSelection={false} margin={12} />
          </div>
        </Box>

      </Box>
    </div>
  );

}