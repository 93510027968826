import React, { forwardRef,} from 'react';
import Founder from './Founder.js';
import Means from './Means.js';
import Affiliation from './Affiliation.js';
import MainPage from './MainPage.js';
import ContactNew from './ContactNew.js';
import SignIn from './SignIn.js';
import Profile from './Profile';
import OtumoetaiDojo from './DojoOtumoetai';
import ThamesDojo from './DojoThames';
import HamiltonDojo from './DojoHamilton';
import KatikatiDojo from './DojoKatikati';
import AucklandBombayDojo from './DojoAucklandBombay';
import BayOfIslandsDojo from './DojoBayOfIslands';
import TaupoDojo from './DojoTaupo';
import MountDojo from './DojoMount'
import PutaruruDojo from './DojoPutaruru';
import AucklandRemueraDojo from './DojoAucklandRemuera';
import ScrollToTop from './ScrollToTop';
import PasswordRecover from './PasswordRecover';
import Privacy from './Privacy';
import PapamoaDojo from './DojoPapamoa';
import Copyright from './Copyright';
import Rollcall from './Rollcall';
import Attendance from './Attendance';
import NewToolbarDrawer from './ToolbarDrawer.js';
import { SnackbarProvider } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core/';
import { BrowserRouter as Router, Switch, Route, withRouter, Link, useHistory } from "react-router-dom";
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import "./styles.css";
import 'fontsource-roboto';

const drawerWidth = '25%';
const leftSpace = '25%';


const useStyles = makeStyles((theme) => ({
  footer: {
    background: '#1D3557',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    minHeight: '100%',
  },
  text: {
    paddingTop: '10px',
    paddingBottom: '10px',
    color: 'white',
  },
  textLink: {
    textDecoration: 'underline', 
    marginLeft: '2em',
    paddingTop: '10px',
    paddingBottom: '10px',
    color: 'white',
    cursor: 'pointer',
  },
  fullHeight: {
    height: '100vh',
  },
  content: {
    width: `calc(100% - ${drawerWidth} - ${leftSpace})`,
    paddingRight: drawerWidth,
    marginLeft: '20%',
    display: 'flex',
    flexWrap: 'wrap',
  },
  background: {
    background: '#F2F2F1'
  }
}));

const Home = () => <MainPage />;
const Found = () => <Founder />;
const Mean = () => <Means />;
const Affil = () => <Affiliation />;


const AnimatedSwitchNew = withRouter(({ location }) => (
  <TransitionGroup>
    <CSSTransition key={location.key} classNames="fade" timeout={{ enter: 300, exit: 300 }}>
      <section className="route-selection">
      <ScrollToTop>
      <Switch location={location}>
          <Route path="/" component={Home} exact/>
          <Route path="/means" component={Mean} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/copyright" component={Copyright} />
          <Route path="/affiliation" component={Affil} />
          <Route path="/founder" component={Found} />
          <Route path="/signin" component={SignIn} />
          <Route path="/recoverpassword/:hash" component={PasswordRecover} />
          <Route path="/recoverpassword/" component={PasswordRecover} />
          <Route path="/profile" component={Profile} />
          <Route path="/rollcall" component={Rollcall} />
          <Route path="/attendance" component={Attendance} />
          <Route path="/contact" component={ContactNew} />
          <Route path="/bethlehem" component={OtumoetaiDojo} />
          <Route path="/taupo" component={TaupoDojo} />
          <Route path="/thames" component={ThamesDojo} />
          <Route path="/katikati" component={KatikatiDojo} />
          <Route path="/hamilton" component={HamiltonDojo} />
          <Route path="/mount" component={MountDojo} />
          <Route path="/papamoa" component={PapamoaDojo} />
          <Route path="/putaruru" component={PutaruruDojo} />
          <Route path="/bayofislands" component={BayOfIslandsDojo} />
          <Route path="/auckland-bombay" component={AucklandBombayDojo} />
          <Route path="/auckland-remuera" component={AucklandRemueraDojo} />
          <Route component={Home}/>
        </Switch>
        </ScrollToTop> 
      </section>
    </CSSTransition>
  </TransitionGroup>
));



function App() {
  const classes = useStyles();
  const history = useHistory();
  const MySwitch = () => {
    return (
      <AnimatedSwitchNew />

    )
  }

  const handlePrivacy = () => {
    history.push('/privacy');
  }

  const MySwitch2 = forwardRef((props, ref) => (
    <AnimatedSwitchNew ref={ref}>
    {props.children}
    </AnimatedSwitchNew>
  ));

  const MyToolbar = () => {
    const ref = React.createRef();
    return (
      <NewToolbarDrawer content={
        <MySwitch/>

    }>


    </NewToolbarDrawer>

    )
  }

  const PrivacyButton = () => (
    <Route render={({ history}) => (
      <div className={classes.textLink}>
        <Typography variant="h7" component="h7"  onClick={() => { history.push('/privacy') }}>
          Privacy Policy
        </Typography>

        <Typography variant="h7" component="h7" className={classes.textLink} onClick={() => { history.push('/copyright') }}>
          Copyright Notice
        </Typography>
      </div>
    
    )} />)

  return (
    <SnackbarProvider maxSnack={3}>
      <Router>
        <div className={classes.background}>
          <MyToolbar></MyToolbar>
          <div className={classes.footer}>
            <Typography variant="h7" component="h7" className={classes.text}>

              © Kiaido Ryu Martial Arts. | Website by Kane Scott.

            </Typography>
            <PrivacyButton/>
          </div>
        </div>
      </Router>
    </SnackbarProvider>
  );
}

export default App;
