import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CardContent, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import './styles.css';
import Button from '@material-ui/core/Button';
import EmailIcon from '@material-ui/icons/Email';
import Gallery from 'react-grid-gallery';


const useStyles = makeStyles((theme) => ({
  root: {
    background: '#F1FAEE',
  },
  textTitle: {
    fontSize: '2rem',
    fontFamily: 'Helvetica Now Text',
    fontWeight: '900',
    color: '#ff3822'
  },
  boxContainer: {
    paddingTop: '2rem',
    marginLeft: '15%',
    paddingBottom: '2rem',
    width: '50%',
    "@media only screen and (max-width : 999px)": {
      width: '85%',
    },
  },
  textBody: {
    fontFamily: 'Helvetica Now Text',
    fontWeight: 'normal',
    marginTop: '1rem',
    fontSize: '1rem',
  },
  text: {
    fontFamily: 'Helvetica Now Text',
    fontWeight: 'normal',
  },
  boxBorder: {
    border: "3px solid black",
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingBottom: '1.5rem',
    marginTop: '2rem',
  },
  verticalHeadingText: {
    writingMode: 'vertical-rl',
    textOrientation: 'upright',
    fontSize: '2rem',
    fontFamily: 'Helvetica Now Text',
    fontWeight: '900',
    marginLeft: '1rem',
    color: '#ff3822',
  },
  imageBox: {
    alignItems: 'center', justifyContent:'center',
    display: "block",
    minHeight: "1px",
    paddingTop: "2%",
    width: '400px',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: "2%",
    border: "1px solid #ddd",
    overflow: "auto",
    maxHeight: '100%',
    "@media only screen and (max-width : 999px)": {
      display: 'none',
    },
  },
}));

const IMAGES =
  [
  {
    src: './Image/Thames/2.jpg',
    thumbnail: './Image/Thames/2.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180px',
  },
  {
    src: './Image/Thames/4.jpg',
    thumbnail: './Image/Thames/4.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180px',
  },
  {
    src: './Image/Thames/5.jpg',
    thumbnail: './Image/Thames/5.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180px',
  },
  {
    src: './Image/Thames/7.jpg',
    thumbnail: './Image/Thames/7.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180px',
  },
  {
    src: './Image/Thames/8.jpg',
    thumbnail: './Image/Thames/8.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180px',
  },
  {
    src: './Image/Thames/9.jpg',
    thumbnail: './Image/Thames/9.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180px',
  },
  {
    src: './Image/Thames/10.jpg',
    thumbnail: './Image/Thames/10.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180px',
  },
  {
    src: './Image/Thames/11.jpg',
    thumbnail: './Image/Thames/11.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180px',
  },
  {
    src: './Image/Thames/12.jpg',
    thumbnail: './Image/Thames/12.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180px',
  },
  {
    src: './Image/Thames/13.jpg',
    thumbnail: './Image/Thames/13.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180px',
  },
  {
    src: './Image/Thames/14.jpg',
    thumbnail: './Image/Thames/14.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180px',
  },
  {
    src: './Image/Thames/15.jpg',
    thumbnail: './Image/Thames/15.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180px',
  },
]

export default function ThamesDojo() {
  const classes = useStyles();
  const Mailto = ({ email, subject, body, children }) => {
    return (
      <Button startIcon={<EmailIcon />} color="primary" variant="contained" href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`}>Email Inquiry</Button>
    );
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.root}>
      <Box display="flex">
        <div className={classes.boxContainer}>
          <div style={{ maxWidth: '85%', marginBottom: '2rem' }}>
            <Typography className={classes.textTitle} style={{ paddingTop: '2rem' }}>Thames Dojo Information</Typography>
            <Typography className={classes.textBody} component="p">Our first Thames Karate classes were established by Hanshi Lance Strong and Kyoshi Ava Strong in 1983. In 1990 these classes became Kiaido Ryu Martial Arts. Over many years all the remaining KRMA schools evolved from the Thames base as many of the original senior Instructors moved to establish Dojo in other centres.
            To support these schools Hanshi and Kyoshi, travelled with a car-load of senior ranks each week, for over a twenty years, to support and teach these classes four nights a week and on many weekends.
            <br/><br/>Thames Dojo currently has over 90 students training and is looking forward to a future where KRMA can make a major difference in continuing to help others to gain the confidence to lead their very best lives. 
            </Typography>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Card variant="outlined" style={{ marginTop: '2rem' }}>
                <CardContent>
                <Typography><b>Juniors (8-11 years)</b><br/>
                Tuesday and Thursday - 5.30pm - 6.30pm</Typography>
                <Typography><b>Seniors</b><br/>
                Tuesday and Thursday - 6.30pm - 8.00pm</Typography>
                <Typography>Contact: Lance & Ava Strong, 021 502333</Typography>
                <Typography><b>Location</b> <br/> Thames High School, Thames, New Zealand </Typography>
                  <div style={{display: 'flex', alignItems: 'center', justifyContent:'center', marginTop: '24px'}}>
                  <Mailto email="lance@martialarts.co.nz" subject="Thames Dojo Inquiry" body="Replace with your inquiry!"></Mailto>
                  </div>
                  </CardContent>
              </Card>
            </Box>
          </div>

          <div style={{maxWidth: '85%',}}>
            <Typography className={classes.textTitle}>Grandmaster Lance Strong</Typography>
            <Typography variant="body2" color="textSecondary" component="p" style={{ paddingBottom: '2rem' }}>
            9th Degree Black Belt (Hanshi) KRMA Masters Board - Thames Dojo Instructor
            </Typography>
            <img style={{ float: 'left', paddingRight: '25px', maxWidth: '320px' }} alt="Hanshi Lance Strong" src="./Image/Thames/thamesInstructor.jpg"></img>
            <div >
              <Typography className={classes.text}>KRMA was founded by Grandmaster, 9th Degree Black Belt, Lance Strong (MNZM). 
              He’s been involved in martial arts for 60 years and an instructor for 38 years.<br/><br/> Lance has always had a passion for the development of human potential, helping people live their very best lives. He combines modern motivational training with the philosophy and teachings of the martial arts, to make this knowledge readily available through his "Warriors Wisdom" course; based on his book "Warriors Wisdom
              Lance has done stunt work and weapons choreography for TV shows Hercules and Xena.<br/><br/>
              He also has an extensive background in Education and Training, working as a Training Manager for Toyota New Zealand for many years. He also spent several years in the USA working as a Senior VP of Operations, while continuing his extensive martial arts studies.</Typography>
            </div>
          </div>
         
          <div style={{maxWidth: '85%', clear: 'both', paddingTop: '4em'}}>
          <span
          style={{display:'inline-block', verticalAlign: 'middle', margin: '29px 0 26px', borderBottom: '1px solid #cecece', width: '100%'}}></span>
          <Typography className={classes.textTitle}>Grandmaster Ava Strong</Typography>
          <Typography variant="body2" color="textSecondary" component="p" style={{ paddingBottom: '2rem' }}>
          8th Degree Black Belt (Kyoshi) KRMA Masters Board - Thames Dojo Instructor
          </Typography>
          <img style={{ float: 'left', paddingRight: '25px', maxWidth: '320px' }} alt="Hanshi Lance Strong" src="./Image/Ava.jpg"></img>
          <div>
            <Typography className={classes.text}>As one of the founding members of Kiaido Ryu Ava is the highest ranked woman in KRMA and has been studying the Martial Arts for over 38 years. In July 2019 Ava received her 8th Degree Black Belt sanctioned by Grandmaster Leo Fong 10th Degree Black Belt USA, and the KRMA Masters Board. 
            <br/><br/>Ava received one of the highest recognitions for her work in the 2019 NZ Honours by being awarded a Member of the New Zealand Order of Merit for her contribution to martial arts and the community. 
            <br/><br/>Ava has trained extensively in the USA and Canada in Karate, Kyusho Jitsu (6 th Degree), Wei Kuen Do (8 th Degree), Jiu Jitsu and Arnis under some of the world’s leading martial arts masters.</Typography>
          </div>
        </div>

        </div>

       
        <Box className={classes.imageBox}>
          <Gallery images={IMAGES} enableImageSelection={false} margin={12}/>
        </Box>

      </Box>
    </div>
  );

}