import React, { useEffect, useState } from 'react';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import './styles.css';
import { client } from "./Client";
import Moment from 'react-moment';
import { Box, Button, IconButton, TextField, Typography } from '@material-ui/core';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

const useStyles = makeStyles((theme) => ({
    button : {
        maxWidth: '50%',
        marginTop: '5em',
    },
    circleIcon : {
        background: 'yellow',
        padding: '10px',
        borderRadius: '50%',
        width: '50px',
        height: '50px',
        marginRight: '12px',
        textAlign: 'center',
        lineHeight: '50px',
        verticalAlign: 'middle',
    }

}))

export default function MailView({message, removeMail}) {
    const classes = useStyles();

    return (
        <Box display="flex" flexDirection="column">
            <Button color="primary" variant="contained" style={{ width: '60px', minWidth: "10%", marginBottom: '24px' }} onClick={() => removeMail()}>Back</Button>
            <Box style={{ marginLeft: '24px' }}>
                <Typography style={{ fontWeight: 600, fontSize: 'large' }}>{message.title}</Typography>
                <Typography style={{ fontSize: 'small', paddingBottom: '48px' }}>from <b>{message.author}</b> on <Moment date={message.createdAt} format="MMM DD, YYYY, h:mm:ss A" /></Typography>
                <Typography style={{ width: '75%', maxWidth: '750px', whiteSpace: 'pre-line', fontSize: 'medium' }}>{message.text}</Typography>
            </Box>
        </Box>
    )
}