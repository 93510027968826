import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CardContent, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import './styles.css';
import Button from '@material-ui/core/Button';
import EmailIcon from '@material-ui/icons/Email';
import Gallery from 'react-grid-gallery';


const useStyles = makeStyles((theme) => ({
  root: {
    background: '#F1FAEE',
  },
  textTitle: {
    fontSize: '2rem',
    fontFamily: 'Helvetica Now Text',
    fontWeight: '900',
    color: '#ff3822'
  },
  boxContainer: {
    paddingTop: '2rem',
    marginLeft: '15%',
    paddingBottom: '2rem',
    width: '50%',
    "@media only screen and (max-width : 999px)": {
      width: '85%',
    },
  },
  textBody: {
    fontFamily: 'Helvetica Now Text',
    fontWeight: 'normal',
    marginTop: '1rem',
    fontSize: '1rem',
  },
  text: {
    fontFamily: 'Helvetica Now Text',
    fontWeight: 'normal',
    "@media only screen and (max-width : 999px)": {
      clear: 'both'
    },
  },
  boxBorder: {
    border: "3px solid black",
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingBottom: '1.5rem',
    marginTop: '2rem',
  },
  verticalHeadingText: {
    writingMode: 'vertical-rl',
    textOrientation: 'upright',
    fontSize: '2rem',
    fontFamily: 'Helvetica Now Text',
    fontWeight: '900',
    marginLeft: '1rem',
    color: '#ff3822',
  },
  imageBox: {
    display: "block",
    minHeight: "1px",
    paddingTop: "2%",
    width: '400px',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: "2%",
    border: "1px solid #ddd",
    overflow: "auto",
    "@media only screen and (max-width : 999px)": {
      display: 'none',
    },
  },
}));

const IMAGES =
  [
  {
    src: './Image/Thames/4.jpg',
    thumbnail: './Image/Thames/4.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180px',
  },
  {
    src: './Image/Thames/5.jpg',
    thumbnail: './Image/Thames/5.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180px',
  },
  {
    src: './Image/Thames/7.jpg',
    thumbnail: './Image/Thames/7.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180px',
  },
  {
    src: './Image/Thames/8.jpg',
    thumbnail: './Image/Thames/8.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180px',
  },
  {
    src: './Image/Thames/9.jpg',
    thumbnail: './Image/Thames/9.jpg',
    thumbnailWidth: '320px',
    thumbnailHeight: '180px',
  },
  
]

export default function BombayDojo() {
  const classes = useStyles();
  const Mailto = ({ email, subject, body, children }) => {
    return (
      <Button startIcon={<EmailIcon />} color="primary" variant="contained" href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`}>Email Inquiry</Button>
    );
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.root}>
      <Box display="flex">
        <div className={classes.boxContainer}>
          <div style={{ width: '80%', marginBottom: '2rem' }}>
            <Typography className={classes.textTitle} style={{ paddingTop: '2rem' }}>Bombay Dojo Information</Typography>
            <Typography className={classes.textBody} component="p">Our dojo places emphasis on not judging people; if you are willing to learn, we are willing to teach.  As instructors, we believe that anybody can be taught something that will improve their life.  Our students notice their self-confidence, stamina and focus improve which is a privilege for us to witness.  Always encouraging our students to progress through the ranks, our dojo offers a safe and fun environment along with a friendly, family vibe.</Typography>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Card variant="outlined" style={{ marginTop: '2rem', maxWidth: '75%' }}>
                <CardContent>
                  <Typography ><b>Juniors Class:</b><br />Monday & Wednesday: Juniors (7-14yrs) 5.45pm – 6.45pm</Typography>
                  <Typography><b>Seniors Class:</b><br />Monday & Wednesday: Seniors (14+) 5.45pm – 7.15pm</Typography>
                  <Typography><b>Location:</b><br /> 35 Paparata Road, Bombay School Hall, Bombay (Enter through Barber Road, parking available)</Typography>
                  <Typography><b>Contact:</b><br /> Steve and Huihana Kellas, 021 116 4643</Typography>
                  <div style={{display: 'flex', alignItems: 'center', justifyContent:'center', marginTop: '24px'}}><Mailto email="whanau@outlook.com" subject="Bombay Dojo Inquiry" body="Replace with your inquiry!">kwhanau@outlook.com</Mailto></div>
                </CardContent>
              </Card>
            </Box>
          </div>

          <div style={{maxWidth: '85%'}}>
            <Typography className={classes.textTitle}>Dai Sempai Steve Kellas and Sempai Huihana Kellas</Typography>
            <Typography variant="body2" color="textSecondary" component="p" style={{ paddingBottom: '2rem' }}>
              2nd Degree Black Belt (Nidan) and 1st Degree Black Belt (Shodan) - Bombay Dojo Instructors
            </Typography>
            <img style={{ float: 'left', paddingRight: '25px', maxWidth: '240px' }} alt="Dai Sempai Steve Kellas" src="./Image/Bombay/bombayInstructor.jpg"></img>
            <div style={{ display: "inline" }}>
              <Typography className={classes.text}>Steve Kellas first joined KRMA way back in the 80’s when it was known as Zen Do Kai.  During his time, he bore witness to the ever evolving and growing practice of which is now called Kiaido Ryu Martial Arts.  Recently taking the lead in his own dojo and particularly fond of sparring, Steve brings a wealth of knowledge along with his own personal style of teaching.</Typography>
              <Typography className={classes.text}><br/>Huihana first joined in 1998 and trained under Dai Sempai Steve Kellas.  She also had the opportunity to train under the highest ranks within Kiaido Ryu eventually leading to her Black Belt.  Recipient of the Budo Junior Female award, Huihana has a passion for weapons, especially the Bo.</Typography>

            </div>
          </div>

        </div>

        

        <Box className={classes.imageBox}>
          <Gallery backdropClosesModal={true} images={IMAGES} enableImageSelection={false} margin={12} />
        </Box>

      </Box>
    </div>
  );

}