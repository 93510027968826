import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import './styles.css';
import {Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({

    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    innerAccordion :{
        maxWidth: '25vw', 
        boxShadow: 'none', 
    },
    outerAccordion :{
        maxWidth: '25vw', 
        
    },
    summaryBorder: {
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
    },
    headerText: {
        fontWeight: 'bold',
        fontFamily: 'Helvetica Now Text',
        fontSize: '1.2rem',
    },
    bodyText: {
        fontFamily: 'Helvetica Now Text',
        fontSize: '400',
    },
    content: {
        height: '100%',
        /* Custom, iPhone Retina */
    "@media only screen and (max-width : 479px)": {
        marginLeft: '5vw', 
        maxWidth: '90vw',
      },
  
      /* Extra Small Devices, Phones */
      "@media only screen and (min-width : 480px)": {
        marginLeft: '10vw', 
        maxWidth: '80vw',
      },
  
      /* Small Devices, Tablets */
      "@media only screen and (min-width : 768px)": {
        marginLeft: '15vw', 
        maxWidth: '70vw',
      },
  
      /* Medium Devices, Desktops */
      "@media only screen and (min-width : 992px)": {
        marginLeft: '15vw', 
        maxWidth: '60vw',
      },
  
      /* Large Devices, Wide Screens */
      "@media only screen and (min-width : 1200px)": {
        marginLeft: '15vw', 
        maxWidth: '50vw',
      },
    }

}));

export default function Copyright() {
    const classes = useStyles();

    return (
        <div className={classes.content}>
            <Typography className={classes.headerText} style={{ marginTop: '2em', fontSize: '2rem' }}>
                Copyright Notice<br /><br />
            </Typography>

            <Typography className={classes.bodyText} style={{ marginBottom: '4em' }}>
            All files and information contained in this Website are copyright by Kane Scott (Kiaido Ryu Martial Arts), and may not be duplicated, copied, modified or adapted, in any way without our written permission. Our Website may contain our service marks or trademarks as well as those of our affiliates or other companies, in the form of words, graphics, and logos.

            <br /><br />Your use of our Website or Services does not constitute any right or license for you to use our service marks or trademarks, without the prior written permission of Kane Scott (Kiaido Ryu Martial Arts).
            
            <br /><br />Our Content, as found within our Website and Services, is protected under New Zealand and foreign copyrights. The copying, redistribution, use or publication by you of any such Content, is strictly prohibited. Your use of our Website and Services does not grant you any ownership rights to our Content.
        
            </Typography>
            
        </div>
    );

}