import React, { Fragment } from 'react';
import { Typography, Grid, Box, Paper } from '@material-ui/core/';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import './styles.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import clsx from 'clsx'

const theme = createMuiTheme();

theme.typography.body1 = {
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.2rem',
    fontFamily: 'Helvetica Now Text',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.2rem',
    fontFamily: 'Helvetica Now Text',
  },
};

const useStyles = makeStyles((theme) => ({
  topText: {
    display: "flex",
    flexWrap: 'wrap',
    width: '100%',
    paddingTop: '2em',
    paddingBottom: '2em',
    boxShadow: '0px 5px 5px 0px rgba(0,0,0,0.75)',
    background: '#dadad9',
    color: 'red',
    zIndex: "3",
  },
  darkerLight: {
    background: '#dadad9',
    borderBottom: '2px solid #A9A9A9',
    boxShadow: 'inset 0px 4px 8px #A9A9A9',
    zIndex: 1,
    width: '100%',
    paddingTop: '2rem',
    paddingBottom: '2rem',


  },
  root: {
    display: "flex",
    flexWrap: 'wrap',
    //boxShadow: 'inset 15px 0px 15px -10px rgba(0,0,0,0.75), inset -15px 0px 15px -10px rgba(0,0,0,0.75)', 
    //boxShadow: ' -5px 0px 10px 0px rgba(0,0,0,0.75), 5px 0px 10px 0px rgba(0,0,0,0.75)',
    paddingLeft: '5vw',
    paddingRight: '5vw',
    zIndex: "-1",
    "@media only screen and (max-width : 700px)": {
      marginLeft: '5vw',
      maxWidth: '90vw',
    },

    /* Extra Small Devices, Phones */
    "@media only screen and (min-width : 1000px)": {
      marginLeft: '5vw',
      maxWidth: '90vw',
    },

    /* Small Devices, Tablets */
    "@media only screen and (min-width : 1300px)": {
      marginLeft: '10vw',
      maxWidth: '80vw',
    },

    /* Medium Devices, Desktops */
    "@media only screen and (min-width : 1600px)": {
      marginLeft: '15vw',
      maxWidth: '70vw',
    },

    /* Large Devices, Wide Screens */
    "@media only screen and (min-width : 1900px)": {
      marginLeft: '20vw',
      maxWidth: '60vw',
    },
  },
  headerText: {
    fontWeight: 'bold',
    fontFamily: 'Helvetica Now Text',
    fontSize: '2rem',
  },
  bodyText: {
    fontFamily: 'Helvetica Now Text',
    fontSize: '400',
  },
  lightBackground: {
    background: '#F1FAEE',
  },
  darkBackground: {
    background: '#dadad9',
  },
  paperList: {
    maxWidth: '45%',
    paddingRight: '40px',
    paddingTop: '16px',
    paddingBottom: '16px',
    marginBottom: '2rem',
    border: 'solid 1px lightgrey',
    color: 'black',
    "@media only screen and (max-width : 1000px)": {
      width: '100%',
      maxWidth: '100%'
    },
  },
  reactiveFlex: {
    flexDirection: 'row',
    "@media only screen and (max-width : 1000px)": {
      flexDirection: 'column',
    },
  },
  topCenter: {
    transition: "all ease 500ms",
    /* Custom, iPhone Retina */
    "@media only screen and (max-width : 700px)": {
      marginLeft: '5%',
      marginRight: '8%',
    },

    /* Extra Small Devices, Phones */
    "@media only screen and (min-width : 1000px)": {
      marginLeft: '10%',
      marginRight: '10%',
    },

    /* Small Devices, Tablets */
    "@media only screen and (min-width : 1300px)": {
      marginLeft: '15%',
      marginRight: '15%',
    },

    /* Medium Devices, Desktops */
    "@media only screen and (min-width : 1600px)": {
      marginLeft: '20%',
      marginRight: '20%',
    },

    /* Large Devices, Wide Screens */
    "@media only screen and (min-width : 1900px)": {
      marginLeft: '25%',
      marginRight: '25%',
    },
  },

  divider: {
    /* Custom, iPhone Retina */
    "@media only screen and (max-width : 700px)": {
      width: '75%'
    },

    /* Extra Small Devices, Phones */
    "@media only screen and (min-width : 1000px)": {
      width: '65%'
    },

    /* Small Devices, Tablets */
    "@media only screen and (min-width : 1300px)": {
      width: '55%'
    },

    /* Medium Devices, Desktops */
    "@media only screen and (min-width : 1600px)": {
      width: '45%'
    },

    /* Large Devices, Wide Screens */
    "@media only screen and (min-width : 1900px)": {
      width: '35%'
    },
  },
}));

export default function Affiliation() {
  const classes = useStyles();
  const openMail = () => {
    window.open('mailto:email@example.com?subject=Subject&body=Body%20goes%20here')
  }

  const Mailto = ({ email, subject, body, children }) => {
    return (
      <a href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`}>{children}</a>
    );
  };

  return (
    <div>
      <ThemeProvider theme={theme}>

        <Box position='relative' container >
          <Box container className={classes.root} style={{ paddingTop: '48px' }}>
            <Typography className={classes.headerText} style={{ fontWeight: '900', color: '#ff3822' }} variant="h4">Affiliation to the Kiaido Ryu Martial Arts International</Typography>

            <Typography className={classes.bodyText}><br />If you would like to know more about how you, or your school, can become a part of Kiaido Ryu Martial Arts you can contact us at  <Mailto email="lance@martialarts.co.nz" subject="Kiaido Ryu Affiliation" body="">lance@martialarts.co.nz</Mailto> or call 07 869 0180 or 021 502 333, New Zealand.</Typography>
            <Typography className={classes.bodyText}><br />A frustration for many high ranking martial artists is the lack of advanced information, classes, and training knowledge available to enable them to continue their studies and growth to higher levels after Black Belt.</Typography>
            <Typography className={classes.bodyText}><br />We provide regular high level study opportunities for all senior ranks.
              These resources cover Basic, Advanced, and Masters level instruction in all aspects of our comprehensive syllabus.
              This includes keys to understanding and applying Kata Bunkai for real self-defence.
              These keys and principles can be adapted and applied to any martial art.
            </Typography>
          </Box>
        </Box>

        <div style={{ marginTop: '4em', marginBottom: '2em' }}>
          <span className={classes.divider}
            style={{ display: 'block', margin: '29px auto 26px', borderBottom: '2px solid #cecece' }}></span>
        </div>

        <Box container className={classes.root}>
          <Typography className={classes.headerText} style={{ marginBottom: '1em', fontWeight: '900', color: '#ff3822' }}><br />The benefits of being an Instructor in KRMA include:</Typography>
          <Box container display="flex" className={classes.reactiveFlex}>
            <Paper elevation={4} className={classes.paperList} style={{ marginRight: '5%' }}>
              <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ height: '100%' }}>
               
                  <Box display="flex" style={{ marginLeft: '40px' }}>
                    <Typography className={classes.bodyText }>•</Typography>
                    <Typography className={classes.bodyText} style={{marginLeft: '.5em'}}>A most comprehensive, progressive, and leading-edge written Syllabus and Video resources for all elements of the Syllabus - Basic and Advanced</Typography>
                  </Box>
                  <Box display="flex" style={{ marginLeft: '40px' }}>
                    <Typography className={classes.bodyText }>•</Typography>
                    <Typography className={classes.bodyText} style={{marginLeft: '.5em'}}>A support structure that assists you in being a skilled martial arts professional</Typography>
                  </Box>
                  <Box display="flex" style={{ marginLeft: '40px' }}>
                    <Typography className={classes.bodyText }>•</Typography>
                    <Typography className={classes.bodyText} style={{marginLeft: '.5em'}}>Comprehensive Instructor and Student support services and resources</Typography>
                  </Box>
                  <Box display="flex" style={{ marginLeft: '40px' }}>
                    <Typography className={classes.bodyText }>•</Typography>
                    <Typography className={classes.bodyText} style={{marginLeft: '.5em'}}>Regular Masters Classes, Camps, and Seminars covering all core elements KRMA</Typography>
                  </Box>
              </Box>
            </Paper>
            <Paper className={classes.paperList} elevation={4} >

              <Box display="flex" flexDirection="column" justifyContent="space-between"  >
                <Box display="flex" style={{ marginLeft: '40px', marginBottom: '1.2em', }}>
                  <Typography className={classes.bodyText}>•</Typography>
                  <Typography className={classes.bodyText} style={{marginLeft: '.5em'}}>Internationally recognized Rank and Instructor Certification and affiliation to a worldwide network of martial arts knowledge and research</Typography>
                </Box>
                <Box display="flex" style={{ marginLeft: '40px', marginBottom: '1.2em', }}>
                  <Typography className={classes.bodyText}>•</Typography>
                  <Typography className={classes.bodyText} style={{marginLeft: '.5em'}}>Advanced training in key areas including: Kata and Bunkai, Kyusho Jitsu, Jiu Jitsu (Small Circle/BJJ), MMA, Boxing/Kickboxing, Arnis, Weapons, Wei Keun Do (Angles of Attack/Trapping Hands), and Self Defence.</Typography>
                </Box>
                <Box display="flex" style={{ marginLeft: '40px', marginBottom: '1.2em', }}>
                  <Typography className={classes.bodyText}>•</Typography>
                  <Typography className={classes.bodyText} style={{marginLeft: '.5em'}}>Ongoing support of a Family oriented organization - Real people, no Macho attitudes</Typography>
                </Box>
                <Box display="flex" style={{ marginLeft: '40px' }}>
                  <Typography className={classes.bodyText}>•</Typography>
                  <Typography className={classes.bodyText} style={{marginLeft: '.5em'}}>Marketing and Promotional Support to grow your school</Typography>
                </Box>
                
              </Box>


            </Paper>
          </Box>
        </Box>
      </ThemeProvider>
    </div>
  );
}